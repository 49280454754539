import { useEffect } from "react";
import { Link } from "react-router-dom";

function FunctionInvocation() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <div className='p-3 text-start'>
        <h5>Function invocation</h5>
        <br /><br /><br />


        <p>In JavaScript, function invocation, also commonly referred to as calling a function, is the process of executing the code defined within a function. The function definition itself doesn't execute the code; it only creates a blueprint for the function. When you invoke the function, you're essentially telling the program to run the code within the function.</p>


        <br /><br />
        <p>Here's a breakdown of JavaScript function invocation:</p>


        <br /><br />
        <p><b>Invoking a Function:</b></p>

        <p>The basic syntax for invoking a function involves writing the function name followed by parentheses:</p>

        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            
            <p><span className="text-info">function</span> <span className="text-warning">greet</span> (name) {`{`}</p> 
                <p className="mx-4"><span className="text-danger">console.log</span>(<span className="text-success">"Hello, my name is " </span> + name + <span className="text-success">"!"</span>);</p>
            <p>{`}`}</p>
            <p>greet(<span className="text-success">"Younes"</span>)</p>
        </div>

        <br />
        <p>In this example, the <span className="light">greet</span> function is invoked with the argument "Younes". The function then logs the message "Hello, Younes!" to the console.</p>


        <br /><br /><br />

        <p><b>Arguments:</b></p>
        <p>Functions can accept zero or more arguments, which are values passed to the function when it's invoked. These arguments are used within the function's code to perform specific actions or calculations.</p>

        <br /><br /><br />
        <p><b>Function as a Method:</b></p>

        <p>Functions can also be defined as properties of objects, known as methods. These methods are invoked using dot notation on the object, followed by the method name and parentheses:</p>


        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-primeray">const</span> person = {`{`}</p>
            <p className="mx-3"><span className="text-warning">name</span> : <span className="text-success">"Bob"</span>,</p>
            <p className="mx-3"><span className="text-warning">greet</span> : <span className="text-info">function</span>() {`{`}</p>
            <p className="mx-5"><span className="text-danger">console.log</span>(<span className="text-success">"Hello, my name is " </span> + <span className="text-info">this</span>.name + <span className="text-success">"!"</span>);</p>
            <p className="mx-3">{`}`}</p>
            <p>{`}`};</p>
        </div>
        <p className="mt-2">Here, the <span className="light">greet</span> method is a property of the <span className="light">person</span> object. When invoked using <span className="light">person.greet()</span>, it logs "Hello, my name is Bob!" to the console because <span className="light">this.name</span> refers to the <span className="light">name</span> property of the <span className="light">person</span> object.</p>


        






        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Parameters' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/FunctionCall' >Next</Link>
        </div>
    </div>
  )
}

export default FunctionInvocation;