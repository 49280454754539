import { Link } from "react-router-dom"
import { useEffect } from "react";
import './Animation.css';

function Animation() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='text-start p-3'>
        <h3 className="text-center">Animation</h3>
        <br /><br /><br />
        <p className="mt-5">Creating simple animations using CSS is a great way to add some flair and interactivity to your web pages without needing complex scripting. Here's a breakdown of the key steps involved:</p>

        <ol className='mt-5'>
            <li>
                <b>Define the Animation Sequence:</b>
                <ul>
                    <li>The <span className="light">@keyframes</span> rule is the foundation of CSS animations. It allows you to define a sequence of styles for an element at specific points in time.</li>
                    <li>Inside the <span className="light">@keyframes</span> rule, you specify keyframes using percentages (0% to 100%). Each keyframe represents a stage in the animation and defines the CSS properties you want to animate.</li>
                </ul>
            </li>
            <li className='mt-5'>
                <b>Style the Element:</b>
                <ul>
                    <li>Target the HTML element you want to animate using a CSS selector.</li>
                    <li>Apply the animation properties to the element's style declaration. Here are the important ones:
                        <ul>
                            <li><span className="light">animation-name</span>: This property references the name you gave your animation defined with <span className="light">@keyframes</span></li>
                            <li className='mt-1'><span className="light">animation-duration</span>: Sets the total time the animation will take (e.g., <span className='light'>2s</span> for 2 seconds).</li>
                            <li className='mt-1'><span className="light">animation-iteration-count</span>: Defines how many times the animation should repeat (<span className='light'>infinite</span> for continuous looping).</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li className='mt-5'>
                <b>Example - Bouncing Ball:</b>
                <p>Let's create a simple animation of a bouncing ball:</p>
                <div className="text-light p-3 bg-dark ">
                    <h6>HTML</h6>
                    <br />
                    <p>{`<div>`} <span className='text-warning'>class</span> = <span className='text-info'>"ball"</span> {`</div>`}</p>
                </div>
                <div className="text-light p-3 bg-dark mt-5">
                    <h6>CSS</h6>
                    <br />
                    <p> <span className='text-warning'>@keyframes </span><span>bounce </span> {`{`}</p>
                        <p className='mx-4'><span> 0%</span> {`{ margin-left:`} <span>0px;</span> {`}`}</p>
                        <p className='mx-4'><span>50%</span> {`{ margin-left:`} <span>200px;</span> {`}`}</p>
                        <p className='mx-4'><span>100%</span> {`{ margin-left:`} <span>0px;</span> {`}`}</p>
                    <p>{`}`}</p>
                    <br />
                    <p> <span className='text-warning'>.ball</span> {`{`}</p>
                    <p className='mx-4'>width: <span className='text-warning'>50px</span>;</p>
                    <p className='mx-4'>height: <span className='text-warning'>50px</span>;</p>
                    <p className='mx-4'>background-color: red;</p>
                    <p className='mx-4'>border-radius: <span className="text-warning">50%</span>;</p>
                    <p className='mx-4'>animation-name: bounce;</p>
                    <p className='mx-4'>animation-duration: <span className="text-warning">1s</span>;</p>
                    <p className='mx-4'>animation-iteration-count: infinite;</p>
                    <p>{`}`}</p>
                </div>

                <p className='mt-3'>In this example:</p>
                <ul>
                    <li>The <span className="light">@keyframes bounce</span>rule defines the animation sequence. The ball moves from its starting position (0%) down to 200px (50%) and back to its starting position (100%).</li>
                    <li>The <span className="light">.ball</span> class styles the element with a red background and rounded corners.</li>
                    <li>The animation properties are applied to the <span className="light">.ball</span> class. The ball will continuously bounce for 1 second per cycle.</li>
                </ul>
            </li>
            <li className='mt-5'>
                <b>Additional Customization:</b>
                <p className='mt-2'>CSS animations offer various properties to fine-tune your animations:</p>
                <ul>
                    <li><span className="light">animation-timing-function</span>: Controls the speed curve of the animation (e.g., <span className="light">ease-in-out</span> for a smooth start and end).</li>
                    <li className='mt-1'><span className="light">animation-direction</span>: Specifies the direction of the animation (e.g., alternate to play forwards and backwards).</li>
                    <li className='mt-1'><span className="light">animation-delay</span>: Sets a delay before the animation starts.</li>
                </ul>
            </li>
        </ol>

        <div className="w-100 ">
            <div className="ball"></div>
        </div>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center mt-5">
            <Link className="px-3" to='/TutorialCss/SettingImage'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Transition'>Next</Link>
        </div>
    </div>
  )
}

export default Animation