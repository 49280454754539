import { Link } from "react-router-dom";
import { useEffect } from "react";

function JavaScriptCodeInHTML() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>

        <h5>How to include JavaScript code in HTML edit</h5>

        <ol>
            <li><b>span.light</b> You can write your JavaScript code directly within your HTML document using the <span className="light">{`<script>`}</span> tag. Here's the format:</li>
            <div className="Box mt-5">
                <h6>HTML</h6>
                <br />
                <p>{`<script>`}</p>
                <p className="mx-4"><span className="text-secondary">// Your JavaScript code goes here</span></p>
                <p>{`</script>`}</p>
            </div>
            <li>
            <b>External Script:</b> This is a preferred approach for larger or reusable JavaScript code. You create a separate .js file containing your JavaScript code and then link it to your HTML using the <span className="light">{`<script>`}</span> tag with the src attribute. Here's the format:   
            </li>
            <br />
            <h5>In your HTML:</h5>
            <div className="Box">
                <h6>In your HTML:</h6>
                <br />
                <p>{`<`}<span className="text-warning">script src</span>= <span className="text-info">{`"path/to/your/script.js"`}</span>{`>`}{`</`}<span className="text-warning">script</span>{`>`}</p>
            </div>
        </ol>

        <br />
        
        <ul>
            <li><h6>In your separate JavaScript file (script.js):</h6></li>
            <div className="Box">
                <br /><br />
                <p className="text-secondary">// Your JavaScript code goes here</p>
            </div>
        </ul>


        <br /><br />
        <h5>Placement:</h5>

        <ul>
            <li>You can place the <span className="light">{`<script>`}</span> tag for inline scripts in either the <span className="light">{`<head>`}</span> or <span className="light">{`<body>`}</span> section of your HTML.</li>
            <li className="mt-2">It's generally recommended to put the <span className="light">{`<script>`}</span> tag for external scripts in the <span className="light">{`<head>`}</span> section. This ensures the code loads before the page content, preventing any delays.</li>
        </ul>

        <br /><br />
        <h5>Choosing the method:</h5>
        <ul>
            <li>Use inline scripts for small code snippets specific to that HTML page.</li>
            <li>Use external scripts for larger code bases or code that you want to reuse across multiple HTML pages.</li>
        </ul>

        <br />
        <p>Here are some additional things to keep in mind:</p>

        <ul>
            <li>You can add the type="text/javascript" attribute to the <span className="light">{`<script>`}</span> tag for older browsers, but it's not strictly necessary anymore.</li>
            <li>Make sure the path to your external script file is correct relative to your HTML file.</li>
        </ul>









        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/HistoryOfJavaScript' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Variables' >Next</Link>
        </div>

    </div>
  )
}

export default JavaScriptCodeInHTML