import {Link, NavLink, Outlet, useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import { Row, Col} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import './RgbStyle.css';


const GradiantGenarator = () => {

  const navigate = useNavigate()
  useEffect(() => {
    navigate('/rgba/ChangeHexToRgb')
  },[])
  
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0); 
  },[])
 
  return (
    <div className='allHeight rgba  pt-5'>
      <Helmet>
      
     <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
      <title>Hex color to Rgba</title>
        <meta name="keywords" content="color conversion, HEX to RGBA, color changer, online color tool" />
        <meta name="description" content="Convert colors between HEX, RGBA, and other formats with our easy-to-use online tool."/>
        {/* Persian */}
        <meta name="keywords" content="تبدیل رنگ، HEX به RGBA، تغییر رنگ، ابزار رنگ آنلاین" />
        <meta name="description" content="با ابزار آنلاین آسان ما، رنگ ها را بین HEX، RGBA و سایر فرمت ها تبدیل کنید."/>
        {/* German */}
        <meta name="keywords" content="Farbkonvertierung, HEX zu RGBA, Farbwechsler, Online-Farbtool" />
        <meta name="description" content="Konvertieren Sie Farben zwischen HEX, RGBA und anderen Formaten mit unserem benutzerfreundlichen Online-Tool."/>
        {/* Japones */}
        <meta name="description" content="使いやすいオンライン ツールを使用して、HEX、RGBA、およびその他の形式の間で色を変換します。"/>
        <meta name="keywords" content="カラー変換、HEX から RGBA、カラーチェンジャー、オンラインカラーツール" />
        {/* China */}
        <meta name="description" content="使用我们易于使用的在线工具在 HEX、RGBA 和其他格式之间转换颜色。"/>
        <meta name="keywords" content="颜色转换、HEX 到 RGBA、换色器、在线颜色工具" />
        {/*  */}
        <meta name="keywords" content="conversión de color, HEX a RGBA, cambiador de color, herramienta de color en línea" />
        <meta name="description" content="Convierta colores entre HEX, RGBA y otros formatos con nuestra herramienta en línea fácil de usar."/>
        {/* Arabic */}
        <meta name="keywords" content="تحويل الألوان، HEX إلى RGBA، مغير اللون، أداة الألوان عبر الإنترنت" />                   
        <meta name="description" content="قم بتحويل الألوان بين HEX وRGBA والتنسيقات الأخرى باستخدام أداتنا سهلة الاستخدام عبر الإنترنت."/>
        {/* Armenian */}
        <meta name="keywords" content="գույների փոխարկում, HEX-ի RGBA, գունային փոխարկիչ, առցանց գունային գործիք" />
        <meta name="description" content="Փոխարկեք գույները HEX, RGBA և այլ ձևաչափերի միջև մեր հեշտ օգտագործման առցանց գործիքի միջոցով:"/>
        {/*  */}
        <meta name="keywords" content="преобразование цветов, HEX в RGBA, смена цвета, онлайн-инструмент цвета" />
        <meta name="description" content="Преобразуйте цвета в HEX, RGBA и другие форматы с помощью нашего простого в использовании онлайн-инструмента."/>
        {/*  */}
        <meta name="keywords" content="renk dönüşümü, HEX'ten RGBA'ya, renk değiştirici, çevrimiçi renk aracı" />
        <meta name="description" content="Kullanımı kolay çevrimiçi aracımızla renkleri HEX, RGBA ve diğer formatlar arasında dönüştürün."/>
        {/*  */}
        <meta name="keywords" content="conversione del colore, da HEX a RGBA, cambia colore, strumento colore online" />
        <meta name="description" content="Converti i colori tra HEX, RGBA e altri formati con il nostro strumento online facile da usare."/>
        

      </Helmet>
       <Row className=''>
            <Col lg={2} className='position-relative d-flex justify-content-center ' >
            <div className='Advertising  mt-5 pt-5 px-5 '> 
              <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-1631607859297946"
                data-ad-slot="2957199157"
                data-auto-format="mcrspv"
                data-full-width="">
                <div overflow=""></div>
              </amp-ad>
            </div>
            </Col>
            <Col lg={8} >
              <div className='ContainerButton_rgb mt-5 '>
                <NavLink className='mx-2 text-decoration-none btn' to='ChangeHexToRgb'>Hex to Rgba</NavLink>
                <NavLink className='mx-2 text-decoration-none btn' to='ChangRgbToHex'>Rgb to hex</NavLink>
                <NavLink className='mx-2 text-decoration-none btn' to='ChangHslToRgba'>Hsl to Rgba and Hex</NavLink>
              </div>

              <div>
                <Outlet />
              </div>
            </Col>
            <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
              <div className='vh-lg-100  position-fixed  '>
                <amp-ad width="100vw" height="320"
                  type="adsense"
                  data-ad-client="ca-pub-1631607859297946"
                  data-ad-slot="2957199157"
                  data-auto-format="mcrspv"
                  data-full-width="">
                  <div overflow=""></div>
                </amp-ad>
              </div>
            </Col>
        </Row>
    </div>
  )
}

export default GradiantGenarator;

