import React from 'react';
import GlassMorphismComponent from './GlassMorphismComponent';
import {Row, Col} from 'react-bootstrap';
import Helmet from 'react-helmet';


const GlasseMorphism = () => {
  return (
    <div className='allHeight py-5'>
        <Helmet>
        
        <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        <meta name="description" content="Create stunning glassmorphism effect on your webpage with this tutorial. Learn how to implement glassmorphism style using HTML and CSS." />
        <meta name="keywords" content="glassmorphism, glass effect, frosted glass, web design, CSS, HTML, tutorial" />
        {/* Farsi - persian */}
        <meta name="description" content="با این آموزش افکت شیشه ای خیره کننده را در صفحه وب خود ایجاد کنید. یاد بگیرید که چگونه سبک شیشه‌مورفیسم را با استفاده از HTML و CSS پیاده‌سازی کنید." />
        <meta name="keywords" content="مورفیسم شیشه، جلوه شیشه، شیشه مات، طراحی وب، CSS، HTML، آموزش" />
        {/* Japon */}
        <meta name="description" content="このチュートリアルを使用して、Web ページに見事なグラスモーフィズム効果を作成します。 HTML と CSS を使用して glassmorphism スタイルを実装する方法を学びます。" />
        <meta name="keywords" content="グラスモーフィズム、ガラス効果、すりガラス、Web デザイン、CSS、HTML、チュートリアル" />
        {/* italian */}
        <meta name="description" content="Crea uno straordinario effetto glassmorphism sulla tua pagina web con questo tutorial. Scopri come implementare lo stile glassmorphism utilizzando HTML e CSS." />
        <meta name="keywords" content="glassmorphism, effetto vetro, vetro smerigliato, web design, CSS, HTML, tutorial" />
        {/* korean */}
        <meta name="description" content="이 튜토리얼을 통해 웹페이지에 놀라운 유리 형태 효과를 만들어 보세요. HTML과 CSS를 사용하여 glassmorphism 스타일을 구현하는 방법을 알아보세요." />
        <meta name="keywords" content="glassmorphism, 유리 효과, 젖빛 유리, 웹 디자인, CSS, HTML, 튜토리얼" />
        {/* turkish */}
        <meta name="description" content="Bu eğitimle web sayfanızda çarpıcı cammorfizm efekti yaratın. HTML ve CSS kullanarak cammorfizm stilini nasıl uygulayacağınızı öğrenin." />
        <meta name="keywords" content="cammorfizm, cam efekti, buzlu cam, web tasarımı, CSS, HTML, eğitim" />
        {/* arabic */}
        <meta name="description" content="أنشئ تأثيرًا مذهلاً لشكل الزجاج على صفحة الويب الخاصة بك باستخدام هذا البرنامج التعليمي. تعرف على كيفية تنفيذ أسلوب التشكيل الزجاجي باستخدام HTML وCSS." />
        <meta name="keywords" content="الشكل الزجاجي، تأثير الزجاج، الزجاج المصنفر، تصميم الويب، CSS، HTML، البرنامج التعليمي" />
        {/* Chinese */}
        <meta name="description" content="通过本教程在您的网页上创建令人惊叹的玻璃形态效果。了解如何使用 HTML 和 CSS 实现 glassmorphism 样式。" />
        <meta name="keywords" content="玻璃态射、玻璃效果、磨砂玻璃、网页设计、CSS、HTML、教程" />
        {/* Armani */}
        <meta name="description" content="Ստեղծեք ցնցող ապակեմորֆիզմի էֆեկտ ձեր վեբ էջում այս ձեռնարկով: Իմացեք, թե ինչպես կիրառել ապակեմորֆիզմի ոճը՝ օգտագործելով HTML և CSS:" />
        <meta name="keywords" content="ապակու մորֆիզմ, ապակու էֆեկտ, սառեցված ապակի, վեբ դիզայն, CSS, HTML, ձեռնարկ" />
        {/*  */}


        </Helmet>
        <Row className='pt-5'>
            <Col lg={2} className='position-relative d-flex justify-content-center' >
            <div className='Advertising  mt-5 pt-5 px-5 '> 
              <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-1631607859297946"
                data-ad-slot="2957199157"
                data-auto-format="mcrspv"
                data-full-width="">
                <div overflow=""></div>
              </amp-ad>
            </div>
            </Col>
            <Col lg={8}>
                {/* <h1>Main Generator Glass Morphism</h1> */}
                <GlassMorphismComponent />
            </Col>
            <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
              <div className='vh-lg-100  position-fixed  '>
                <amp-ad width="100vw" height="320"
                  type="adsense"
                  data-ad-client="ca-pub-1631607859297946"
                  data-ad-slot="2957199157"
                  data-auto-format="mcrspv"
                  data-full-width="">
                  <div overflow=""></div>
                </amp-ad>
              </div>
            </Col>
        </Row>
    </div>
  )
}

export default GlasseMorphism