import {Link} from 'react-router-dom';
import { useEffect } from "react";

function CommonHTMLTags() {
  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className='p-3'>
        <h5 className=''>HyperText Markup Language (HTML)</h5>

        <p className='text-start mt-5'>{`<html>`}: This tag denotes the beginning and end of an HTML document. All website content resides within this tag.</p>
        <p className='text-start'><strong>HTML</strong> stands for HyperText Markup Language. It is the standard markup language for creating web pages. HTML describes the structure of a web page using a series of elements. These elements tell the browser how to display the content of the page.</p>
        <p className='text-start'>HTML is written using tags. Tags are enclosed in angle brackets {`(< >)`} and consist of a tag name and optional attributes. For example, the following tag defines a heading </p>

        <div className="text-start p-4 bg-dark  " style={{color:'#fff'}}>
            <h4>HTML</h4>
            <br />
            <br />
            <br />
            <p>{`<h1>This is a heading</h1>`}</p>
        </div>
        <p className='text-start mt-3'>In this example, the tag name is {`<h1>`}. This tells the browser that the text "This is a heading" should be displayed as a heading. The {`<h1>`} tag is one of six heading levels, ranging from {`<h1>`} (the largest heading) to {`<h6>`} (the smallest heading).</p>
        <p className='text-start'><strong>HTML</strong> tags can also have attributes. Attributes provide additional information about the tag. For example, the following tag defines a hyperlink to the Wikipedia website:</p>
        <div className="text-start p-4 bg-dark  " style={{color:'#fff'}}>
          <h4>HTML</h4>
          <br />
          <br />
          <br />
          <p>{`<a href="https://en.wikipedia.org/">Wikipedia</a>`}</p>
        </div>
        <p className='text-start mt-3'>In this example, the tag name is a. This tells the browser that the text "Wikipedia" should be displayed as a hyperlink. The href attribute specifies the URL of the web page that the link should point to.</p>
        <p className='text-start'>HTML is a relatively simple language to learn, and there are many resources available to help you get started. </p>
       <br/>
       <p>Is there anything else you'd like to know about HTML?</p>
       <p>In this site, we have provided you with what you need</p>
       <br/>
       <br/>

       <Link className='px-3' to='/TutorialCss/BasicHTMLstructure'>Previous</Link>
       <Link className='px-3' to='/TutorialCss/CreatingHeadings'>Next</Link>
    </div>
  )
}

export default CommonHTMLTags