import { useEffect } from "react";
import { Link } from "react-router-dom";

function FunctionCall() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <div className='text-start p-3'>
        <h5>Function call</h5>

        <br /><br /><br />

        <p>In JavaScript, a function call is how you execute the code defined within a function. When you call a function, you're essentially telling the program to run the instructions you've written inside the function.</p>

        <br /><br />
        <p>Here's how a function call works:</p>
        <ol>
            <li>
            <b>Define the function:</b> You first define a function using the function keyword, followed by the function's name and parentheses. Optionally, you can specify parameters (inputs) that the function can receive. Inside the function's curly braces, you write the code you want the function to execute.
            </li>
            <li className="mt-4">
            <b>Call the function:</b> To execute the function's code, you use the function's name followed by parentheses. Inside the parentheses, you can optionally provide arguments (values) that correspond to the function's parameters.
            </li>
        </ol>

        <p className="mt-4">Here's an example:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-info">function</span> <span className="text-warn">greet</span>(name) {`{`}</p>
            <p className="mx-4"><span className="text-danger">console.log</span>(<span className="text-success">"Hello, "</span> + name);</p>
            <p>{`}`}</p>
        </div>

        <p className="mt-3">In this example, the greet function is defined to take one parameter, name. When we call greet("Alice"), we're passing the value "Alice" as the argument to the function. The function then uses this argument to construct the greeting message.</p>

        <br /><br /><br />

        <p>Here are some key points to remember about function calls in JavaScript:</p>

        <ul>
            <li className="mt-2">Functions can be called multiple times with different arguments.</li>
            <li className="mt-2">You can define functions before or after you call them in your code.</li>
            <li className="mt-2">Functions can return a value using the return keyword. The returned value can then be stored in a variable or used in an expression.</li>
        </ul>























        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/FunctionInvocation' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/' >Next</Link>
        </div>

    </div>
  )
}

export default FunctionCall