import { Link } from 'react-router-dom';
import { useEffect } from "react";

function HistoryandpurposeofCSS() {

  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className='px-3'>
        <h3 className=''>History and purpose of CSS</h3>
        
        <p className='text-start mt-5'>CSS, which stands for Cascading Style Sheets, was created in the early 1990s to address the growing complexity of web design at the time. Back then, websites were primarily text-based, and HTML (HyperText Markup Language) was the main tool for structuring web pages. HTML itself couldn't control how those pages looked, so designers had to embed presentational instructions directly into the HTML code. This made websites cumbersome to build and maintain, as any changes to the design required modifying the HTML throughout the entire site.</p>

        <p className="text-start mt-3">
          <b>Håkon Wium</b> Lie is credited with creating CSS. He envisioned a way to separate the presentation of a web page (fonts, colors, layouts) from its content (text, images, videos). This separation is the   core purpose of CSS.  By using CSS, designers can define styles in a central location (usually a CSS file) and apply those styles to different parts of a web page. This makes websites much easier to    maintain and allows for a more consistent look and feel across the entire site.
        </p>
        <div className='m-auto '>
          <img src="https://www.oxfordwebstudio.com/user/pages/06.da-li-znate/sta-je-css/sta-je-css.png" alt="css" style={{width:'50%'}} />
        </div>

        <h4 className='text-start my-5'>Here are some of the key benefits of using CSS:</h4>
        <ul className='text-start mt-5'>
          <li>Separation of Concerns: Separates presentation from content, making code cleaner and more manageable.</li>
          <li>Reusability: Styles can be applied to multiple elements, reducing redundancy in code.</li>
          <li>Maintainability: Makes it easier to update the design of a website without having to modify the HTML content.</li>
          <li>Accessibility: CSS can be used to create websites that are more accessible to users with disabilities.</li>
          <li>Responsiveness: CSS allows websites to be responsive, meaning they can adapt to different screen sizes and devices.</li>
        </ul>
        <p className="text-start">Overall, CSS is a fundamental building block of modern web development. It plays a critical role in creating visually appealing, user-friendly, and accessible websites.</p>

        <br/>
        <br/>
        <Link className='px-3' to='/TutorialCss/Table'>Previous</Link>
        <Link className='px-3' to='/TutorialCss/StructureofACSSfile'>Next</Link>
    </div>
  )
}

export default HistoryandpurposeofCSS