import { Link } from "react-router-dom"
import { useEffect } from "react";

function SettingImage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const BackGroundImageReapet = {
        width:'100%',
        height:'300px',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        backgroundImage: `url(https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`,
    }

  return (
    <div className='text-start p-3'>
        <h3 className="text-center">Setting image</h3>
        <br /><br /><br />
        <ul className="">
            <li><a href="#repeat">Background-repeat</a></li>
            <li><a href="#Size">Background-size</a></li>
            <li><a href="#Position">Background-position</a></li>
        </ul>
        <p id="repeat">As you saw in the previous tutorial, we can add the photo to the element by using <span className="light">background-image: url();</span> but we must configure it correctly.</p>

        <p  className="mt-4">Below we have shown some examples for setting the photo along with the explanation:</p>

        <h5 className="mt-5">Background-norepeat:</h5>
        <p className="mt-4">1. In CSS, the <span className="light">background-repeat: no-repeat;</span> property controls how a background image is displayed on an element. By default, background images tile (repeat) both horizontally and vertically to fill the entire element's background.</p>


        <p className="mt-4">Here's what <span className="light">background-repeat: no-repeat;</span> does:</p>
        <ul>
            <li><b>Stops repetition</b>: The image won't be repeated across the element's background.</li>
            <li><b>Single display</b>: The background image appears only once.</li>
        </ul>
        <div>
            <h6>Background image in no repeat</h6>
            <div style={BackGroundImageReapet}>

            </div>
        </div>

        <h6  className="mt-5">How the image is positioned within the element depends on another CSS property:</h6>
        <p>background-position. If background-position isn't specified, the image defaults to the top-left corner of the element.</p>
        <p>If background-position isn't specified, the image defaults to the top-left corner of the element</p>

        <h6>Here are some use cases for <span className="light">background-repeat: no-repeat;</span>:</h6>
        <ul id="Size">
            <li><b>Placing a logo in a specific corner</b>: You can use it to position a logo image in the top-right corner of a webpage.</li>
            <li><b>Using a small background element</b>: When you have a small background image that doesn't need to fill the entire element, no-repeat prevents unwanted tiling.</li>
        </ul>

        <h5  className="mt-5">background-size</h5>
        <p>The background-size property in CSS controls the dimensions of an element's background image. It dictates how the image scales or fits within the element's designated background area.</p>

        <p className="mt-4">There are several ways to use background-size:</p>
        <ul>
            <li><b>Keywords:</b>
                <ul>
                    <li><span className="light">auto</span>: This maintains the image's original size.</li>
                    <li className="mt-1"><span className="light">cover</span>: The image resizes to completely cover the background area, while preserving its aspect ratio (ratio of width to height). Any parts of the image that don't fit within the element's boundaries will be cropped off.</li>
                    <li><span className="light">contain</span>: Similar to cover, but the image resizes to fit entirely within the background area without cropping. If the image's aspect ratio doesn't match the element's proportions, there may be empty space around the image.</li>
                </ul>
            </li>
            <li><b>Values:</b>
                <ul>
                    <li>You can specify pixel (px) or percentage (%) values for width and height. A single value sets the width, and the height is automatically adjusted to maintain the aspect ratio. Two values define both width and height independently.</li>
                </ul>
            </li>
        </ul>

        <p>By understanding background-size, you can achieve different effects with background images in your web pages.  For instance, you can use it to:</p>

        <ul id="Position">
            <li>Stretch an image to fill the entire background area.</li>
            <li>Maintain the image's original size but clip it if it overflows the element's boundaries.</li>
            <li>Resize the image to fit within the element while preserving its aspect ratio.</li>
        </ul>

        <h5 className="mt-5">Background-position:</h5>
        <p>In CSS, you can control the repetition and position of background images using two key properties:</p>


        <ol>
            <li>
               <b>background-repeat</b>: This property determines how the background image tiles or repeats within the element's background area. Here are the common values:
               <ul>
                <li><span className="light">repeat-x</span>: Repeats the image horizontally across the element.</li>
                <li className="mt-1"><span className="light">repeat-y</span>: Repeats the image vertically down the element.</li>
                <li className="mt-1"><span className="light">repeat</span>: Repeats the image in both horizontal and vertical directions (default behavior).</li>
                <li className="mt-1"><span className="light">no-repeat</span>: Displays the image only once, at its initial position.</li>
                <li className="mt-1"><span className="light">space</span>: Tiles the image with whitespace in between (not commonly used).</li>
               </ul>
            </li>
            <li>
                <b>background-position</b>: This property sets the initial position of the background image within the element's background area. You can specify the position using:
                <ul>
                    <li><span className="light">Keywords</span>: top, center, bottom, left, right (or combinations like top left).</li>
                    <li className="mt-1"><span className="light">Percentage values</span>: Define the offset from the top and left edges of the element (e.g., 50% 20%).</li>
                    <li className="mt-1"><span className="light">Length values (px, em, etc.)</span>: Specify the exact pixel distance from the top and left edges (e.g., 20px 10em).</li>
                </ul>
            </li>
        </ol>

        <h6 className="mt-5">Here's how you can use them together:</h6>

        <ul>
            <li>
                <b>Repeating a background image in the top-left corner</b>:
                <div className="text-light p-3 bg-dark">
                    <h6>CSS</h6>
                    <br />
                    <p>.element {`{`}</p>
                    <p className="mx-4">background-image: <span className="text-info">url</span>(<span className="text-warning">"image.jpg"</span>)</p>
                    <p className="mx-4">background-repeat: repeat;</p>
                    <p className="mx-4">background-position: top left;</p>
                    <p>{`}`}</p>
                </div>
            </li>
            <li className="mt-4">
                <b>Displaying a single image centered horizontally:</b>
                <div className="text-light p-3 bg-dark">
                    <h6>CSS</h6>
                    <br />
                    <p>.element {`{`}</p>
                    <p className="mx-4">background-image: <span className="text-info">url</span>(<span className="text-warning">"image.jpg"</span>)</p>
                    <p className="mx-4">background-repeat: no-repeat;</p>
                    <p className="mx-4">background-position: center;</p>
                    <p>{`}`}</p>
                </div>
            </li>

            <li className="mt-4">
                <b>Placing an image 20 pixels from the bottom and 50% from the right edge:</b>
                <div className="text-light p-3 bg-dark">
                    <h6>CSS</h6>
                    <br />
                    <p>.element {`{`}</p>
                    <p className="mx-4">background-image: <span className="text-info">url</span>(<span className="text-warning">"image.jpg"</span>)</p>
                    <p className="mx-4">background-repeat: no-repeat;</p>
                    <p className="mx-4">background-position: <span className="text-warning">20px 50%</span>;</p>
                    <p>{`}`}</p>
                </div>
            </li>
        </ul>

    {/* btn  */}
    <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/AddImage'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Animation'>Next</Link>
        </div>

    </div>
  )
}

export default SettingImage