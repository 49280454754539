import { Link } from "react-router-dom"
import { useEffect } from "react";

function FontSize() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-3'>
      <h3>Font size</h3>
      <br/><br/>
      <p className='text-start'>HTML offers a variety of tags to format text and enhance the visual presentation of your webpage. These tags fall into two categories: logical and physical.</p>
      <h5 className='mt-5 text-start'>Logical Tags</h5>
      <p className="text-start">Logical tags define the meaning or purpose of the text, focusing on its importance rather than appearance.</p>
      <p className='text-start '>Here are some common examples:</p>

      <ul className='text-start'>
        <li>{`<strong>`} tag: This tag indicates strong importance. Text wrapped in this tag is usually rendered bold by browsers, but the emphasis is on the content's weight rather than just a visual effect.</li>
        <li>{`<em>`} tag: This tag signifies emphasis. Content within this tag is often displayed in italics, but it highlights importance rather than just a stylistic choice.</li>
        <li>{`<blockquote>`} tag: This tag is used for quotations, indenting the quoted text visually to set it apart from the surrounding content.</li>
      </ul>

      <h5 className='text-start'>Physical Tags</h5>
      <p className="text-start">Physical tags control the visual presentation of the text, dictating how it appears on the screen. </p>
      <p className='text-start '>Some commonly used physical tags include:</p>
      <ul className='text-start'>
        <li className='mt-1'><span className="light">{`<b>`}</span> tag: This tag makes text bold, but it doesn't carry any semantic meaning like {`<strong>`}.</li>
        <li className='mt-1'> tag: Similar to <span className="light">{`<b>`}</span>, this tag italicizes text without any inherent emphasis.</li>
        <li className='mt-1'><span className="light">{`<sup>`}</span> tag: This tag renders text in superscript, appearing slightly raised above the normal line.</li>
        <li className='mt-1'><span className="light">{`<sub>`}</span> tag: This tag formats text in subscript, appearing slightly lower than the regular line.</li>
      </ul>

      <h5 className='text-start mt-4'>Best Practices</h5>
      <p className='text-start'>While both logical and physical tags can format text, it's generally recommended to prioritize logical tags whenever possible. This approach makes your HTML code more semantic,  improving accessibility and readability for users and search engines.</p>
      <p className='text-start'>For instance, use <span className="light">{`<strong>`}</span> instead of <span className="light">{`<b>`}</span> to denote important text, as it conveys the meaning more effectively. Browsers can still render it bold if that's the default style, but the semantic meaning is preserved.</p>

      <h5 className='text-start'>Style linear or css.</h5>
      <p className="text-start mt-4">You can also format your tags using linear style or css.</p>

      <p className="text-start mt-4">For example, you can do the following for linear style in Html:</p>

      <div className="bg-dark text-start p-3 text-light">
        <h5>HTML</h5>
        <br />
        <br />
        <p>{`<p style="color: red; font-size: 20px" >The desired text</p>`}</p>
      </div>

      <br />
      <br />
      <Link className="px-3" to='/TutorialCss/PseudoSelectors'>Previous</Link>
      <Link className="px-3" to='/TutorialCss/MarginPadding'>Next</Link>
    </div>
  )
}

export default FontSize;