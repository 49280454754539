import { Link } from "react-router-dom";
import { useEffect } from "react";

function Introduction() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h4>Introduction</h4>
        <h5>What is JavaScript?</h5>
        <br /><br />

        <ul>
            <li><b>Interactive Websites:</b> JavaScript adds interactivity to webpages. Imagine buttons that trigger actions, forms that respond to user input, or games that run in your browser. That's all thanks to JavaScript!</li>
            <li><b>Cross-Platform:</b> JavaScript works on all major browsers (Chrome, Firefox, Edge, etc.), making it a reliable choice for web development.</li>
            <li><b>Beginner-Friendly:</b> JavaScript has a relatively simple syntax and is considered a great language for beginners to learn programming concepts.</li>
        </ul>

        <br /><br /><br />
        <h5>What can you do with JavaScript?</h5>
        <ul>
            <li><b>Make Websites Dynamic:</b> Change content, styles, and respond to user interactions – all with JavaScript's magic.</li>
            <li><b>Create Animations:</b> Bring your webpages to life with smooth animations and interactive elements.</li>
            <li><b>Build Web Applications:</b> Go beyond static websites and develop complex web applications with data processing and user interfaces.</li>
        </ul>

        <br /><br /><br />

        <p><b>Learning the Basics:</b></p>
        <ul>
            <li><b>Data Types:</b> Understand how JavaScript handles information like numbers, text, and booleans.</li>
            <li><b>Variables & Operators:</b> Learn how to store and manipulate data using variables and operators like addition, subtraction, and comparison.</li>
            <li><b>Control Flow:</b> Make your code respond differently based on conditions with if statements, loops, and other control flow structures.</li>
            <li><b>Functions:</b> Organize your code into reusable blocks called functions to make your programs more efficient.</li>
        </ul>

        <br /><br /><br />
        <p><b>Ready to Explore Further?</b></p>
        <p>So stay with me and I will explain more</p>
        <br /><br /><br />
        <div className="text-center">
            <Link to='/Tutorial_JS/HistoryOfJavaScript' >Next</Link>
        </div>
    </div>
  )
}

export default Introduction