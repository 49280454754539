import { Link } from "react-router-dom"
import { useEffect } from "react";

function ComponentBox() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='p-3'>
        <h3>Components of the box model (content, margin, border, padding)</h3>
        <br /><br /><br />

        <p className="text-start">The box model is a fundamental concept in CSS that defines the overall size and positioning of an element on a webpage. It's essentially a rectangular box that surrounds every HTML element and is made up of four main components:</p>

       <p className="text-start mt-5"><b>Content</b>: This is the core area where the actual content of your element resides, such as text, images, or forms. You can control the size of the content area using CSS properties like width and height.</p>
       <p className="text-start"><b>Padding</b>: Padding creates a transparent space around the content, providing a buffer between the content and the border. This is useful for adding breathing room to your element's content. Padding can be set for all sides at once or for each side individually using properties like <span className="light">padding</span> and <span className="lkight">padding-top</span>, <span className="light">padding-right</span>, <span className="light">padding-bottom</span>, and <span className="light">padding-left</span>.</p>
       <p className="text-start"><b>Border</b>: The border surrounds the padding and content, creating a visual separation from other elements on the page. You can customize the border's appearance using CSS properties like border-width, <span className="light">border-style</span>, and <span className="light">border-color</span>.</p>
        <p className="text-start"><b>Margin</b>: Margin is the outermost layer that creates space around the entire element, including the border. It separates the element from its neighboring elements. Margins are controlled by properties like margin and <span className="light">margin-top</span>, <span className="light">margin-right</span>, <span className="light">margin-bottom</span>, and <span className="light">margin-left</span>.</p>

        <p className="text-start">Here's a helpful analogy to understand the difference between padding and margin: Imagine a box representing the element. The content is like a piece of paper placed inside the box. Padding is the space between the paper and the inner walls of the box, while the margin is the space between the box itself and other things around it.</p>

        <p className="text-start">By understanding and manipulating these components, you can achieve precise control over the layout and styling of your webpages. There's even a CSS property called <span className="light">box-sizing</span> that allows you to define how the <span className="light">width</span> and <span className="light">height</span> of an element account for padding and border.</p>

        <p className="text-start mt-5"> Let's look at an example to illustrate the box model components:</p>

        <p className="text-start">Imagine a web page with a product box showcasing a pair of shoes. Here's how the box model would break down:</p>
        <ul className='text-start'>
            <li><b>Content</b>: This would be the image of the shoes itself.</li>
            <li><b>Padding</b>: Maybe you want a small amount of space between the image and the border of the box for a cleaner look. This space would be the padding.</li>
            <li><b>Border</b>: The product box might have a thin black border to visually define its edges and separate it from other elements on the page.</li>
            <li><b>Margin</b>: There could be some margin applied to the box to create spacing between it and other product boxes on the page.</li>
        </ul>


        <br /><br />
        <p className="text-start">Here's a basic HTML code snippet for the product box:</p>

        <div className="text-start text-light p-3 bg-dark">
            <h5>HTML</h5>
            <br />
            <p>{`<div`}<span className='text-danger'>class</span>=<span className='text-success'>"product-box"</span> {`>`}</p>
            <p className='mx-3'>{`<img`}<span className='text-danger'>src</span>=<span className='text-success'>"shoes.jpg"</span> <span className='text-danger'>alt</span>=<span className='text-success'>"Shoes</span>{`>`}</p>
            <p>{`</div>`}</p>
        </div>
        <br />
        <p className="text-start">And the corresponding CSS to style the box model components:</p>
        <div className="text-start text-light p-3 bg-dark">
            <h5>CSS</h5>
            <br />
            <p><span className="text-danger">.product-box</span>{` {`}</p>
            <p className='mx-3'>width: <span className='text-info'>250px;</span></p>
            <p className='mx-3'>height: <span className='text-info'>300px;</span></p>
            <p className='mx-3'>padding: <span className='text-info'>10px</span>;</p>
            <p className='mx-3'>border: <span className='text-info'>1px</span> solid black;</p>
            <p className='mx-3'>margin: <span className='text-info'>20px</span>;</p>
            <p>{`}`}</p>
            
        </div>
        <p className="text-start mt-3">In this example, the <span className='light'>width</span> and <span className="light">height</span> properties define the size of the content area (the image). The padding adds a 10px buffer around the image. The <span className="light">border</span> creates a 1px black line surrounding the <span className="lioght">padding</span> and content. Finally, the <span className="light">margin</span> introduces a 20px space between the product box and other elements on the page.</p>
        <p className="text-start mt-3">By adjusting these CSS properties, you can control the overall size, spacing, and visual presentation of the product box. This is just a basic example, but it demonstrates how the box model components work together to create the final layout of an element on a webpage.</p>

         {/* btn  */}
            <br />
            <br />
            <Link className="px-3" to='/TutorialCss/BoxModel'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/BlockAndInlineLayout'>Next</Link>
    </div>
  )
}

export default ComponentBox