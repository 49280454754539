import LandingPage from '../Pages/LandingPage/LandingPage';
import PrivacyPolici from '../Pages/PrivacyPolici/PrivacyPolici';
import About from '../Pages/About/About';
import Notification from '../Pages/Notification/Notification';

// Generators ;
import Rgba from '../Pages/Generators/Rgba-Genarator/Rgba';
import Glassemorphism from '../Pages/Generators/GlasseMorphism-Generator/GlasseMorphism';
import Radius from '../Pages/Generators/Radius_box-shadow_color/Radius';
import SpectrumColor from '../Pages/Generators/SpectrumColor/SpectrumColor';
import ColumnarText from '../Pages/Generators/ColumnarText/ColumnarText';
// Sample pages
import BoxShadow from '../Pages/Sample-pages/Box-Shadow/BoxShadow';
import Gradient from '../Pages/Sample-pages/Gradient/Gradient';
import PaletteColor from '../Pages/Sample-pages/Paletts-color/PalettesColor';
import Spiner from '../Pages/Sample-pages/Spiner/Spiner';

// Tutorial CSS ********************************************
import TutorialCss from '../Pages/Tutorial-css/TutorialCss';
// Children of Tutorial CSS
import BasicHTMLstructure from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/BasicHTMLstructure';
import CommonHTMLTags from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/CommonHTMLTags';
import CreatingHeadings from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/CreatingHeadings';
import Paragraphs from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/Paragraphs';
import ListsTag from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/List';
import Table from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToHTML/Table';
import HistoryandpurposeofCSS from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToCss/HistoryandpurposeofCSS';
import StructureofACSSfile from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToCss/StructureofACSSfile';
import CSSsyntax from '../Pages/Tutorial-css/nestedRoutePage/IntroductionToCss/CSSsyntax';
import BasicSelectors from '../Pages/Tutorial-css/nestedRoutePage/CSSselectors/BasicSelectors';
import DescendantSelectors from '../Pages/Tutorial-css/nestedRoutePage/CSSselectors/DescendantSelectors';
import AttributeSelectors from '../Pages/Tutorial-css/nestedRoutePage/CSSselectors/AttributeSelectors';
import PseudoSelectors from '../Pages/Tutorial-css/nestedRoutePage/CSSselectors/PseudoSelectors';
import FontSize from '../Pages/Tutorial-css/nestedRoutePage/TextFormatting/FontSize';
import MarginPadding  from '../Pages/Tutorial-css/nestedRoutePage/TextFormatting/MarginPadding';
import TextEffects from '../Pages/Tutorial-css/nestedRoutePage/TextFormatting/TextEffects';
import Width from '../Pages/Tutorial-css/nestedRoutePage/DimensionInCss/Width';
import Position from '../Pages/Tutorial-css/nestedRoutePage/DimensionInCss/Position';
import Floating from '../Pages/Tutorial-css/nestedRoutePage/DimensionInCss/Floating';
import BoxModel from '../Pages/Tutorial-css/nestedRoutePage/BoxModel/BoxModel';
import ComponentBox from '../Pages/Tutorial-css/nestedRoutePage/BoxModel/ComponentBox';
import BlockAndInlineLayout from '../Pages/Tutorial-css/nestedRoutePage/LayoutSystems/BlockAndInlineLayout';
import FloatingPositioningLayout from '../Pages/Tutorial-css/nestedRoutePage/LayoutSystems/FloatingPositioningLayout';
import FlexBox from '../Pages/Tutorial-css/nestedRoutePage/LayoutSystems/FlexBox';
import Grid from '../Pages/Tutorial-css/nestedRoutePage/LayoutSystems/Grid';
import Colors from '../Pages/Tutorial-css/nestedRoutePage/ColorsAndGradients/Colors';
import Gradients from '../Pages/Tutorial-css/nestedRoutePage/ColorsAndGradients/Gradients';
import AddImage from '../Pages/Tutorial-css/nestedRoutePage/ImagesAndBackgrounds/AddImage';
import SettingImage from '../Pages/Tutorial-css/nestedRoutePage/ImagesAndBackgrounds/SettingImage';
import Animation from '../Pages/Tutorial-css/nestedRoutePage/Animations/Animation';
import Transition from '../Pages/Tutorial-css/nestedRoutePage/Animations/Transition';
import CSSAnimationLibraries from '../Pages/Tutorial-css/nestedRoutePage/Animations/CSSAnimationLibraries';
import MediaQueries from '../Pages/Tutorial-css/nestedRoutePage/MediaQueries/MediaQueries';
import Devices from '../Pages/Tutorial-css/nestedRoutePage/MediaQueries/Devices';
import AdaptiveDesign from '../Pages/Tutorial-css/nestedRoutePage/MediaQueries/AdaptiveDesign';
import Introduction from '../Pages/Tutorial-css/nestedRoutePage/CSSPreprocessors/Introduction';
import CssVariable from '../Pages/Tutorial-css/nestedRoutePage/CSSPreprocessors/CssVariable';
import CompilingPreprocessors from '../Pages/Tutorial-css/nestedRoutePage/CSSPreprocessors/CompilingPreprocessors';
import BrowserInspect from '../Pages/Tutorial-css/nestedRoutePage/BrowserDeveloperTools/BrowserInspect';
import OptimizingCss from '../Pages/Tutorial-css/nestedRoutePage/BrowserDeveloperTools/OptimizingCss';
import ImportantNotes from '../Pages/Tutorial-css/nestedRoutePage/Points/ImportantNotes';

// Tutorial_JS *********************************************
import Tutorial_JS from '../Pages/Tutorial-Js/Tutorial_JS';
// Childeren of Tutorial_JS
import IntroductionJs from '../Pages/Tutorial-Js/nested_pages/Introduction/Introduction';
import JavaScriptCodeInHTML from '../Pages/Tutorial-Js/nested_pages/Introduction/JavaScriptCodeInHTML';
import HistoryOfJavaScript from '../Pages/Tutorial-Js/nested_pages/Introduction/HistoryOfJavaScript';
import Variables from '../Pages/Tutorial-Js/nested_pages/DataTypes/Variables';
import String from '../Pages/Tutorial-Js/nested_pages/DataTypes/String';
import Numbers from '../Pages/Tutorial-Js/nested_pages/DataTypes/Numbers';
import Booleans from '../Pages/Tutorial-Js/nested_pages/DataTypes/Booleans';
import Arithmetic from '../Pages/Tutorial-Js/nested_pages/Operators/Arithmetic';
import Comparison from '../Pages/Tutorial-Js/nested_pages/Operators/Comparison';
import LogicalOperators from '../Pages/Tutorial-Js/nested_pages/Operators/LogicalOperators';
import ForLoop from '../Pages/Tutorial-Js/nested_pages/Loops/ForLoop';
import While from '../Pages/Tutorial-Js/nested_pages/Loops/While';
import DoWhile from '../Pages/Tutorial-Js/nested_pages/Loops/DoWhile';
import ForEach from '../Pages/Tutorial-Js/nested_pages/Loops/ForEach';
import Function from '../Pages/Tutorial-Js/nested_pages/Functions/Function';
import Parameters from '../Pages/Tutorial-Js/nested_pages/Functions/Parameters';
import FunctionInvocation from '../Pages/Tutorial-Js/nested_pages/Functions/FunctionInvocation';
import FunctionCall  from '../Pages/Tutorial-Js/nested_pages/Functions/FunctionCall';

// Params page
import ParamsPaletteColor from '../Pages/Sample-pages/Paletts-color/ParamsPaletteColor';
// Animations ;
// import Animations from '../Pages/Animations/Animations';
// Nested pages
import ChangeHexToRgb from '../Pages/Generators/Rgba-Genarator/NestedComponents/ChangeHexToRgb';
import ChangHslToRgba from '../Pages/Generators/Rgba-Genarator/NestedComponents/ChangeHslToRgba';
import ChangRgbToHex from '../Pages/Generators/Rgba-Genarator/NestedComponents/ChangeRgbToHex';

const routes = [
    { path:'/', element:<LandingPage />},
    { path:'/PrivacyPolici', element: <PrivacyPolici />},
    { path: '/About', element: <About />},
    { path:'/Notification', element: <Notification />},
    // Sample pages
    { path:'/gradient', element:<Gradient />},
    { path:'/boxShadow', element:<BoxShadow />},
    { path:'/palettecolor', element:<PaletteColor />},
    { path:'Spiner', element: <Spiner />},

    // Generators 
    { path:'/Radius', element:<Radius />},
    { path:'/Glassemorphism' , element: <Glassemorphism />},
    { path:'/SpectrumColor' , element: <SpectrumColor />},
    { path:'/ColumnarText' , element: <ColumnarText />},

    // Tutorial CSS
    { path:'/TutorialCss', element:<TutorialCss />, children :[
        // Introduction to HTML
        { path:'BasicHTMLstructure', element: <BasicHTMLstructure />},
        { path:'CommonHTMLTags', element: <CommonHTMLTags />},
        { path:'CreatingHeadings', element: <CreatingHeadings />},
        { path:'Paragraphs', element: <Paragraphs />},
        { path:'Lists', element: <ListsTag />},
        { path:'Table', element: <Table />},
        { path:'HistoryandpurposeofCSS', element: <HistoryandpurposeofCSS />},
        { path:'StructureofACSSfile' , element: <StructureofACSSfile />},
        { path:'CSSsyntax' , element: <CSSsyntax />},
        { path:'BasicSelectors' , element: <BasicSelectors />},
        { path:'DescendantSelectors' , element: <DescendantSelectors />},
        { path:'AttributeSelectors' , element: <AttributeSelectors />},
        { path:'PseudoSelectors' , element: <PseudoSelectors />},
        { path:'FontSize' , element: <FontSize />},
        { path:'MarginPadding' , element: <MarginPadding />},
        { path:'TextEffects' , element: <TextEffects />},
        { path:'Width' , element: <Width />},
        { path:'Position' , element: <Position />},
        { path:'Floating' , element:<Floating />},
        { path:'BoxModel' , element:<BoxModel />},
        { path:'ComponentBox' , element:<ComponentBox />},
        { path:'BlockAndInlineLayout' , element:<BlockAndInlineLayout />},
        { path:'FloatingPositioningLayout' , element:<FloatingPositioningLayout />},
        { path:'FlexBox' , element:<FlexBox />},
        { path:'Grid' , element:<Grid />},
        { path:'Colors' , element:<Colors />},
        { path:'Gradients' , element:<Gradients />},
        { path:'AddImage' , element:<AddImage />},
        { path:'SettingImage' , element:<SettingImage />},
        { path:'Animation' , element:<Animation />},
        { path:'Transition' , element:<Transition />},
        { path:'CSSAnimationLibraries' , element:<CSSAnimationLibraries />},
        { path:'MediaQueries' , element:<MediaQueries />},
        { path:'Devices' , element:<Devices />},
        { path:'AdaptiveDesign' , element:<AdaptiveDesign />},
        { path:'Introduction' , element:<Introduction />},
        { path:'CssVariable' , element:<CssVariable />},
        { path:'CompilingPreprocessors' , element:<CompilingPreprocessors />},
        { path:'BrowserInspect' , element:<BrowserInspect />},
        { path:'OptimizingCss' , element:<OptimizingCss />},
        { path:'ImportantNotes' , element:<ImportantNotes />},


        // 
    ]},
    { path: '/Tutorial_JS', element:<Tutorial_JS />, children: [
        // Introduction
        { path:'Introduction_js', element:<IntroductionJs />},
        { path:'HistoryOfJavaScript', element:<HistoryOfJavaScript />},
        { path:'JavaScriptCodeInHTML', element:<JavaScriptCodeInHTML />},
        // Type
        { path:'Variables', element:<Variables />},
        { path:'String', element:<String />},
        { path:'Numbers', element:<Numbers />},
        { path:'Booleans', element:<Booleans />},
        { path:'Arithmetic', element:<Arithmetic />},
        { path:'Comparison', element:<Comparison />},
        { path:'LogicalOperators', element:<LogicalOperators />},
        { path:'ForLoop', element:<ForLoop />},
        { path:'While', element:<While />},
        { path:'DoWhile', element:<DoWhile />},
        { path:'ForEach', element:<ForEach />},
        { path:'Function', element:<Function />},
        { path:'Parameters', element:<Parameters />},
        { path:'FunctionInvocation', element:<FunctionInvocation />},
        { path:'FunctionCall', element:<FunctionCall />},


        // 
    ]},

    //  



    { path:'/rgba' , element: <Rgba />, children : [
        { path:'ChangRgbToHex' , element:<ChangRgbToHex />},
        { path:'ChangeHexToRgb' , element:<ChangeHexToRgb />},
        { path:'ChangHslToRgba' , element:<ChangHslToRgba />},
    ]},
    // Params pages
    { path:'/ParamsPaletteColor/:idColorPalette', element:<ParamsPaletteColor />}
]

export default routes