import React from 'react';
import './Notification.css';

const Notification = () => {
  return (
    <div className='Notification'>
      <div className='w-100 pt-5 mt-5'>
        <h4 className='text-light pt-5 mt-5'>There are no messages !</h4>
        <p className='text-light pt-5 mt-5'>Here the announcements of this website will be available</p>
      </div>
    </div>
  )
}

export default Notification