import { Link } from "react-router-dom"
import { useEffect } from "react";

function AttributeSelectors() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-3'>
        <h3>Attribute selectors</h3>

        <br/>
        <br/>
        <p className='text-start'>Attribute-based selectors in CSS are a powerful tool for targeting elements on a webpage based on the attributes they have and the values of those attributes.  <b>HTML</b> elements can have attributes that provide additional information about the element. For instance, an image tag (<span className='light'>{`<img>`}</span>) may have an <span className="light">alt</span> attribute that specifies alternative text to display if the image fails to load.</p>
        <p className='text-start'>With attribute selectors, you can write CSS rules that target elements based on the presence of a specific attribute, the exact value of that attribute, or even if the attribute's value contains a specific substring. This allows for very granular control over how you style your webpages.</p>

        <p className="mt-5 text-start">Here's a breakdown of how attribute selectors work:</p>

        <ul className="text-start mt-4">
          <li><b>Basic Syntax</b>:  Attribute selectors are written within square brackets <span className="light">[]</span>. Inside the brackets, you specify the attribute name followed by a comparison operator and the attribute value (which needs to be in quotes). For example, <span className="light">[href="link.html"]</span> would target all elements that have an <span className="light">href</span> attribute with the exact value <span className="light">"link.html"</span>.</li>
        </ul>
        <ul className='text-start mt-4'>
          <li><b>Different Types of Matches</b>: There are various comparison operators you can use to target elements based on different matching criteria. Some common ones include:</li>

          <ul className='text-start'>
            <li><b>Exact Match (<span className='light'>=</span>)</b>: Selects elements where the attribute value exactly matches the provided value.</li>
            <li><b>Presence ()</b>: Selects elements that simply have the specified attribute, regardless of the value.</li>
            <li><b>Contains (<span className='light'>*=</span>)</b>: Selects elements where the attribute value contains the specified substring.</li>
            <li><b>Starts With (<span className='light'>^=</span>)</b>: Selects elements where the attribute value starts with the specified substring.</li>
            <li><b>Ends With (<span className='light'>$=</span>)</b>: Selects elements where the attribute value ends with the specified substring.</li>
          </ul>
        </ul>
        <p className="text-start">By using these different matching options, you can achieve a high degree of precision in your CSS targeting.</p>

        <p className="mt-4 text-start">Here are some advantages of using attribute selectors:</p>

        <ul className='text-start'>
          <li><b>Improved Maintainability</b>: By targeting elements based on their attributes, your CSS code becomes more maintainable and easier to understand. You can modify the styles foelements with specific attributes without affecting other parts of your code.</li>
          <li><b>Greater Flexibility</b>: Attribute selectors allow you to style elements dynamically based on the content they hold. This can be useful for creating interactive elements oapplying styles based on user input.</li>
        </ul>


        <div className="mt-5 text-start p-3 bg-dark text-light">
            <h5>Css</h5>
            <br/>
            <br/>
            <p className='text-start text-secondary'>{`/* Style all links that have an href attribute with the exact value "link.html" */`}</p>
             <p className=''>{`a[href="link.html"] {`}</p>
             <p className='px-4'>{`color: green;`} <span className='text-secondary'>/* Change the text color to green */</span></p>
             <p className='px-4'>{`font-weight: bold;`} <span className='text-secondary'>/* Make the text bold */</span></p>
             <p className='px-4'>{`text-decoration: none; `} <span className='text-secondary'>/* Remove the default underline decoration */</span></p>
            <p>{`}`}</p>
        </div>
        <p className="mt-3 text-start">Before styling: <a href="TutorialCss/AttributeSelectors">Example</a></p> 
        <p className="mt-3 text-start">After styling: <a style={{textDecoration:'none', color:'green'}} href="TutorialCss/AttributeSelectors">Example</a></p>

        <p className='text-start mt-5'>Overall, attribute selectors are a versatile tool in the CSS toolbox that can help you create more targeted and dynamic styles for your webpages.</p>

        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/DescendantSelectors'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/PseudoSelectors'>Next</Link>
    </div>
  )  
}

export default AttributeSelectors