import {Link} from 'react-router-dom';
import { useEffect } from "react";

function CreatingHeadings() {
  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className='px-3'>
        <h3>Headings in HTML</h3>
        <p className="text-start mt-5">Headings in HTML are created using tags ranging from {`<h1>`} to {`<h6>`}. These tags define the structure and importance of the content on your webpage.</p>
        <p className="text-start ">Here's a breakdown of heading tags in HTML:</p>
        <ul className='text-start'>
            <li>Importance: Headings range from {`<h1>`} (most important) to {`<h6>`} (least important).</li>
            <li>Structure: Headings help establish a clear hierarchy for your content, making it easier for users and search engines to understand.</li>
            <li>Visuals: By default, browsers display headings in larger and bolder fonts compared to regular text.</li>
        </ul>
        <h5 className='text-start mt-4'>Using Heading Tags:</h5>
        <ol className='text-start'>
            <li>Placement: Heading tags are typically used within the {`<body>`} section of your HTML code.</li>
            <li>Content: Place the text you want to designate as a heading within the opening and closing tags <br/> (e.g., {`<h1>This is a heading</h1>`}).</li>
        </ol>

        <h3 className='text-start mt-5'>Example:</h3>

        <div className='text-start p-4 bg-dark  ' style={{color:'#fff'}}>
          <h6>{`<!DOCTYPE html>`}</h6>
         
          <p>{`<html>`}</p>
          <p className='mx-3'>{`<body>`}</p>
          <p className='mx-5'>{`<h1>Welcome to my Page</h1>`}</p>
          <p className='mx-5'>{`<h2>This is a subheading.</h2>`}</p>
          <p className='mx-5'>{`<p>This is some paragraph text.</p>`}</p>
          <p className='mx-3'>{`</body>`}</p>
          <p>{`</html>`}</p>
        </div>

            <h3 className='text-start mt-5'>Best Practices:</h3>
            <ul className='text-start mt-4'>
                <li>Use headings semantically: Don't misuse headings just to make text bold or big.</li>
                <li>Heading Hierarchy: Use {`<h1>`} for the main title, followed by {`<h2>`} for subheadings, and so on.</li>
                <li>Search Engines: Headings are essential for search engine optimization (SEO) as they help search engines understand the content structure.</li>
            </ul>

          <br />
          <br />
          <Link className='px-3' to='/TutorialCss/CommonHTMLTags'>Previous</Link>
          <Link className='px-3' to='/TutorialCss/Paragraphs'>Next</Link>
    </div>
  )
}

export default CreatingHeadings