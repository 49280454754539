import React, { useState } from 'react';
import Styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const Button = Styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #365486;
  color: #fff;
  &:hover {
    background-color: #789461;
  }
`
const ContainerColor = Styled.div`
  width: 300px;
  height: 400px;
  border: solid 1px #000;
  border-radius: 4px;
`
const Span = Styled.span`
padding: 0.2em 1em;
cursor: pointer;
border: solid 1px #000;
border-radius: 4px;

&:hover {
  background-color: #789461;
  color: #fff;
}
`

const ChangeHexToRgb = () => {
  const [hexColor, setHexColor] = useState('#102849');
  const [rgbaColor, setRgbaColor] = useState('');

  const controlHandler = (e) => {
    setHexColor(e.target.value);
  };

  // Function to convert HEX to RGBA
  const hexToRgba = (hex) => {
    if (!hex.startsWith('#') || hex.length !== 7) {
      return 'Invalid HEX code'; // Handle invalid input
    }

    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    const a = 1; // Default alpha value

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  const sendHandler = () => {
    const rgba = hexToRgba(hexColor);
    setRgbaColor(rgba);
  };

  return (
    <div className='container m-auto d-flex flex-column'>
      <h1 className='p-5'>Hex color to Rgba</h1>
        <div className='w-50 d-flex justify-content-between m-auto  '>
          <input
            className='w-75'
            type="text"
            onChange={controlHandler}
            placeholder="#102849   Code hex"
          />
          <Button onClick={sendHandler} type='button'>
            Convert
          </Button>
      </div>
      <Row className='m-auto mt-3'>
        <Col lg={6}>
          <p>Hex code</p>
          <ContainerColor style={{ backgroundColor: hexColor }}></ContainerColor>
          <p className='mt-2'>{hexColor}</p><Span onClick={() => {navigator.clipboard.writeText(hexColor)}}>copy</Span>
        </Col>
        <Col lg={6}>
          <p>Rgba code</p>
          <ContainerColor style={{ backgroundColor: rgbaColor }}></ContainerColor>
          <p className='mt-2'>{rgbaColor}</p><Span onClick={() => {navigator.clipboard.writeText(rgbaColor)}}>copy</Span>
        </Col>
      </Row>
    </div>
  );
};

export default ChangeHexToRgb;
