import { Link } from "react-router-dom"
import { useEffect } from "react";

function FloatingPositioningLayout() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='text-start p-3'>
        <h3 className='text-center'> Floating and positioning for layout</h3>
        <br /><br /><br />

        <p>Floating and positioning are fundamental concepts in CSS that allow you to arrange elements on a webpage beyond the basic linear flow. Here's a breakdown of each:</p>

        <h5>Floating:</h5>
        <ul>
            <li>Moves an element (image, text box etc.) to the left or right of its container.</li>
            <li>Text and other following elements will wrap around the floated element.</li>
            <li>Useful for creating layouts with images alongside text or multi-column layouts.</li>
            <li>Common property used: float: left; (floats to the left) or float: right; (floats to the right).</li>
        </ul>
        <h5 className="mt-5">Positioning:</h5>
        <ul>
            <li>Offers more precise control over an element's location on the page.</li>
            <li>Elements can be positioned anywhere relative to their container or the entire page.</li>
            <li>Useful for creating complex layouts, overlays, or fixed elements that stay in place while scrolling.</li>
            <li>Common properties used: position: absolute; (relative to the nearest positioned ancestor) or position: fixed; (fixed relative to the viewport).</li>
        </ul>

        <h6 className="mt-4">Here's a quick comparison:</h6>
        <table>
            <tr>
                <th>Feature</th>
                <th>Floating</th>
                <th>Positioning</th>
            </tr>
            <tr>
                <td>Functionality</td>
                <td>Moves element along the flow, text wraps around it</td>
                <td>Positions element precisely on the page</td>
            </tr>
            <tr>
                <td>Placement control</td>
                <td>Limited (left or right)</td>
                <td>High (anywhere relative to a reference point)</td>
            </tr>
            <tr>
                <td>Layout complexity</td>
                <td>Simpler layouts (e.g., image beside text)</td>
                <td>Complex layouts, overlays, fixed elements</td>
            </tr>
        </table>

        <h5 className="mt-5">Limitations of Floats for Layout:</h5>
        <ul>
            <li>Floats can cause issues with element order and responsiveness in modern layouts.</li>
            <li>Better alternatives like Flexbox and Grid offer more control and flexibility.</li>
        </ul>


        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/BlockAndInlineLayout'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/FlexBox'>Next</Link>
        </div>



    </div>
  )
}

export default FloatingPositioningLayout