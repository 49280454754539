import {Outlet, useNavigate} from 'react-router-dom';
import { useEffect , useState} from 'react';
// import './tutorialCss.css';
import Helmet from 'react-helmet';
import {Row, Col} from 'react-bootstrap';

import List_ment from './List_menu';
import LinkComponent from './Components/LinkComponent/LinkComponent';

import { MdOutlineMenuOpen } from "react-icons/md";



const  TutorialCss = () => {
  const [btnList , setBtnList] = useState(false);
  const [btnBorder, setBtnBorder] = useState('noneBorderBtn');
  const [listSide, setListSide] = useState('hidden-side-beurger');

  const [menus , setMenus] = useState(List_ment);

  const BtnList_handler = () => {
    if( btnList == false) {
      setBtnList(true);
      setListSide('visible-side-beurger');
      setBtnBorder('BorderBtn')
    }
    else if(btnList == true) {
      setBtnList(false);
      setListSide('hidden-side-beurger');
      setBtnBorder('noneBorderBtn')
    }
  }


  const navigate = useNavigate();
  useEffect(() => {
    navigate('/TutorialCss/BasicHTMLstructure')
  }, []);
  
  return (
    <Row className='ContainerTutorialPages'>
      
      <Helmet>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?       client=ca-pub-1631607859297946"
        crossorigin="anonymous"></script>
        <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        </Helmet>
        
            <h3>CSS</h3>
      <Col lg={2} className='side '>
        
        <div className="fixed">
          {/* Adds */}
          
        </div>
      </Col>
      
      <Col lg={8} className=''>
          <div className={`${listSide} side-menu`}>

          <div className="container-list">
          {
            menus.map(menu => <LinkComponent key={menu.id} {...menu} />)
          }
          </div>

          </div>
        <Row className='row_main'>
          <Col lg={2}  className='container-tutorial-list '>
          <button onClick={BtnList_handler} className={`bt-list ${btnBorder}`} style={{width:'100px'}}>{listSide  && <MdOutlineMenuOpen /> }List</button>
          <div className='fixed-list' >
            <div className="container-link-tutorial">
              {
                menus.map(menu => <LinkComponent key={menu.id} {...menu} />)
              }
            </div>
          </div>
          </Col>
          <Col lg={8} className='main'>
            <br /><br /><br />

            <Outlet />
          </Col>
        </Row>
      </Col>
      <Col lg={2} className='side '>
        <div className="fixed ">
          {/* Adds */}
          
          
        </div>
      </Col>
      
    </Row>
  )
}

export default TutorialCss;