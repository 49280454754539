import {Row,Col} from 'react-bootstrap';
import './spinerStyle.css';
import SpinerComponent from './SpinerComponent';
import { Helmet } from 'react-helmet';

const Spiner = () => {
  return (
    <div className='Spiner'>
      <Helmet>
       <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
       <meta name="robots" content="index, follow" />
        <title>Loaders & spinners</title>
        <meta name="description" content="Discover the ultimate fidget tool with our innovative spinner!" />
        <meta name="keywords" content="css loader, css spinner, loading" />
        {/* Persian */}
        <meta name="description" content="ابزار فیجت نهایی را با اسپینر نوآورانه ما کشف کنید!" />
        <meta name="keywords" content="سی اس اس  لودر, سی اس اس چرخنده, لودر سایت" />
        {/* Japon */}
        <meta name="description" content="革新的なスピナーで究極のそわそわツールを発見してください!" />
        <meta name="keywords" content="CSS ローダー、CSS スピナー、読み込み" />
        {/* armanian */}
        <meta name="description" content="Բացահայտեք ֆիդջեթի վերջնական գործիքը մեր նորարար մանողով:" />
        <meta name="keywords" content="css loader, css spinner, loading" />
        {/* Italian */}
        <meta name="description" content="Scopri lo strumento di fidget definitivo con il nostro innovativo spinner!" />
        <meta name="keywords" content="caricatore css, spinner css, caricamento" />
        {/* Arabic */}
        <meta name="description" content="اكتشف أداة التململ المثالية مع الدوار المبتكر الخاص بنا!" />
        <meta name="keywords" content="محمل CSS، الدوار CSS، التحميل" />
        {/* korea */}
        <meta name="description" content="혁신적인 스피너로 최고의 안절부절 도구를 발견해보세요!" />
        <meta name="keywords" content="CSS 로더, CSS 스피너, 로딩" />
        {/* Turkish */}
        <meta name="description" content="Yenilikçi döndürücümüzle en iyi kıpırdatma aracını keşfedin!" />
        <meta name="keywords" content="css yükleyici, css döndürücü, yükleniyor" />
        {/* china */}
        <meta name="description" content="使用我们创新的旋转器探索终极坐立不安工具！" />
        <meta name="keywords" content="css加载器，css微调器，加载" />
     
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1631607859297946"
        crossorigin="anonymous"></script>
      </Helmet>
        <div className='mt-5 pb-5'>
            

            <Row className=''>
                <Col lg={2} className='position-relative d-flex justify-content-center ' >
                    <div className='Advertising  mt-5 pt-5 px-5 '>

                    </div>
                </Col>
                <Col lg={8} >
                    <SpinerComponent />
                </Col>
                <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
                  <div className='vh-lg-100  position-fixed  '>

                  </div>
                </Col>
        </Row>
        </div>
    </div>
  )
}

export default Spiner