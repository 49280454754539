import { NavLink } from "react-router-dom";
import './LinkComponent.css';

function LinkComponent({
    HeaderTitle,
    MenuList
}) {


  return (
    <div>  {/* Add class for styling */}
      <h6 className="text-start">{HeaderTitle}</h6>  {/* Add class for styling */}
      <ul className="lists">  {/* Add class for styling */}
        {MenuList.map((menuItem) => (
          <li  key={menuItem.LinkMenu}>
            <NavLink to={`/${menuItem.LinkMenu}`}>{menuItem.TitleMenu}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LinkComponent;