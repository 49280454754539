import { Link } from "react-router-dom";
import { useEffect } from "react";

function Booleans() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>Booleans</h5>

        <br /><br />
        <p>In JavaScript, booleans are a fundamental data type that represent logical values. They can only be one of two things: true or false. Booleans are used to represent conditions, make decisions in your code, and control the flow of your program.</p>



        <p className="mt-3">Here are some key points about booleans in JavaScript:</p>

        <ul>
            <li className="mt-3"><b>Literal values:</b> You can directly assign true or false to variables to create booleans.</li>
            <li className="mt-3"><b>Conditions and control flow:</b> Booleans are essential for writing conditional statements like if, else if, and switch. These statements evaluate conditions (which return true or false) and execute different code blocks based on the outcome.</li>
            <li className="mt-3"><b>Logical operators:</b> JavaScript provides logical operators like && (AND), || (OR), and ! (NOT) to combine booleans and create more complex conditions.</li>
            <li className="mt-3"><b>Truthy and falsy values:</b> In addition to true and false, JavaScript has some values that are considered "truthy" or "falsy" in boolean contexts. These include 0, "" (empty string), undefined, and null. When used in conditions, truthy values evaluate to true, while falsy values evaluate to false.</li>
        </ul>


        <p className="mt-5">Here's an example of how booleans are used in JavaScript:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">let</span> isLoggedIn  = <span className="text-danger">true</span>;</p>
            <p className="mt-2"><span className="text-warning">if</span> (isLoggedIn) {`{`}</p>
                <p className="mx-3"><span className="text-warning">console.log</span>(<span className="text-info">"Welcome back!"</span>);</p>
            <p>{`}`} <spa className="text-warning">else</spa> {`{`}</p>
                <p className="mx-3"><span className="text-warning">console.log</span>(<span className="text-info">"Please log in."</span>);</p>
            <p>{`}`}</p>
        </div>

        <p className="mt-4">In this example, the isLoggedIn variable is a boolean that determines whether the user is logged in. The if statement checks the condition and displays a message based on the boolean value.</p>

        <p className="mt-3">Booleans are a basic but powerful concept in JavaScript programming. By understanding how to use them effectively, you can write code that makes decisions and responds to different conditions.</p>















        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Numbers' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Arithmetic' >Next</Link>
        </div>
    </div>
  )
}

export default Booleans