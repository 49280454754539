import { Link } from "react-router-dom"
import { useEffect } from "react";

function DescendantSelectors() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3'>
        <h3>CSS selectors</h3>
        <br/><br/>

        <h5 className="text-start mt-5">Descendant selectors:</h5>
        <ul className="text-start">
            <li>Targets any element that is nested below another element in the HTML.</li>
            <li className="mt-1">Represented by a space between two selectors.</li>
            <li className="mt-1">For example, ul li selects all <span className="light">{`<li>`}</span> elements that are descendants of any <span className="light">{`<ul>`}</span> element. Even if the <span className="light">{`<li>`}</span> element is nested within other elements like a <span className="light">{`<div>`}</span>, it will still be selected.</li>
        </ul>

        <h5 className="text-start mt-5">Child Selector:</h5>

        <ul className="text-start">
            <li>Targets only the direct children of another element.</li>
            <li>Represented by a greater than sign (<span className="light">{`>`}</span>) between two selectors.</li>
            <li>Targets elements that are one level deeper than the first selector.</li>
            <li>For example, ul <span className="light">{`>`}</span> li selects only the <span className="light">{`<li>`}</span> elements that are directly nested within a <span className="light">{`<ul>`}</span>, not any nested deeper.</li>
        </ul>

        <h5 className="text-start mt-5">Sibling Selector:</h5>
        <p className="mt-4 text-start">There are two types of sibling selectors:</p>
        <ul>
            <li className="text-start"><b>Adjacent Sibling Selector</b>: Targets an element that is immediately after another specific element, sharing the same parent.</li>
            <li className="text-start">Represented by a plus sign (<span className="light">+</span>) between two selectors.</li>
            <li className="text-start">For example,<span className="light"> h1 + p</span> selects the <span className="light">{`<p>`}</span> element that directly follows an <span className="light">{`<h1>`}</span> element.</li>
        </ul>

        <ul className="mt-5 text-start">
            <li><b>General Sibling Selector</b>: Targets any element that is a sibling of another element, regardless of order.</li>
            <li>Represented by a tilde (<span className="light">~</span>) between two selectors.</li>
            <li>For example, h1 ~ p selects all <span className="light">{`<p>`}</span> elements that are siblings of an <span className="light">{`<h1>`}</span> element, even if other elements come between them.</li>
        </ul>

        <br/><br/>
        <h6>Here's a table summarizing the differences:</h6>

            <table>
                <tr className="text-start">
                    <th>Selector</th>
                    <th>Target</th>
                    <th>Symbol</th>
                    <th>Example</th>
                </tr>
                <tr>
                    <td>Descendant</td>
                    <td>Any element nested below another </td>
                    <td>Whitespace</td>
                    <td><span className="light">{`ul li`}</span></td>
                </tr>
                <tr>
                    <td>Child</td>
                    <td>Direct children of another element</td>
                    <td><span className="light">{`>`}</span></td>
                    <td><span className="light">{`ul > li`}</span></td>
                </tr>
                <tr>
                    <td>Adjacent Sibling</td>
                    <td>Element directly following another element (same parent)</td>
                    <td><span className="light">+</span></td>
                    <td><span className="light"> h1 + p</span></td>
                </tr>
                <tr>
                    <td>General Sibling</td>
                    <td>Any sibling element (same parent)</td>
                    <td><span className="light">~</span></td>
                    <td><span className="light">h1 ~ p</span></td>
                </tr>
            </table>

            <div className="mt-5 bg-dark text-light text-start p-3">
                <h5>Css</h5>
                <br />
                <br />
                <p>{`ul > li {`}</p>
                <p className="px-4">{`list-style-type: disc;`}</p>
                <p className="px-4">{`color: red;`}</p>
                <p className="px-4">{`font-size: 24px;`}</p>
                <p className="px-4">{`font-family: sans-serif;`}</p>
                <p>{`}`}</p>
                <br />
                <p>{`p {`}</p>
                <p>{`color: black;`}</p>
                <p>{`font-family: Verdana, sans-serif;`}</p>
                <p>{`font-size: 16px;`}</p>
                <p>{`line-height: 1.5;`}</p>
                <p>{`}`}</p>
            </div>
            <p className="text-start mt-2">In this example, we have two sets of CSS rules:</p>
                <ul className="text-start">
                    <li>The first rule applies to all <span className="light">{`<li>`}</span> elements on the page. This rule changes the text color to red, the font to Arial or another sans-serif font, the font size to 24 pixels, and the text alignment to center.</li>
                    <li>The second rule applies to all <span className="light">{`<p>`}</span> elements on the page. This rule changes the text color to black, the font to Verdana or another sans-serif font, the font size to 16 pixels, and the line height to 1.5.</li>
                </ul>

            <p className="text-start">To use this CSS, you must save it in a CSS file and then link that file to your HTML page. You can do this using the <span className="light">{`<link>`}</span> tag:</p>

            <p className="text-start">First, we choose the name for the css file and put a dot, and then write the css</p>
            <p className="text-start"><span className="light">namefile.css</span></p>
            <div className="mt-5 bg-dark text-light text-start p-3">
                <h5>HTML</h5>
                <br/>
                <br/>
                <p>{`<head>`}</p>
                <p className="px-4">{`<link rel="stylesheet" href="style.css">`}</p>
                <p>{`</head>`}</p>
            </div>
            <p className="mt-3 text-start">In this example, style.css is the name of your CSS file.</p>
            <p className="mt-3 text-start">Once you've done this, all <span className="light">{`<h1>`}</span> and <span className="light">{`<p>`}</span> elements on your page will appear according to your CSS rules.</p>

            <br />
            <br />

            <Link className="px-3" to='/TutorialCss/BasicSelectors'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/AttributeSelectors'>Next</Link>

    </div>
  )
}

export default DescendantSelectors