import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Attention from '../../assets/Image/PrivacyPolici/attention.png';
import Email from '../../assets/Image/PrivacyPolici/gmail.png';
import Notification from '../../assets/Image/PrivacyPolici/notification.png'
import './PrivaciPoloici.css'

const PrivacyPolici = () => {
  return (
    <div className='Privacy'>
        <div className='w-100 h-auto  mt-5 '>
            <Row className='mt-5'>
                <Col lg={3} className=' p-3 h-100'>
                    <div className='p-2  '>
                        <div className='w-25 d-flex m-auto py-3'>
                            <img src={Attention} alt="privacy policy" style={{width:'100%'}} />
                        </div>
                        <div className=''>
                            
                        </div>
                    </div>
                </Col>
                <Col lg={9} className=' p-3 h-100'>
                    <div className='pt-5   '>
                        <h3 className='text-start'>STYLES CSS RIVACY POLICY</h3>
                    </div>
                    <div className=''>
                    <div className='border p-5 rounded'>
                        <p className='text-start'>We started offering a new choice about ads on Janvier 12, 2024. This choice means we now rely on your consent for using your information to show you ads. We  updated our Privacy Policy to reflect these changes. </p>
                        <h3 className='text-start mt-4'>User choices</h3>
                        <p className='text-start mt-3'>Users have the right to approve or reject advertisements</p>
                        <p className='text-start text-warning'>And we will not misuse your information in any way.
                            And we respect your privacy.</p>
                        <p className='text-start text-warning'>Here, you do not need to register or give your information.</p>
                        <p className='text-start text-success mt-5'> If you have any questions about this Privacy Policy, you can contact us. ( <a href="mailto:sheikhlaryounes@gmail.com">Younes sheikhlar</a> <img src={Email} alt="Email" /> ) </p>

                        <p className='text-start'>If the privacy policy changes, we will notify you through this page</p>
                    </div>

                    <div className='border mt-2 p-5 rounded'>
                        <p className='text-start'>You can easily use styles, components and css code generators. without paying any amount. </p>
                    </div>

                    <div className='mt-5'>
                        <h3 className='text-start'>To communicate with you</h3>
                        <p className='text-start my-3'>We provide you with new news offers through the notifications section <a href="">Notification</a> <img style={{width:'20px'}} src={Notification} alt="notification" /></p> 
                    </div>

                    <div className='mt-5'>
                        <h3 className='text-start'>What is our legal basis for processing your information, and what are your rights?</h3>

                        <p className='text-start my-3'>Under applicable data protection law, companies must have a legal basis for processing personal data. When we talk about "personal data processing", we mean the ways in which your information is collected, used and shared, as described in other sections of this policy above.
                        We will not collect any information from you.</p>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default PrivacyPolici