const Links = [
    {
        id: 1,
        HeaderTitle: "Introduction to JS",
        MenuList : [
            { LinkMenu: 'Tutorial_JS/Introduction_js', TitleMenu: 'Introduction'},
            { LinkMenu: 'Tutorial_JS/HistoryOfJavaScript', TitleMenu: 'History of javaScript'},
            { LinkMenu: 'Tutorial_JS/JavaScriptCodeInHTML', TitleMenu: 'JS code in HTML'},
           
        ]
    },
    {
        id: 2,
        HeaderTitle: "Data types",
        MenuList : [
            { LinkMenu: 'Tutorial_JS/Variables', TitleMenu: 'Variables'},
            { LinkMenu: 'Tutorial_JS/String', TitleMenu: 'String'},
            { LinkMenu: 'Tutorial_JS/Numbers', TitleMenu: 'Number'},
            { LinkMenu: 'Tutorial_JS/Booleans', TitleMenu: 'Booleans'},
        
        ]
    },
    {
        id: 3,
        HeaderTitle: "Operators",
        MenuList : [
            { LinkMenu: 'Tutorial_JS/Arithmetic', TitleMenu: 'Arithmetic'},
            { LinkMenu: 'Tutorial_JS/Comparison', TitleMenu: 'Comparison'},
            { LinkMenu: 'Tutorial_JS/LogicalOperators', TitleMenu: 'Logical operators'},
        
        ]
    },
    {
        id: 4,
        HeaderTitle: "Loops",
        MenuList : [
            { LinkMenu: 'Tutorial_JS/ForLoop', TitleMenu: 'For Loop'},
            { LinkMenu: 'Tutorial_JS/While', TitleMenu: 'While'},
            { LinkMenu: 'Tutorial_JS/DoWhile', TitleMenu: 'Do while'},
            { LinkMenu: 'Tutorial_JS/ForEach', TitleMenu: 'For each'},
        
        ]
    },
    {
        id: 5,
        HeaderTitle: "Function",
        MenuList : [
            { LinkMenu: 'Tutorial_JS/Function', TitleMenu: 'Function'},
            { LinkMenu: 'Tutorial_JS/Parameters', TitleMenu: 'Parameters'},
            { LinkMenu: 'Tutorial_JS/FunctionInvocation', TitleMenu: 'Function invocation'},
            { LinkMenu: 'Tutorial_JS/FunctionCall', TitleMenu: 'Function call'},
        
        ]
    }
]

//  

export default Links;





// {
//     id: ,
//     HeaderTitle: "",
//     MenuList : [
//         { LinkMenu: '', TitleMenu: ''},
       
//     ]
// }


