import { Link } from "react-router-dom";
import { useEffect } from "react";

function DoWhile() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>do-while</h5>
        <br /><br />

        <p>In JavaScript, a do-while loop is a control flow statement that executes a block of code repeatedly until a specified condition evaluates to false. It's similar to a while loop, but the key difference is that in a do-while loop, the block of code is executed at least once before the condition is checked.</p>


        <p className="mt-5">Here's the basic syntax:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">do</span> {`{`}</p>
            <p className="mx-4 text-secondary">// code block to be executed</p>
            <p>{`}`} <span className="text-warning">while</span>( <span className="text-info">condition</span> );</p>
        </div>


        <p className="mt-5">The flow of execution is as follows:</p>

        <ol>
            <li>The code block within the do statement is executed.</li>
            <li>The condition specified in the while statement is evaluated.</li>
            <li>If the condition evaluates to true, the code block is executed again. This process repeats until the condition evaluates to false.</li>
            <li>Once the condition becomes false, the loop terminates, and the program continues with the next line of code after the do-while block.</li>
        </ol>


        <p className="mt-5">Here's a simple example:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">let</span> count = <span className="text-danger">0</span>;</p>
            <br />
            <p><span className="text-warning">do</span> {`{`}</p>
            <p className="mx-4"> <span className="text-info">console.log</span>(<span className="text-success">"Count is: "</span> + count)</p>
            <p className="mx-4">count++</p>
            <p>{`}`} <span className="text-warning">while</span>(count {`<`} <span className="text-danger">5</span>);</p>
        </div>

        <p className="mt-2">In this example, the code block inside the <b>do</b> statement will execute once, even if the condition  <span className="light">count {`< 5`}</span> is initially false. Then, it will check the condition, and if it's still true, it will execute the block again. It repeats this process until the condition becomes false (when <b>count</b> reaches 5 in this case).</p>



        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/While' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/ForEach' >Next</Link>
        </div>
    </div>
  )
}

export default DoWhile