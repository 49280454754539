import {NavLink} from 'react-router-dom';
import './Btn_link.css';

function BtnLikn({
    HeaderTitle,
    MenuList
}) {
  return (
    <div className='container-list'>
        <h6 className="text-start">{HeaderTitle}</h6>  {/* Add class for styling */}
        <ul className="lists">  {/* Add class for styling */}
          {MenuList.map((menuItem) => (
            <li className=''  key={menuItem.LinkMenu}>
              <NavLink to={`/${menuItem.LinkMenu}`}>{menuItem.TitleMenu}</NavLink>
            </li>
          ))}
        </ul>
    </div>
  )
}

export default BtnLikn;

