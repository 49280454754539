import { useEffect } from "react";
import { Link } from "react-router-dom";

function Parameters() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <div className='text-start p-3'>
        <h5>Parameters</h5>
        <br /><br /><br />

        <p>Parameters in JavaScript functions are like placeholders that you define when you create the function. They act as variables that hold the values that are passed to the function when it's called. These values, called arguments, are used by the function to perform its operations and potentially produce an output.</p>

        <br /><br />
        <p><b>Here's a breakdown of the concept:</b></p>
        <ul>
            <li>Function definition: When you create a function, you can specify parameters within parentheses following the function name. These parameters are like empty containers waiting to be filled with values.</li>
            <li>Function call: When you execute the function (call it), you provide actual values, separated by commas, inside the parentheses. These values are the arguments that get assigned to the parameters.</li>
        </ul>

        <br /><br />
        <p><b>Analogy:</b> Imagine a function as a bakery that makes cookies. The parameters in the function definition are like the ingredients list (flour, sugar, eggs). When you call the function (place an order), you provide the specific quantities of ingredients (arguments) that you want in your cookies.</p>

        <br /><br />
        <h6>Example:</h6>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />

            <p><span className="text-info">function</span> <span className="text-warning">greet</span> (name) {`{`}</p>
            <p className="mx-4"><span className="text-danger">console.log</span>( <span className="text-success">"Hello, "</span> + name <span className="text-success">"!"</span>)</p>
            <p>{`}`}</p>
            <p>greet ( <span className="text-success">"Younes"</span>); <span className="text-secondary">// Output: "Hello, Younes!"</span></p>
        </div>

        <p className="mt-5">In this example, the <span className="light">greet</span> function has a parameter named <span className="light">name</span>. When you call the function with the argument <span className="light">"Younes"</span>, the value <span className="light">"Younes"</span> is assigned to the name parameter inside the function. The function then uses this value to construct the greeting message.</p>

        <br /><br /><br />

        <p><b>Key points to remember:</b></p>

        <ul>
            <li>Parameters are defined in the function declaration, arguments are provided during the function call.</li>
            <li>JavaScript functions are generally passed by value, meaning the function receives a copy of the argument's value, not the original value itself. So, changes made to arguments inside the function won't affect the original variables outside the function.</li>
        </ul>
















        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Function' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/FunctionInvocation' >Next</Link>
        </div>
    </div>
  )
}

export default Parameters