import { Helmet } from "react-helmet";
import Header from "../../Components/Landing-page/Header/Header";
import Main from "../../Components/Landing-page/Main/Main";
const LandingPage = () => {
  return (
    <div className="LandingPage">
      {/* Meta tags */}
      <Helmet>
        <title>Styles - css</title>
        <meta
          name="description"
          content="Styles css - A tool to easily find CSS codes."
        />
        <meta
          name="keywords"
          content="CSS, CSS code  box-shadow, gradient, components, CSS tools"
        />
        {/* Persian */}
        <meta
          name="description"
          content="Styles css - ابزاری برای یافتن آسان کدهای CSS."
        />
        <meta
          name="keywords"
          content="CSS، کد CSS، جعبه سایه، گرادیان، اجزا، ابزارهای CSS"
        />
        {/* Dutch */}
        <meta
          name="description"
          content="Styles css - Een hulpmiddel om eenvoudig CSS-codes te vinden."
        />
        <meta
          name="keywords"
          content="CSS, CSS-codevakschaduw, verloop, componenten, CSS-tools"
        />
        {/* Spanish */}
        <meta
          name="description"
          content="Styles css: una herramienta para encontrar fácilmente códigos CSS."
        />
        <meta
          name="keywords"
          content="CSS, código CSS box-shadow, degradado, componentes, herramientas CSS"
        />
        {/* Japan */}
        <meta
          name="description"
          content="Styles css - CSS コードを簡単に検索するツール。"
        />
        <meta
          name="keywords"
          content="CSS、CSS コードボックス - シャドウ、グラデーション、コンポーネント、CSS ツール"
        />
        {/* Chain */}
        <meta
          name="description"
          content="Styles css - 轻松查找 CSS 代码的工具。"
        />
        <meta
          name="keywords"
          content="CSS、CSS代码框阴影、渐变、组件、CSS工具"
        />
        {/* Arabic */}
        <meta
          name="description"
          content="Styles css - أداة للعثور بسهولة على رموز CSS."
        />
        <meta
          name="keywords"
          content="CSS، ظل مربع كود CSS، التدرج، المكونات، أدوات CSS"
        />
        {/* Armenian */}
        <meta
          name="description"
          content="Styles css - CSS կոդերը հեշտությամբ գտնելու գործիք:"
        />
        <meta
          name="keywords"
          content="CSS, CSS կոդի տուփ-ստվեր, գրադիենտ, բաղադրիչներ, CSS գործիքներ"
        />
        {/* Italy */}
        <meta
          name="description"
          content="Stili css - Uno strumento per trovare facilmente i codici CSS."
        />
        <meta
          name="keywords"
          content="CSS, codice box-shadow CSS, gradiente, componenti, strumenti CSS"
        />
        {/* korean */}
        <meta
          name="description"
          content="스타일 CSS - CSS 코드를 쉽게 찾을 수 있는 도구입니다."
        />
        <meta
          name="keywords"
          content="CSS, CSS 코드 상자 그림자, 그라디언트, 구성 요소, CSS 도구"
        />
      </Helmet>

      
      <Header />

      <Main />
    </div>
  );
};

export default LandingPage;
