import { Link } from "react-router-dom";
import { useEffect } from "react";

function AdaptiveDesign() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Adaptive design</h3>
        <br /><br /><br /><br />
        <p>Adaptive design and responsive design are two approaches to making websites work well on different devices, but they have some key differences. Here's how relative units come into play with adaptive design:</p>



        <h6 className="mt-5">Adaptive Design vs. Responsive Design:</h6>
        <ul>
            <li><b>Responsive Design</b>: This approach uses a single website that adjusts its layout and styling based on the screen size of the device. Media queries are heavily relied upon to achieve this fluid responsiveness.</li>
            <li><b>Adaptive Design</b>: This approach creates separate layouts specifically designed for different device categories (desktop, tablet, mobile). Media queries might be used to switch between these pre-defined layouts at set breakpoints.</li>
        </ul>


        <h6 className="mt-5">Relative Units and Adaptive Design:</h6>
        <ul>
            <li><b>Relative Units</b>: These are CSS units that define sizes or positions relative to another element or the viewport (visible area of the browser). Examples include <span className="light">em</span>, <span className="light">rem</span>, <span className="light">vh</span>, and <span className="light">vw</span>.</li>
            <li><b>Benefits in Adaptive Design</b>: Since adaptive design uses separate layouts, relative units can be very helpful in defining element sizes and spacing within those layouts. They ensure elements scale proportionally within their specific layout without needing extensive media query adjustments.</li>
        </ul>


        <p className="mt-5">Here's a breakdown of some common relative units and their use in adaptive design:</p>
        <ul>
            <li>em: This unit is relative to the font size of the parent element. Useful for defining margins, padding, and element sizes that scale proportionally within a layout.</li>
            <li>rem: Similar to em but relative to the font size of the root element (usually <span className="light">{`<html>`}</span>) . This helps maintain consistent sizing across all elements in a layout.</li>
            <li>vh: This unit is a percentage of the viewport height. Useful for defining element heights or positions relative to the viewport, especially in mobile layouts.</li>
            <li>vw: Similar to vh but a percentage of the viewport width. Useful for defining element widths or positions relative to the viewport on mobile or tablet layouts.</li>
        </ul>


        <h6 className="mt-5">Example:</h6>

        <p>Imagine you have a separate layout for mobile devices where you want a navigation bar to take up 10% of the viewport height. You can use <span className="light">vh</span> for this:</p>


        <div className="text-light bg-dark p-3">
            <h6>CSS</h6>
            <br />
            <p><span className="text-warning">.mobile-nav </span> {`{`}</p>
            <p className="mx-4">height: <span className="text-info">10vh</span>;</p>
            <p>{`}`}</p>
        </div>


        <p className="mt-3">This ensures the navigation bar stays a consistent 10% of the screen height regardless of the specific mobile device being used.</p>

        <p><b>Important Considerations:</b></p>
        <ul>
            <li>While relative units offer flexibility in adaptive design, they can become cumbersome if you have nested elements with multiple <span className="light">em</span> definitions.</li>
            <li>It's important to plan your layout breakpoints carefully and consider how elements using relative units will behave when switching between layouts.</li>
            <li>Responsive design, with its fluid adjustments using media queries, might be more suitable for complex layouts that need to adapt across a wide range of screen sizes.</li>
        </ul>


        <p className="mt-5">In conclusion, relative units are a valuable tool for defining element sizes and positions within adaptive design layouts. They ensure proportional scaling within each specific layout, reducing the need for extensive media query adjustments. However, for complex and fluid layouts, responsive design might be a more effective approach.</p>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Devices'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Introduction'>Next</Link>
        </div>
    </div>
  )
}

export default AdaptiveDesign