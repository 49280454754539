import { Link } from "react-router-dom"
import { useEffect } from "react";

function MarginPadding() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

      
  return (
    <div className='p-3'>
        <h3>Margin & Padding</h3>
        <br />
        <br />
        <p className="text-start">In CSS, margins are used to control the amount of white space around an element, outside of its border. It's like creating a buffer zone around the element. This space separates the element from its neighbors and the surrounding content.</p>

        <p className="text-start">Here's a breakdown of margins in CSS:</p>
        <ul className='text-start'>
            <li><b>Purpose</b>: Adds spacing outside the border of an element.</li>
            <li><b>Difference from Padding</b>: Padding creates space inside the border, but margins create space outside.</li>
            <li><b>Controlling Margins</b>:</li>
            <ul >
                <li>You can set margins for each side of an element individually: top, right, bottom, and left.</li>
                <li>There's also a shorthand property <span className='light'>margin</span> that lets you set margins for all sides at once with different values.</li>
            </ul>
        </ul>
        <p className="text-start mt-3">By adjusting margins, you can control the layout of your webpage and create space between elements for better readability.</p>

        <p className="text-start mt-5">Here's an example of using margins in CSS:</p>
        <div className="bg-dark text-start text-light p-3">
            <h5>HTML</h5>
            <br />
            <br />
            <p>{`<!DOCTYPE html>`}</p>
            <p>{`<html>`}</p>
            <p>{`<head>`}</p>
            <p className='text-warning'>{`<style>`}</p>
            <p className='mx-3 text-danger'>{`.content {`}</p>
            <p className='mx-5'>{`margin: 20px; `} <span className='text-secondary'>/* Creates a 20px margin around all sides of the element */</span></p>
            <p className='mx-5'>{`border: 1px solid #ddd;`} <span className='text-secondary'>/* Adds a thin gray border for reference */</span></p>
            <p className='mx-5'>{`padding: 10px;`} <span className='text-secondary'>/* Adds some padding inside the border */</span></p>
            <p className='mx-3'>{`}`}</p>
            <p className='text-warning'>{`</style>`}</p>
            <p>{`</head>`}</p>
            <p>{`<body>`}</p>
            <p className='mx-3'>{`<p`} <span className='text-danger'>class="content"</span> {`This is a paragraph with margins and padding applied. As you can see, the margin creates space outside the border, while the padding creates space inside.</p>`}</p>
            <p>{`</body>`}</p>
            <p>{`</html>`}</p>
        </div>

        <p className="text-start">In this example:</p>
        <ul className="text-start">
            <li className='mt-1'>We have a <span className="light">{`<p>`}</span> element with a class of <span className="light">"content"</span>.</li>
            <li className='mt-1'>The CSS style defines margins for the <span className="light">"content"</span> class.</li>
            <li className='mt-1'>The <span className="light">margin: 20px;</span> property sets a 20-pixel margin around all four sides of the element (top, right, bottom, and left)</li>
            <li className='mt-1'>The <span className="light">border</span> property adds a thin gray border to the element, which helps visualize the margin's effect.</li>
            <li className='mt-1'>The <span className="light">padding: 10px;</span> property adds some padding inside the border, demonstrating the difference between margin and padding</li>
        </ul>

        <p className="text-start">This is a simple example, but it shows how margins can be used to create space around elements on your webpage. You can experiment with different margin values and see how they affect the layout.</p>

    <br />
      <br />
      <Link className="px-3" to='/TutorialCss/FontSize'>Previous</Link>
      <Link className="px-3" to='/TutorialCss/TextEffects'>Next</Link>
    </div>
  )
}

export default MarginPadding