import { Link } from "react-router-dom"
import { useEffect } from "react";

function Colors() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className='text-center'>Colors</h3>
        <br /><br /><br />

        <p>There are two main categories for specifying colors in CSS:</p>

        <p className="mt-4"><b>Color Keywords</b>: These are predefined names that correspond to specific colors. For example, <span className="light">red</span>, <span className="light">blue</span>, <span className="light">green</span>, and <span className="light">yellow</span> are all color keywords. This is a simple and easy-to-use method, but it offers a limited color palette.</p>
        <p><b>Color Values</b>: These provide more control over the exact color you want to use. There are several different color value methods in CSS, each with its own advantages:</p>

        <ul className="mt-5">
            <li>Hexadecimal: This format uses a six-digit code preceded by a hash symbol ( <span className="light">#</span> ). For instance, <span className="light">#FF0000</span> represents red, and <span className="light">#FFFFFF</span> is white.</li>
            <li><b>RGB (Red, Green, Blue)</b>: This method defines colors based on the intensity of red, green, and blue light. Each value ranges from 0 to 255 (or 0% to 100%). So, <span className="light">rgb(0, 0, 255)</span> is blue, and <span className="light">rgb(255, 255, 255)</span> is white.</li>
            <li>RGBA (RGB with Alpha): This builds upon RGB by adding an alpha channel (transparency) value between 0 (fully transparent) and 1 (fully opaque).</li>
            <li><b>HSL (Hue, Saturation, Lightness)</b>: This approach uses hue (color angle), saturation (color intensity), and lightness (color brightness) values to define colors. It can be more intuitive for some users.</li>
            <li><b>HSLA (HSL with Alpha)</b>: Similar to RGBA, HSLA incorporates an alpha channel for transparency into the HSL model.</li>
        </ul>

        <h6>Examples of colors:</h6>

        <div className='w-100  border d-flex' style={{height:'200px'}}>
            <div className='w-25 h-100 p-2 bg-danger mx-1 text-light d-flex flex-column justify-content-center align-items-center'>
                <p>rgb(220,53,69)</p>
                <p>#DC3545</p>
                <p>hsl(354, 70%, 54%)</p>
                <p></p>
            </div>
            <div className='w-25 h-100 bg-primary mx-1 text-light d-flex flex-column justify-content-center align-items-center'>
                <p>rgb(13,110,253)</p>
                <p>#0D6EFD</p>
                <p>hsl(216, 98%, 52%)</p>
            </div>
            <div className='w-25 h-100 bg-success mx-1 text-light d-flex flex-column justify-content-center align-items-center'>
                <p>rgb(25, 135, 84)</p>
                <p> #198754</p>
                <p>hsl(152, 69%, 31%)</p>
            </div>
            <div className='w-25 h-100 bg-warning mx-1 d-flex flex-column justify-content-center align-items-center'>
                <p>rgb(255,193,7)</p>
                <p>#ffc107</p>
                <p>hsl(45, 100%, 51%)</p>
            </div>
        </div>

        <p className='mt-5'>The best method for you depends on your specific needs and preferences. Consider factors like readability, browser compatibility, and the level of color control you require.</p>


        
        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Grid'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Gradients'>Next</Link>
        </div>
    </div>
  )
}

export default Colors