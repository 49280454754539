import {Row, Col} from 'react-bootstrap';
import FadeIn from 'react-fade-in';
import {Link} from 'react-router-dom';

const MainTwo = () => {

  return (
    <div className='MainTwo' >
      <h1 className='text-light'>Color palette</h1>

      <Row className='w-75  m-auto pt-5 mt-3'>
        <Col lg={6}>
          <div className='ContainerTextColorPalette' >
            <h3 className='text-xs-center text-lg-start '>The purpose of your design.</h3>
            <p className='text-xs-center text-lg-start '>What are you trying to achieve with your design? Are you trying to create a calming atmosphere? A sense of excitement? Once you know the purpose of your design, you can start to choose colors that will help you achieve that goal.</p>
            <h4 className=' text-xs-center text-lg-start'>How to customize a color palette</h4>
            <p className='text-xs-center text-lg-start '>Once you've chosen a color palette, you can customize it to suit your specific needs.Just click on the edit brush</p>
          </div>
          <p className='text-xs-center text-lg-start text-light'>Change the hue, saturation, or lightness of the colors. You can use a color wheel or a color picker to change the hue, saturation, or lightness of the colors in your palette. This will help you to create a unique look that fits your specific needs.</p>
        </Col>
        <Col lg={6}>
          <div className='ContainerPalette '>
            <div className='palette '>
                  <FadeIn delay={100} >
                    <div className='item1'></div>
                    <div className='item2'></div>
                    <div className='item3'></div>
                    <div className='item4'></div>
                    <div className='item5'></div>
                    <div className='item6'></div>
                    <div className='item7'></div>
                    <div className='item8'></div>
                    <div className='item9'></div>
                    <div className='item10'></div>
                    <div className='item11'></div>
                    <div className='item12'></div>
                    <div className='item13'></div>
                    <div className='item14'></div>
                    <div className='item15'></div>
                    <div className='item16'></div>
                    <div className='item17'></div>
                    <div className='item18'></div>
                  </FadeIn>
            </div>
          </div>
        </Col>
      </Row>
      <Link to="https://styles-css.com/palettecolor" className='w-75 m-auto btn border text-light'>Color palette</Link>
    </div>
  )
}

export default MainTwo