import {Link} from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className='About'>
        <h4 className='mt-4'>About</h4>
        <div className='container mt-5'>
            <p className='text-start'>We are proud to present to you a website that has been created with the aim of collecting your opinions, criticisms and suggestions, dear users. Our goal in creating this website is to better communicate with you and create products that suit your needs.</p>


            <p className='text-start'>We believe that your comments are very valuable to us and can play a significant role in improving the quality of our products and services.</p>

            
            <h3 className='text-start mt-5'>Through this website you can:</h3>

            <p className='text-start mt-3'>Share your feedback about our products and services.</p>
            <p className='text-start'>Submit new ideas for new products and services.</p>
            <p className='text-start'>Find out about our latest news and events.</p>
            <p className='text-start'>We will carefully consider all your comments and suggestions and use them to improve the quality of our products and services.</p>
            <p className='text-start'>We invite you to join us on this website and share your comments and suggestions with us.</p>
            <p className='text-start'></p>
            <p className='text-start'></p>

            <h4 className='text-start mt-5'>Thanks</h4>
            <p className='text-start'>styles-css team</p>
            <div>
                <Link to='' >Contact</Link>
            </div>
        </div>

    </div>
  )
}

export default About