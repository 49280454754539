import { Link } from "react-router-dom"
import { useEffect } from "react";

function MediaQueries() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">MediaQueries</h3>

       <p className="mt-4">Media queries are a powerful feature in Cascading Style Sheets (CSS) that allow you to apply styles conditionally depending on the device or environment where the web page is being viewed. This is particularly important for creating responsive web design (RWD), which ensures that your website looks good and functions properly on all devices, from large desktops to small smartphones.</p>


        <h6>Here's a breakdown of how media queries work:</h6>
        <ul>
          <li><b>Media Type</b>: You can specify the media type, such as screen (for computer screens), print (for printing), or handheld (for small devices like phones). If no media type is specified, the query applies to all media types.</li>
          <li><b>Media Features</b>: These are conditions that check characteristics of the device, such as screen size (width or height), orientation (portrait or landscape), resolution, or even user preferences like reduced motion mode.</li>
          <li><b>Media Query Syntax</b>: The media query uses the @media rule in CSS followed by the media type and media features in parentheses. If all the conditions are met, the styles defined within the curly braces are applied.</li>
        </ul>

        <p className="mt-5">Here's an example of a simple media query that changes the font size for screens with a width less than 600 pixels:</p>


        <div className="p-3 text-light bg-dark">
          <h6>CSS</h6>
          <br />
          <p><span className="text-warning">@media </span> screen <span className="text-warning"> and </span>(max-width: <span className="text-info">600px</span>)  {`{`}</p>
          <p className="mx-3"><span className="text-info">body</span> {`{`}</p>
          <p className="mx-5">font-size: <span className="text-info">16px</span>;</p>
          <p className="mx-3">{`}`}</p>
          <p>{`}`}</p>
        </div>

        <p className="mt-5">Media queries offer a lot of flexibility in adapting your website's layout and styling based on the device. You can use them to:</p>
        <ul>
          <li>Change the size and position of elements</li>
          <li>Adjust the number of columns in a layout</li>
          <li>Hide or show specific content</li>
          <li>Use different images optimized for different screen sizes</li>
        </ul>






        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/MediaQueries'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Devices'>Next</Link>
        </div>
    </div>
  )
}

export default MediaQueries