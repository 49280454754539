import {Link} from 'react-router-dom';
import { useEffect } from "react";

function Table() {
  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className="px-3">
      <h3>Tables</h3>
      <br/>
      <br/>
      <br/>
      <p className="text-start">HTML tables are used to present data in a structured format of rows and columns, just like a spreadsheet or a calendar. This makes it easy for users to read and compare information. Here's a breakdown:</p>
        
        <br/>
        <br/>
        <br/>
        <h4 className="text-start">Structure:</h4>
        <ul className="text-start">
          <li>An HTML table is defined by the {`<table>`} tag.</li>
          <li>Rows are created using the {`<tr>`} tag.</li>
          <li>Cells within each row are defined by {`<td>`} (data cell) or {`<th>`} (header cell) tags.</li>
        </ul>
        <br/>
        <br/>
        <br/>

        <h4 className="text-start">Content:</h4>
        <ul className="text-start">
          <li>Data goes inside the {`<td>`} cells.</li>
          <li>Headers for columns are placed within {`<th>`} cells.</li>
        </ul>
        <br/>
        <br/>
        <br/>
        <h4 className="text-start">Additional Features:</h4>
        <ul className="text-start">
          <li>You can add captions to your tables using the {`<caption>`} tag.</li>
          <li>There are advanced tags for grouping rows and columns for more complex tables.</li>
        </ul>
        <br/>
        <br/>
        <br/>

        <h4 className="text-start">When to Use Tables:</h4>
        <ul className="text-start">
          <li>Tables are ideal for presenting tabular data like prices, schedules, or product comparisons.</li>
        </ul>
        <br/>
        <br/>
        <br/>
        <h4 className="text-start">When to Avoid Tables:</h4>
        <ul className="text-start">
          <li>Don't use tables for website layout. There are better ways to achieve this using CSS (Cascading Style Sheets).</li>
        </ul>
        <br/>
        <br/>



        <div className='text-start p-4 bg-dark  ' style={{color:'#fff'}}>
          <h6>{`<!DOCTYPE html>`}</h6>
               
            <p>{`<html>`}</p>
            <p className='mx-3'>{`<body>`}</p>
            <p className='mx-5'>{`<table>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<th>Company</th>`}</p>
            <p className='mx-5 px-5'>{`<th>Contact</th>`}</p>
            <p className='mx-5 px-5'>{`<th>Country</th>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Alfreds Futterkiste</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Maria Anders</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Germany</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Centro comercial Moctezuma</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Francisco Chang</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Mexico</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Ernst Handel</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Roland Mendel</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Austria</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Island Trading</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Helen Bennett</td>`}</p>
            <p className='mx-5 px-5'>{`<td>UK</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Laughing Bacchus Winecellars</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Yoshi Tannamuri</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Canada</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5 px-3'>{`<tr>`}</p>
            <p className='mx-5 px-5'>{`<td>Magazzini Alimentari Riuniti</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Giovanni Rovelli</td>`}</p>
            <p className='mx-5 px-5'>{`<td>Italy</td>`}</p>
            <p className='mx-5 px-3'>{`</tr>`}</p>
            <p className='mx-5'>{`</table>`}</p>
            <p className='mx-3'>{`</body>`}</p>
            <p>{`</html>`}</p>
          
        </div>

        <table>

          <tr className='text-start'>
    
            <th>Company</th>
              
            <th>Contact</th>
              
            <th>Country</th>
    
          </tr>
    
          <tr>
    
            <td>Alfreds Futterkiste</td>

            <td>Maria Anders</td>

            <td>Germany</td>
    
          </tr>
    
          <tr>
    
            <td>Centro comercial Moctezuma</td>

            <td>Francisco Chang</td>

            <td>Mexico</td>
    
          </tr>
    
          <tr>
    
            <td>Ernst Handel</td>

            <td>Roland Mendel</td>

            <td>Austria</td>
    
          </tr>
    
          <tr>
    
            <td>Island Trading</td>

            <td>Helen Bennett</td>

            <td>UK</td>
    
          </tr>
    
          <tr>
    
            <td>Laughing Bacchus Winecellars</td>

            <td>Yoshi Tannamuri</td>

            <td>Canada</td>
    
          </tr>
    
          <tr>
    
            <td>Magazzini Alimentari Riuniti</td>

            <td>Giovanni Rovelli</td>

            <td>Italy</td>
    
          </tr>

        </table>



        <div className="bg-dark p-4 text-start mt-4" style={{color:'#fff'}}>
          <h4>CSS</h4>
          <br />
          <br />
          <br />

          <p>table {`{`}</p>
          <p className='px-4'>font-family: arial, sans-serif;</p>
          <p className='px-4'>border-collapse: collapse;</p>
          <p className='px-4'>width: 100%;</p>
          <p className='px-4'>border-bottom: solid 1px #000;</p>
          <p>{`}`}</p>

          <p>td, th  {`{`}</p>
          <p className='px-4'>border: 1px solid #dddddd;</p>
          <p className='px-4'>text-align: left;</p>
          <p className='px-4'>padding: 8px;</p>
          <p>{`}`}</p>

          <p>tr:nth-child(even) {`{`}</p>
          <p className='px-4'>background-color: #dddddd;</p>
          <p>{`}`}</p>
        </div>

        




        

        <p className="text-start mt-4">I hope this explanation clarifies what tables are in HTML!</p>

        <br/>
        <br/>
        <Link className='px-3' to='/TutorialCss/lists' >Previous</Link>
        <Link className='px-3' to='/TutorialCss/HistoryandpurposeofCSS' >Next</Link>
    </div>
  )
}

export default Table;