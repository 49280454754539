import { Link } from "react-router-dom"
import { useEffect } from "react";
import './Grid.css';

function Grid() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Grid</h3>
        <br /><br /><br />
        <p>In CSS, a grid layout is a two-dimensional system that allows you to arrange elements on a page using rows and columns. It provides a more flexible and powerful way to structure your web pages compared to older methods like floats or tables.</p>
        <p className="mt-5">Here's a breakdown of the concept:</p>
        <ul>
            <li><b>Structure</b>: A grid is made up of horizontal and vertical lines that define rows and columns. These lines themselves are not visible on the page, but they create a framework for positioning elements.</li>
            <li><b>Elements</b>: The content you see on the page, like text, images, and buttons, become grid items. You can place these items on specific rows and columns within the grid.</li>
            <li><b>Responsiveness</b>: Grid layout is particularly useful for responsive web design because it allows elements to adapt and resize based on the screen size.</li>
        </ul>
        <p className="mt-5">Some advantages of using CSS Grid:</p>
        <ul>
            <li><b>Flexibility</b>: Grid offers more control over layout compared to other methods. You can easily create complex and responsive designs.</li>
            <li><b>Accuracy</b>: Grid makes it simpler to achieve precise alignment and spacing of elements on your page.</li>
            <li><b>Efficiency</b>: Grid can streamline your workflow by reducing the amount of code needed to achieve your desired layout.</li>
        </ul>


        <div class="grid-container mt-5">
          <div class="item1">Header</div>
          <div class="item2">Menu</div>
          <div class="item3">Main</div>  
          <div class="item4">Right</div>
          <div class="item5">Footer</div>
        </div>


        <div className="text-light p-3 bg-dark mt-5">
            <h6>HTML</h6>

            {/* <p>{`<div`} <span className="text-danger">class</span>=<span className="text-info">""</span>{`>`}</p> */}
            
            {/* <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">""</span>{`>`}  {`</div>`} </p>  */}

            <p>{`<div`} <span className="text-danger">class</span>=<span className="text-info">"grid-container"</span>{`>`}</p>
            <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">"item1"</span>{`>`} Header {`</div>`} </p> 
            <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">"item2"</span>{`>`}  Menu{`</div>`} </p>
            <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">"item3"</span>{`>`} Main {`</div>`} </p> 
            <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">"item4"</span>{`>`} Right {`</div>`} </p> 
            <p className="mx-4">{`<div`} <span className="text-danger ">class</span>=<span className="text-info">"item5"</span>{`>`} Footer {`</div>`} </p> 
            <p>{`}`}</p>

        </div>

        <div className="text-light p-3 bg-dark mt-5">
            <h6>CSS</h6>
            <p><span className="text-danger">.grid-container</span> {`{`}</p>
            {/* <p className="mx-4"><span className="text-info"></span>: <span className="text-warning"></span>;</p> */}

            <p className="mx-4"><span className="text-info">display</span>: <span className="text-warning">grid</span>;</p>
            <p className="mx-4"><span className="text-info">grid-template-areas</span>: <br />
                <span className="text-warning">
                <span className="mx-5">'header header header header header header'</span>    <br />
                <span className="mx-5">'menu main main main right right'</span><br />
                <span className="mx-5">'menu footer footer footer footer footer'</span>
            </span>;</p> 
            <p className="mx-4"><span className="text-info">gap</span>: <span className="text-warning">10px</span>;</p>
            <p className="mx-4"><span className="text-info">background-color</span>: <span className="text-warning">#0c0c0c</span>;</p> 
            <p className="mx-4"><span className="text-info">padding</span>: <span className="text-warning">10px</span>;</p> 
            <p>{`}`}</p>
            <br />
            <p><span className="text-danger">.grid-container {`>`} div</span> {`{`}</p>
            <p className="mx-4"><span className="text-info">background-color</span>: <span className="text-warning"> rgba(255, 255, 255, 0.8)</span>;</p>
            <p className="mx-4"><span className="text-info">text-align</span>: <span className="text-warning">center</span>;</p>
            <p className="mx-4"><span className="text-info">padding</span>: <span className="text-warning">20px 0</span>;</p>
            <p className="mx-4"><span className="text-info">font-size</span>: <span className="text-warning">30px</span>;</p>
            <p>{`}`}</p>
            <p className="mt-3"><span className="text-danger">.item1 </span> {`{`} <span className="text-info">grid-area</span> : <span>header</span>; {`}`}</p>
            <p className="mt-3"><span className="text-danger">.item2 </span> {`{`} <span className="text-info ">grid-area</span> : <span className="text-warning">menu</span>; {`}`}</p>
            <p className="mt-3"><span className="text-danger">.item3 </span> {`{`} <span className="text-info">grid-area</span> : <span>main</span>; {`}`}</p>
            <p className="mt-3"><span className="text-danger">.item4 </span> {`{`} <span className="text-info ">grid-area</span> : <span className="text-warning">right</span>; {`}`}</p>
            <p className="mt-3"><span className="text-danger">.item5 </span> {`{`} <span className="text-info">grid-area</span> : <span>footer</span>; {`}`}</p>


        </div>

        <p className="mt-3">In Grid CSS, the gap refers to the spacing between rows and columns in your grid layout. It's essentially a gutter that separates your grid items from each other.</p>

        <p>There are two main ways to control the gap:</p>

        <p><b>Using the gap property</b>: This is a shorthand property introduced in later CSS specifications. It allows you to set the size of both the row and column gaps simultaneously. You can specify the gap size using pixels (px), percentages (%), or other CSS length units.</p>


        <p><b>Using individual properties</b>:</p>
        <ul>
            <li><b><span className="light">grid-row-gap</span></b>: This property controls the spacing between grid rows.</li>
            <li className="mt-1"><b><span className="light">grid-column-gap</span></b>: This property controls the spacing between grid columns.</li>
        </ul>

        <p>Both approaches achieve the same outcome, but using gap is generally more concise.</p>

        <p>Here's a quick comparison:</p>


        <p><b>Using</b> <span className="light">gap</span>:</p>
        <div className="text-light p-3 bg-dark">
            <h6>CSS</h6>
            <br />
            <p><span className="text-danger">.grid-container</span>{`{`}</p>
            <p className="mx-4"><span className="text-info">display</span> : <span className="text-warning">grid</span>;</p>
            <p className="mx-4"><span className="text-info">gap</span><span className="text-warning">10px</span>; <span className="text-secondary">/* Gap between both rows and columns */</span></p>
            <p>{`}`}</p>
        </div>

        <p className="mt-3"><b>Using individual properties</b>:</p>
        <div className="text-light p-3 bg-dark ">
            <h6>CSS</h6>
            <br />
            <p><span className="text-danger">.grid-container</span>{`{`}</p>
            <p className="mx-4"><span className="text-info">display</span> : <span className="text-warning">grid</span>;</p>
            <p className="mx-4"><span className="text-info">grid-row-gap</span>: <span className="text-warning">10px</span>;</p>
            <p className="mx-4"><span className="text-info">grid-column-gap</span>: <span className="text-warning">10px</span>;</p>
            <p>{`}`}</p>
        </div>

        <p className="mt-5">By default, there's no gap between grid items in CSS Grid. So, if you don't set any gap properties, your items will be placed directly next to each other.</p>



        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/FlexBox'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Colors'>Next</Link>
        </div>
    </div>
  )
}

export default Grid