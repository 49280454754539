// RgbToHexConverter.jsx
import React, { useState } from 'react';
import Styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const Button = Styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #365486;
  color: #fff;
  &:hover {
    background-color: #789461;
  }
`
const ContainerColor = Styled.div`
  width: 300px;
  height: 400px;
  border: solid 1px #000;
  border-radius: 4px;
`
const Span = Styled.span`
padding: 0.2em 1em;
cursor: pointer;
border: solid 1px #000;
border-radius: 4px;

&:hover {
  background-color: #789461;
  color: #fff;
}
`
const RgbToHexConverter = () => {
  const [rgbaColor, setRgbaColor] = useState('');
  const [hexColor, setHexColor] = useState('');

  const controlHandler = (e) => {
    setRgbaColor(e.target.value);
  };

  const rgbaToHex = (rgba) => {
    // تفکیک مقادیر RGB از کد RGBA
  const rgbaValues = rgba.match(/\d+/g);
  const r = parseInt(rgbaValues[0]);
  const g = parseInt(rgbaValues[1]);
  const b = parseInt(rgbaValues[2]);

  // تبدیل مقادیر RGB به مقادیر HEX
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  // ادغام مقادیر HEX به یک رشته
  const hexColor = `#${rHex}${gHex}${bHex}`;

  return hexColor.toUpperCase(); // حروف را به صورت بزرگ تبدیل می‌کنیم
  };

  const sendHandler = () => {
    const hex = rgbaToHex(rgbaColor);
    setHexColor(hex);
  };

  return (
    // <div>
    //   <h2>RGBA to HEX Converter</h2>
    //   <input type="text" onChange={controlHandler} placeholder="Enter RGBA code" />
    //   <button onClick={sendHandler}>Convert</button>
    //   <div style={{ backgroundColor: rgbaColor }}>Converted Color</div>
    //   <span>{hexColor}</span>
    // </div>
    <div className='container m-auto d-flex flex-column'>
      <h1 className='p-5'>Hex color to Rgba</h1>
      <div className='w-50 d-flex justify-content-between m-auto  '>
        <input
          className='w-75'
          type="text"
          onChange={controlHandler}
          placeholder="Enter Rgba code "
        />
        <Button onClick={sendHandler} type='button'>
          Convert
        </Button>
      </div>
      <Row className='m-auto mt-3'>
        <Col lg={6}>
          <p>Rgb code</p>
          <ContainerColor style={{ backgroundColor: rgbaColor }}></ContainerColor>
          <p className='mt-2'>{rgbaColor}</p><Span onClick={() => {navigator.clipboard.writeText(rgbaColor)}}>copy</Span>
        </Col>
        <Col lg={6}>
          <p>Hex code</p>
          <ContainerColor style={{ backgroundColor: hexColor }}></ContainerColor>
          <p className='mt-2'>{hexColor}</p><Span onClick={() => {navigator.clipboard.writeText(hexColor)}}>copy</Span>
        </Col>
      </Row>
    </div>
  );
};

export default RgbToHexConverter;
