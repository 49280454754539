import {useState} from 'react';
import { Link } from "react-router-dom"
import { useEffect } from "react";

function PseudoSelectors() {
    const [isHovered, setIsHovered] = useState(false); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
   
    const colors = {
        color: isHovered ? 'red' : 'blue',
    };

  return (
    <div className='p-3'>

        <h3>Pseudo selectors</h3>
        <br/>
        <br/>
        <p className="text-start">In CSS, pseudo-selectors are special keywords that extend the functionality of regular selectors. They come in two flavors: pseudo-classes and pseudo-elements.</p>
        <p className="text-start"><b>Pseudo-classes</b> target specific states of an element. For instance, you can style a button differently when the user hovers over it with the <span className='light'>:hover</span> pseudo-class.</p>
        <p className="text-start mt3">Some common pseudo-classes include:</p>
        <ul className="text-start">
            <li className='mt-1'><span className="light">:hover</span>Applies styles when the user hovers over an element with the mouse.</li>
            <li className='mt-1'><span className="light">:link</span>Applies styles to unvisited links.</li>
            <li className='mt-1'><span className="light">:visited</span>Applies styles to visited links.</li>
            <li className='mt-1'><span className="light">:focus</span>Applies styles to the element that currently has user focus.</li>
            <li className='mt-1'><span className="light">:active</span>Applies styles to the element when it's activated (e.g., a button being clicked).</li>
        </ul>

        <p className='text-start'>Let's say we want to have blue text that changes to red when the user hovers over it. For this, we can use CSS and the <span className="light">:hover</span> pseudo-class.</p>

        <div className="mt-3 text-light p-3 text-start bg-dark">
            <h4>HTML</h4>
            <br/>
            <br/>
            <p>{`<p>The desired text</p>`}</p>
        </div>
        <div className="mt-3 text-light p-3 text-start bg-dark">
            <h4>CSS</h4>
            <br/>
            <br/>
            <p className=''>{`p {`}</p>
            <p className='mx-3'>{`color: blue;`}<span className='text-secondary'>/* Default text color */</span></p>
            <p >{`}`}</p>
            <p className='mt-2'>{`p:hover {`}</p>
            <p className='mx-3'>{` color: red;`} <span className='text-secondary'>/* Text color in hover mode */</span></p>
            <p>{`}`}</p>
        </div>
        <p className='text-start mt-3'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        style={colors}>The desired text</p>
        <p className="mt-4 text-start">In this example, we first set the default text color using color: blue inside the p selector. Next, we use the :hover pseudoclass after the p selector to change the text color to red on hover.</p>
        <p className="mt-4 text-start">Hints:</p>
        <ul className='text-start mt-3'>
            <li>You can apply any styling you want inside the :hover pseudoclass, such as changing the background color, font size, or adding shadow effects.</li>
            <li>The <span className="light">:hover</span> pseudoclass can be used not only for text, but for any element in HTML, such as buttons, images, and links.</li>
            <li>You can use multiple pseudoclasses together to apply different effects in different situations.</li>
        </ul>
        <p className="mt-4 text-start">Using creativity and CSS, you can use the :hover pseudo-class to create attractive and user-friendly interactions on your website.</p>
        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/AttributeSelectors'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/FontSize'>Next</Link>
    </div>
  )
}

export default PseudoSelectors