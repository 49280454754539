import {Link} from 'react-router-dom';
import { useEffect } from "react";

function Lists() {

  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  },[])

  return (
    <div className='px-3'>
      <h3>lists</h3>
      <br/>
      <br/>
      <br/>
      <p className='text-start'>Lists are a way to display items in HTML  in a bulleted or numbered format. There are two main types of lists:</p>
      <ul className='mt-2 text-start'>
        <li><b>Unordered lists</b>: These are used for items where the order doesn't necessarily matter. They are created with the {`<ul>`} tag, and each list item is defined with the {`<li>`} tag. By default, unordered list items are displayed with bullets.</li>
        <li><b>Ordered lists</b>: These are used for items where the order is important.  They are created with the {`<ol>`} tag, and each list item is defined with the {`<li>`} tag  just like unordered lists. By default, ordered list items are displayed with numbers (consecutively by default).</li>
      </ul>

      <p className="mt-4">Here's a basic example of an unordered list</p>
      <div className="bg-dark p-2 text-start" style={{color:'#fff'}}>
        <h4>HTML</h4>
        <br/>
        <br/>
        <br/>
        <p className='px-3'>{`<ul>`}</p>
        <p className='px-5'>{`<li>Coffee</li>`}</p>
        <p className='px-5'>{`<li>Tea</li>`}</p>
        <p className='px-5'>{`<li>Milk</li>`}</p>
        <p className='px-3'>{`<ul>`}</p>
      </div>
      <p className="text-start mt-3">This code would produce a bulleted list with three items: "Coffee", "Tea", and "Milk".</p>
      <p className="text-start mt-3">Its output is as follows:</p>
      <ul className='text-start'>
        <li>Coffee</li>
        <li>Tea</li>
        <li>Milk</li>
      </ul>
      <p className="text-start mt-3">And it can be displayed with other modes!</p>

      <p className='text-start'>For this purpose you can use style ( list-style-type ) which has features like armenia, circle, decimal, decimal-leading-zero, disc, georgian, lower-alpha, lower-greek, lower-latin, lower-roman and even None</p>

      <br/>
      <br/>
      <br/>

      <p className=''>Here is a basic example of an ordered list</p>
      <div className="bg-dark p-2 text-start" style={{color:'#fff'}}>
        <h4>HTML</h4>
        <br/>
        <br/>
        <br/>
        <p className='px-3'>{`<ol>`}</p>
        <p className='px-5'>{`<li>Paris</li>`}</p>
        <p className='px-5'>{`<li>Tehran</li>`}</p>
        <p className='px-5'>{`<li>Karaj</li>`}</p>
        <p className='px-5'>{`<li>Milan</li>`}</p>
        <p className='px-3'>{`<ol>`}</p>
      </div>
      <p className="text-start mt-3">In this method, the lists are numbered in order</p>

      <ol className='text-start'>
        <li>Paris</li>
        <li>Tehran</li>
        <li>Karaj</li>
        <li>Milan</li>
      </ol>


      <br/>
      <br/>
      <br/>
      <Link className='px-3' to='/TutorialCss/Paragraphs' >Previous</Link>
      <Link className='px-3' to='/TutorialCss/Table' >Next</Link>
    </div>
  )
}

export default Lists