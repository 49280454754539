import { Link } from "react-router-dom";
import { useEffect } from "react";

function LogicalOperators() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>

        <h5>Logical operators</h5>

        <br /><br />

        <p>Logical operators are special symbols in JavaScript that are used to combine multiple conditions together. They are essential for making decisions and controlling the flow of your program based on different scenarios.</p>

        <p className="mt-3">Here are the common logical operators in JavaScript:</p>

        <ul>
            <li className="mt-3"><b>AND (&&):</b> This operator returns <span className="light">true</span> only if both operands (conditions) being evaluated are <span className="light">true</span>. If even one operand is <span className="light">false</span>, the entire expression evaluates to <span className="light">false</span>.</li>
            <li className="mt-3"><b>OR (||):</b> This operator returns <span className="light">true</span> if at least one of the operands being evaluated is <span className="light">true</span>. It only returns <span className="light">false</span> if both operands are <span className="light">false</span>.</li>
            <li className="mt-3"><b>NOT (!):</b> This operator flips the logical state of its operand. If the operand is <span className="light">true</span>, it becomes <span className="light">false</span>, and vice versa.</li>
        </ul>

        <p className="mt-3">These operators are frequently used in conditional statements like if, else if, and else to control the program flow based on certain conditions. They can also be used with boolean expressions or any value that can be coerced into a boolean value.</p>

        <p className="mt-4">Here are some examples of how logical operators are used in JavaScript:</p>

        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">let</span> isLoggedIn = <span className="text-danger">true</span></p>
            <p><span className="text-warning">let</span> hasPermission = <span className="text-danger">false</span></p>
            <p className="mt-2"><span className="text-warning">if</span>( isLoggedIn <span className="text-info">&&</span> hasPermission ) {`{`}</p>
            <p className="mx-4"> console.log(<span className="text-success">"You can access this resource."</span>);</p>
            <p>{`}`} <span className="text-warning">else</span> {`{`}</p>
            <p className="mx-4"> console.log(<span className="text-success">"You are not authorized."</span>);</p>
            <p>{`}`}</p>
            <br />
            <p><span className="text-warning">let</span> isRaining  = <span className="text-danger">true</span></p>
            <p><span className="text-warning">let</span> isNightTime  = <span className="text-danger">false</span></p>

            <p className="mt-2"><span className="text-warning">if</span>( isRaining  <span className="text-info">||</span> isNightTime ) {`{`}</p>
            <p className="mx-4"> console.log(<span className="text-success">"Bring an umbrella."</span>);</p>
            <p>{`}`} <span className="text-warning">else</span> {`{`}</p>
            <p className="mx-4"> console.log(<span className="text-success">"No need for an umbrella."</span>);</p>
            <p>{`}`}</p>
            <br />
            <p><span className="text-warning">let</span> isAvailable  = <span className="text-danger">null</span></p>
            <p><span className="text-warning">let</span> message = isAvailable <span className="text-info">?? </span> <span className="text-success">"Product is not available"</span>;</p>
            <p className=""> console.log(message); <span className="text-secondary">// Output: "Product is not available" (nullish coalescing operator)</span></p>

        </div>










        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Comparison' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/ForLoop' >Next</Link>
        </div>

    </div>
  )
}

export default LogicalOperators