import React, { useState, useEffect } from 'react';
import './Header.css';
import Animation from '../../Animation/AnmationSpline';

const Header = () => {
  
  
  const [animationClicked, setAnimationClicked] = useState(false);

  const handleClick = () => {
    setAnimationClicked(true);
  };

 
  return (
    <div className='Header' >
      <div className='backHeader' >
        <Animation onClick={animationClicked} className='w-100'/>
      </div>
      <div className='frontHeader text-light' onClick={handleClick}>
    
        <div>
          <h1>Develop your creativity</h1>
          <h3>Fast and easy design with online tools</h3>
        </div>
        <div className='ParagraphHeader'>
          <p>Create the styles you want for your website quickly and easily with our online tools.</p>
          <p>Ready-made components for React.js and HTML/CSS: Save time and finish your projects faster.</p>
        </div>
      </div>
    </div>
  )
}

export default Header;
