import { Link } from "react-router-dom";
import { useEffect } from "react";

function Variables() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
  return (
    <div className='text-start p-3'>
        <h5>Variables</h5>
        <br /><br /><br />

        <p>In JavaScript, variables act like named storage containers. You can use them to hold information that you want to use throughout your code. This information can be anything from numbers and text to more complex data structures like lists or objects.</p>



        <p className="mt-3">Here's a breakdown of the key points about variables in JavaScript:</p>

        <ul className="mt-3">
            <li><b>Creating Variables (Declaration):</b> You use keywords like let, const, or var (although var is generally discouraged due to scoping issues) to declare a variable. This creates a space in memory for the variable to hold data.</li>
            <li><b>Assigning Values:</b> After you declare a variable, you can assign a value to it using the equals sign (=). The value can be a number (e.g., 10), a piece of text (enclosed in quotes, like "Hello"), or even another variable.</li>
            <li><b>Using Variables:</b> Once you have a variable with a value, you can use its name throughout your code to refer to that value. This makes your code more readable and easier to maintain.</li>
        </ul>


        <br /><br />
        <p>For example:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-danger">let</span> name = <span className="text-info">"Younes"</span></p>
            <p><span className="text-danger">let</span> age = <span className="text-info">39;</span></p>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Hello, my name is "</span> + name +  <span className="text-info">" and I am "</span> + age + <span className="text-info">" years old."</span>)</p>
        </div>


        <br />
        <p>In this example, we declare two variables: name and age. We then assign values to them. Finally, we use the variables within a string to create a message.</p>





        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/JavaScriptCodeInHTML' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/String' >Next</Link>
        </div>

    </div>
  )
}

export default Variables