import {Link} from 'react-router-dom';
import { useEffect } from "react";

function Paragraphs() {
  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className='px-3'>
        <h3>Paragraphs</h3>

        <br />
        <br />
        <br />
        <p className='text-start'>In HTML, a paragraph is defined using the {`<p>`} tag. This tag marks the beginning and end of a block of text that represents a paragraph.</p>
        <p className='text-start'>Here's a breakdown of how it works:</p>
        <ul className='text-start'>
            <li><b>Definition</b>: The {`<p>`} tag indicates the start of a new paragraph.</li>
            <li><b>Structure</b>: Anything written between the opening {`<p>`} tag and the closing {`</p>`} tag is considered part of the paragraph.</li>
            <li><b>Formatting</b>: Browsers automatically format paragraphs as a block of text, typically with some space before and after the paragraph for readability.</li>
        </ul>
        <p className='text-start'>For instance, this code:</p>

        <div className='text-start p-4 bg-dark  ' style={{color:'#fff'}}>
            <h3>HTML</h3>
            <br />
            <br />
            <p>{`<p>This is an example of a paragraph in HTML.</p>`}</p>
              
        </div>

        <p className='text-start mt-5'>Would display the following text on a webpage:</p>
        <p className='text-start'>This is an example of a paragraph in HTML.</p>
        <p className='text-start'>While the {`<p>`} tag defines the structure of a paragraph, you can use Cascading Style Sheets (CSS) to style the appearance of paragraphs, such as font size, color, or alignment.</p>
        <p className='text-start'>Overall, the {`<p>`} tag provides a basic way to structure your text content into readable paragraphs on a webpage. You can further enhance the look and feel of your paragraphs using Cascading Style Sheets (CSS).</p>

        <br />
        <br />
        <Link className='px-3' to='/TutorialCss/CreatingHeadings'>Previous</Link>
        <Link className='px-3' to='/TutorialCss/lists'>Next</Link>
    </div>
  )
}

export default Paragraphs