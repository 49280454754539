import React from 'react';

function RadioButtonGroup(props) {
  return (
    <div className='text-start text-light'>
      <label >
        <input type="radio" value="linear-gradient" checked={props.selectedOption === 'linear-gradient'} onChange={props.handleChange}  />
        linear-gradient
      </label>
      <br />
      <label >
        <input type="radio" value="circle" checked={props.selectedOption === 'circle'} onChange={props.handleChange} />
        Radial-gradient
      </label>
      <br />
    </div>
  );
}

export default RadioButtonGroup;
