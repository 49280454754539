import { useState, useEffect } from 'react';
import {Row, Col} from 'react-bootstrap';
import Styled from 'styled-components';
import './Column.css';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';
const Settings = Styled.div`
  margin-top: 15px;
  padding-top: 5px;
  border-top: solid 1px #ccc;
  display: flex;
  flex-direction: column;
  juustify-content: center;
  align-items: center;
  width: 25%;
`
const ButtonOpenModal = Styled.p`
  color: #ccc;
  background-color: #102949;

  &:hover {
    background-color: #106010;
    color: #fff;
  }
`

const Modal = Styled.div`
width: 80%;
height: 80vh;
paddin: 5px;
position: absolute;
top: 10%;
left: 10%;
object-fit: cover;
background-color: #0f0f0f;
display: flex;
flex-direction: column;
justify-content: center;
transation: 1s linear forwards;

div {
  width: 50%;
  height: 100%;
}
`

const ColumnText = () => {
  const [ isVisible, setIsVisible ] = useState('hidden');
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0); 
  },[])
 

  // the hooks are for all setting Paragraph
  const [ isPadding, setIsPadding ] = useState('5');
  const [ isColumnCount, setIsColumnCount ] = useState('3');
  const [ ColumnRow, setColumnRow ] = useState('4');
  const [ isColumnGap, setIsColumnGap ] = useState('40');
  const [ isColumnRule, setIsColumnRule ] = useState('3');
  const [ isColorRule, setIsColorRule ] = useState('#000000');
  const [ colorFirstLetter, setColorFirstLetter ] = useState('red');
  const [ textMode, setTextMode ] = useState('uppercase');
  const [ letterHeight , setLetterHeight ] = useState(3);
  const [ textAlign, setTextAlign ] = useState('justify');
  const [ paragraphDirection , setParagraphDirection] = useState('ltr');
  const [ isOpacity, setIsOpacity ] = useState('0')

  const ParagraphStyle = Styled.p`
  direction: ${paragraphDirection};
  padding: ${isPadding}px;
  column-count: ${isColumnCount};
  -webkit-column-count: ${isColumnCount};
  -moz-column-count: ${isColumnCount};
  column-gap: ${isColumnGap}px;
  -webkit-column-gap: ${isColumnGap}px;
  -moz-column-gap: ${isColumnGap}px;
  column-rule: ${isColumnRule}px solid ${isColorRule};
  -webkit-column-rule: ${isColumnRule}px solid ${isColorRule};
  -moz-column-rule: ${isColumnRule}px solid ${isColorRule};
  text-align: ${textAlign};
  
  &::first-letter {
    color: ${colorFirstLetter};
    text-transform: ${textMode};
    initial-letter: ${letterHeight};
  }
  `
  const openModal = () => {
    setIsVisible('visible');
    setIsOpacity('100')
  }
  const CloseModal = () => {
    setIsVisible('hidden')
  }

  // Handler copy style SASS
  const SassCopy = () => {
    navigator.clipboard.writeText(`
                      // SASS
                        .paragraph: {
                          direction: ${paragraphDirection};
                          padding: ${isPadding}px;
                          column-count: ${isColumnCount};
                          -webkit-column-count: ${isColumnCount};
                          -moz-column-count: ${isColumnCount};
                          column-gap: ${isColumnGap};
                          -webkit-column-gap: ${isColumnGap};
                          -moz-column-gap: ${isColumnGap};
                          column-rule: ${isColumnRule}px solid ${isColorRule};
                          -webkit-column-rule: ${isColumnRule}px solid ${isColorRule};
                          -moz-column-rule: ${isColumnRule}px solid ${isColorRule};
                          text-align: ${textAlign};

                          ::first-letter {
                            color: ${colorFirstLetter};
                            text-transform: ${textMode};
                            initial-letter: ${letterHeight};
                        }
                      `)
                      .then(() => Swal.fire({
                        position: "bottom-start",
                        icon: "success",
                        title: "The style was copied",
                        showConfirmButton: false,
                        timer: 1500
                      }))
                      .catch(err => console.error('Could not copy: ', err));
  }
  // Handler copy style CSS
  const CssCopy = () => {
    navigator.clipboard.writeText(`
                          // CSS
                            .paragraph : {
                              direction: ${paragraphDirection};
                              padding: ${isPadding}px;
                              column-count: ${isColumnCount};
                              -webkit-column-count: ${isColumnCount};
                              -moz-column-count: ${isColumnCount};
                              column-gap: ${isColumnGap};
                              -webkit-column-gap: ${isColumnGap};
                              -moz-column-gap: ${isColumnGap};
                              column-rule: ${isColumnRule}px solid ${isColorRule};
                              -webkit-column-rule: ${isColumnRule}px solid ${isColorRule};
                              -moz-column-rule: ${isColumnRule}px solid ${isColorRule};
                              text-align: ${textAlign};
                            }
                            .paragraph::first-letter: {
                              color: ${colorFirstLetter};
                              text-transform: ${textMode};
                              initial-letter: ${letterHeight};
                            }
                          `)
                          .then(() => Swal.fire({
                            position: "bottom-start",
                            icon: "success",
                            title: "The style was copied",
                            showConfirmButton: false,
                            timer: 1500
                          }))
                          .catch(err => console.error('Could not copy: ', err));
  }
  // Handler copy style SCSS
  const ScssCopy = () => {
    navigator.clipboard.writeText(`
                      // SCSS
                        .paragraph: {
                          direction: ${paragraphDirection};
                          padding: ${isPadding}px;
                          column-count: ${isColumnCount};
                          -webkit-column-count: ${isColumnCount};
                          -moz-column-count: ${isColumnCount};
                          column-gap: ${isColumnGap};
                          -webkit-column-gap: ${isColumnGap};
                          -moz-column-gap: ${isColumnGap};
                          column-rule: ${isColumnRule}px solid ${isColorRule};
                          -webkit-column-rule: ${isColumnRule}px solid ${isColorRule};
                          -moz-column-rule: ${isColumnRule}px solid ${isColorRule};
                          text-align: ${textAlign};

                          &::first-letter {
                            color: ${colorFirstLetter};
                            text-transform: ${textMode};
                            initial-letter: ${letterHeight};
                        }
                      `)
                      .then(() => Swal.fire({
                        position: "bottom-start",
                        icon: "success",
                        title: "The style was copied",
                        showConfirmButton: false,
                        timer: 1500
                      }))
                      .catch(err => console.error('Could not copy: ', err));
  }
  return (
    <div className='ColumnText-container pt-4 position-relative'>
      <Helmet>
      
     <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        <title>Columnar text</title>
        <meta name="description" content="A component for styling paragraphs and displaying them in separate columns like a newspaper." />
        <meta name="keywords" content="columnar text, newspaper style, paragraph styling, component, web" />
        {/* persian */}
        <meta name="description" content="مؤلفه ای برای استایل دادن به پاراگراف ها و نمایش آنها در ستون های جداگانه مانند روزنامه." />
        <meta name="keywords" content="متن ستونی، سبک روزنامه، سبک پاراگراف، مؤلفه، وب" />
        {/* italian */}
        <meta name="description" content="Un componente per definire lo stile dei paragrafi e visualizzarli in colonne separate come un giornale." />
        <meta name="keywords" content="testo in colonne, stile giornale, stile paragrafo, componente, web" />
        {/* Chainese */}
        <meta name="description" content="用于设置段落样式并像报纸一样在单独的列中显示它们的组件。" />
        <meta name="keywords" content="柱状文本、报纸样式、段落样式、组件、网页" />
        {/* Korean */}
        <meta name="description" content="단락 스타일을 지정하고 신문처럼 별도의 열에 표시하기 위한 구성 요소입니다." />
        <meta name="keywords" content="칼럼 형식의 텍스트, 신문 스타일, 단락 스타일, 구성 요소, 웹" />
        {/* Arabic */}
        <meta name="description" content="مكون لتصميم الفقرات وعرضها في أعمدة منفصلة مثل الصحيفة." />
        <meta name="keywords" content="النص العمودي، نمط الصحيفة، تصميم الفقرة، المكون، الويب" />
        {/* Spanish */}
        <meta name="description" content="Un componente para diseñar párrafos y mostrarlos en columnas separadas como un periódico." />
        <meta name="keywords" content="texto en columnas, estilo de periódico, estilo de párrafo, componente, web" />
        {/* German */}
        <meta name="description" content="Eine Komponente zum Gestalten von Absätzen und deren Anzeige in separaten Spalten wie bei einer Zeitung." />
        <meta name="keywords" content="Spaltentext, Zeitungsstil, Absatzstil, Komponente, Web" />
        {/* Japon */}
        <meta name="description" content="段落をスタイル設定し、新聞のように別の列に表示するためのコンポーネント。" />
        <meta name="keywords" content="柱状テキスト、新聞スタイル、段落スタイル、コンポーネント、Web" />
        {/* Turkish */}
        <meta name="description" content="Paragrafları biçimlendirmek ve bunları gazete gibi ayrı sütunlarda görüntülemek için kullanılan bir bileşen." />
        <meta name="keywords" content="sütunlu metin, gazete stili, paragraf stili, bileşen, web" />
        {/* Armani */}
        <meta name="description" content="Բաղադրիչ՝ պարբերությունները ոճավորելու և թերթի նման առանձին սյունակներում ցուցադրելու համար:" />
        <meta name="keywords" content="սյունակային տեքստ, թերթի ոճ, պարբերության ոճավորում, բաղադրիչ, վեբ" />

       
      </Helmet>
        <Row className='pt-5'>
            <Col lg={2} className='position-relative d-flex justify-content-center' >
            <div className='Advertising  mt-5 pt-5 px-5 '>
              <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-1631607859297946"
                data-ad-slot="2957199157"
                data-auto-format="mcrspv"
                data-full-width="">
                <div overflow=""></div>
              </amp-ad>
            </div>
            </Col>
            {/* --------------- */}
            <Col lg={8} className='border'>
             <h3 className='pt-2'>Columnar text</h3>

             <div className='d-flex flex-column pt-2'>
                <div className='Display-view'>
                  <ParagraphStyle>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto inventore aliquam maiores vel distinctio eveniet et molestiae. Eius eaque consequuntur unde sequi consequatur soluta laboriosam reprehenderit! Quidem minima rem sit.
                  Aperiam, velit id! Aut ad iure delectus quaerat, rem nobis iste dicta doloremque, dolorum nihil sed sint expedita vero sapiente sequi illum id voluptatum architecto aspernatur. Quia, excepturi temporibus. Nisi?
                  Laboriosam libero blanditiis ipsa, facilis dolores facere explicabo ut nulla exercitationem. Cumque pariatur dolorem fuga repellat quae tempora omnis cum consequuntur exercitationem excepturi, voluptatum doloribus tempore iusto maiores dolores! Iure?
                  Placeat, obcaecati voluptate. Recusandae, obcaecati fuga quod distinctio aliquam nam! Eveniet, ipsam nisi itaque odio iure beatae, recusandae repudiandae reprehenderit tenetur ea exercitationem labore enim libero vel facilis harum obcaecati.
                  Iste cumque, et aperiam, blanditiis corporis, accusamus tempore odit quas rerum amet cum ad. Amet eum, delectus enim facere magnam, est sit, aspernatur eligendi illum deserunt ad neque dolores placeat?
                  Vero facilis eum similique tenetur repellendus nemo fugit laudantium recusandae porro mollitia sint, blanditiis quae omnis laborum! Veritatis, repudiandae. In error officiis temporibus blanditiis perspiciatis sapiente voluptatibus corporis. Libero, consequatur.
                  Molestiae quam dignissimos placeat quod, nemo maiores possimus vel, laborum perspiciatis voluptates fuga commodi quia delectus eligendi, tempore facere sunt omnis animi culpa sapiente quae corrupti aperiam quaerat. Cumque, corrupti.
                  Quaerat hic corrupti modi neque. Dicta, adipisci ut minima deleniti libero accusantium aperiam dignissimos, neque culpa iusto perspiciatis vel a saepe, reiciendis omnis doloribus ipsum numquam accusamus quidem. A, quidem!  </ParagraphStyle>
                  <ButtonOpenModal className='btn border' onClick={() => openModal()}>Copy style</ButtonOpenModal>
                </div>
                <div className='d-flex flex-wrap'>
                  <Settings>
                    <span className='py-2'>Padding</span>
                    <input type="text" value={isPadding}  onChange={(e) => setIsPadding(e.target.value)}/>
                  </Settings>
                  <Settings>
                    <span className='py-2'>Column count</span>
                    <input type="text" value={isColumnCount}  onChange={(e) => setIsColumnCount(e.target.value)}  />
                  </Settings>
                  <Settings>
                    <span className='py-2'>gap</span>
                    <input type="text" value={isColumnGap}  onChange={(e) => setIsColumnGap(e.target.value)}  />
                  </Settings>
                  <Settings>
                    <span className='py-2'>rule</span>
                    <input type="text" value={isColumnRule}  onChange={(e) => setIsColumnRule(e.target.value)}  />
                  </Settings>
                  <Settings>
                    <span className='py-2'>Column rule</span>
                    <input type="text" value={ColumnRow}  onChange={(e) => setColumnRow(e.target.value)} />
                  </Settings>
                  <Settings>
                    <span className='py-2'>Text align</span>
                    <select  id=""  onChange={(e) => setTextAlign(e.target.value)}>
                      <option value="justify" selected>Justify</option>
                      <option value="center">Center</option>
                      <option value="start">Start</option>
                      <option value="end">End</option>
                    </select>
                  </Settings>
                  <Settings>
                    <span className='py-2'>Paragraph direction</span>
                    <select  id=""  onChange={(e) => setParagraphDirection(e.target.value)}>
                      <option value="Ltr" selected>Ltr</option>
                      <option value="Rtl">Rtl</option>
                    </select>
                  </Settings>
                  <Settings>
                    <span className='py-2'>Color rule</span>
                    <input type="color" value={ColumnRow}  onChange={(e) => setIsColorRule(e.target.value)} name='ColumnRow' />
                  </Settings>
                  
                </div>
                <div className=' my-1 pt-1'>
                  <p>Setting the first letter</p>
                  <div className='d-flex flex-wrap'>
                    <Settings>
                      <span className='py-2'>Letter height</span>
                      <input type="text" value={letterHeight}  onChange={(e) => setLetterHeight(e.target.value)} />
                    </Settings>
                    
                    <Settings>
                      <span className='py-2'>Text mode</span> 
                      <select   onChange={(e) => setTextMode(e.target.value)}>
                        <option value='lowercase' id="">lowercase</option>
                        <option value='uppercase' id="" selected>uppercase</option>
                      </select>
                    </Settings>
                    <Settings>
                      <span className='py-2'>Color first letter</span>
                      <input type="color" value={colorFirstLetter}  onChange={(e) => setColorFirstLetter(e.target.value)} />
                    </Settings>
                  </div>

                </div>
             </div>
             <Modal style={{visibility:`${isVisible}`, opacity:`${isOpacity}`}}>
                <div className='w-100 text-light d-flex justify-content-center align-items-center' style={{height:'50px'}}>
               
              <ButtonOpenModal  className='btn border mt-3' onClick={() => CloseModal()}>Close</ButtonOpenModal></div>
              <div className='w-100 p-1 d-flex justify-content-center align-items-center  gap-2'>
                {/* SASS */}
                <div className='border p-2'>
                  <p className='text-light'>SASS</p>
                  <div className='w-100 text-start text-warning px-5'>
                    .paragraph: &#10100;
                        <code className='text-start text-light'>
                        <br />
                          direction: {`${paragraphDirection}`};<br />
                          padding: {`${isPadding}`}px;<br />
                          column-count: {`${isColumnCount}`};<br />
                          -webkit-column-count: {`${isColumnCount}`};<br />
                          -moz-column-count: {`${isColumnCount}`};<br />
                          column-gap: {`${isColumnGap}px`};<br />
                          -webkit-column-gap: {`${isColumnGap}px`};<br />
                          -moz-column-gap: {`${isColumnGap}px`};<br />
                          column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -webkit-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -moz-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          text-align: {`${textAlign}`};
                          <br />
                          <br />
                          ::first-letter: <span className='text-warning'>&#10100;</span>
                          <br />
                              color: {`${colorFirstLetter}`};
                            <br />
                            text-transform: {`${textMode}`};
                            <br />
                            initial-letter: {`${letterHeight}`};  
                        </code>  
                        <br />
                        &#10101;   &#10101;   
                        <br />
                    <span className='btn border text-light m-auto' style={{width:'150px', margin:'auto'}}
                      onClick={() => SassCopy()}
                    >copy style</span>
                  </div>
                </div>
                {/* CSS */}
                <div className='border p-2'>
                  <p className='text-light'>CSS</p>
                  <div className='w-100 text-start px-5 text-warning'>
                    .paragraph: &#10100;
                    
                        <code className='text-start text-light'>
                          <br />
                          direction: {`${paragraphDirection}`};<br />
                          padding: {`${isPadding}`}px;<br />
                          column-count: {`${isColumnCount}`};<br />
                          -webkit-column-count: {`${isColumnCount}`};<br />
                          -moz-column-count: {`${isColumnCount}`};<br />
                          column-gap: {`${isColumnGap}px`};<br />
                          -webkit-column-gap: {`${isColumnGap}px`};<br />
                          -moz-column-gap: {`${isColumnGap}px`};<br />
                          column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -webkit-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -moz-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          text-align: {`${textAlign}`};
                        </code>
                        <br />
                        &#10101; 
                        <br />
                        .paragraph::first-letter: <span className='text-warning'>&#10100;</span> <br />
                        <code className='text-light'>
                        color: {`${colorFirstLetter}`};
                        <br />
                        text-transform: {`${textMode}`};
                        <br />
                        initial-letter: {`${letterHeight}`};  
                        </code>  
                        <br />
                        &#10101;   
                        <br />
                        <span className='btn border text-light m-auto text-start' style={{width:'150px', margin:'auto'}}
                          onClick={() => CssCopy()}
                        >copy style</span>   
                  </div>
                </div>
                {/* SCSS */}
                <div className='border p-2'>
                  <p className='text-light'>SCSS</p>
                  <div className='w-100 text-start text-warning px-5'>
                    .paragraph: &#10100;
                        <code className='text-start text-light'>
                        <br />
                          direction: {`${paragraphDirection}`};<br />
                          padding: {`${isPadding}`}px;<br />
                          column-count: {`${isColumnCount}`};<br />
                          -webkit-column-count: {`${isColumnCount}`};<br />
                          -moz-column-count: {`${isColumnCount}`};<br />
                          column-gap: {`${isColumnGap}px`};<br />
                          -webkit-column-gap: {`${isColumnGap}px`};<br />
                          -moz-column-gap: {`${isColumnGap}px`};<br />
                          column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -webkit-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          -moz-column-rule: {`${isColumnRule}`}px solid {`${isColorRule}`};<br />
                          text-align: {`${textAlign}`};
                          <br />
                          <br />
                          &::first-letter: <span className='text-warning'>&#10100;</span>
                          <br />
                              color: {`${colorFirstLetter}`};
                            <br />
                            text-transform: {`${textMode}`};
                            <br />
                            initial-letter: {`${letterHeight}`};  
                        </code>  
                        <br />
                        &#10101;   &#10101;   
                        <br />
                    <span className='btn border text-light m-auto' style={{width:'150px', margin:'auto'}}
                      onClick={() => ScssCopy()}
                    >copy style</span>
                  </div>
                </div>
              </div>
             </Modal>
            </Col>
            {/* --------------- */}
            <Col lg={2} className='    pt-5 d-flex flex-wrap justify-content-center   '>
              <div className='  position-fixed  '>
                <amp-ad width="100vw" height="320"
                  type="adsense"
                  data-ad-client="ca-pub-1631607859297946"
                  data-ad-slot="2957199157"
                  data-auto-format="mcrspv"
                  data-full-width="">
                  <div overflow=""></div>
                </amp-ad>
              </div>
            </Col>
        </Row>

        

    </div>
  )
}

export default ColumnText