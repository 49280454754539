import { Link } from "react-router-dom";
import { useEffect } from "react";

function Numbers() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
  return (
    <div className="text-start p-3">

        <h5>Number</h5>

        <br /><br /><br />
        <p>In JavaScript, numbers are used to represent numeric values, and they can be of two main types:</p>

        <ol>
            <li><b>Integers:</b> Whole numbers, like 10, 25, or -7.</li>
            <li className="mt-2"><b>Decimals:</b> Numbers with a decimal point, like 3.14, 1.50, or -9.25.</li>
        </ol>


        <br />
        <p>JavaScript uses a special format called <b>double-precision floating-point </b> to store numbers internally. This format allows it to represent a wide range of values, including both very large and very small numbers.</p>



        <br /><br />

        <p>Here are some key things to know about numbers in JavaScript:</p>

        <ul>
            <li><b>All numbers are treated as floating-point:</b> There's no separate data type for integers.</li>
            <li className="mt-3"><b>Conversion:</b> You can convert other data types to numbers using the Number() function.</li>
            <li className="mt-3"><b>Special values:</b> JavaScript has special numeric values like Infinity (representing a value too large to represent), -Infinity (too small), and NaN (Not a Number) for situations where a valid number can't be determined.</li>
            <li className="mt-3"><b>Checking if a value is a number:</b> Use the isNaN() function to check if a value is a number or not.</li>
        </ul>



        <br />
        <p>I will give an example below:</p>

        <div className="Box mt-5">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">let</span> <span className="text-info">number1</span> = <span className="text-danger">100</span> ;</p>
            <p><span className="text-warning">let</span> <span className="text-info">number2</span> = <span className="text-danger">50</span> ;</p>

            <p> console.log( <span className="text-info">number1</span> + <span className="text-info">number2</span>);</p>

            <p>output: <span className="text-secondary">150</span></p>
        </div>

        <h5 className="mt-2">Or</h5>

        <div className="Box mt-2">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">let</span> <span className="text-info">number1</span> = <span className="text-danger">100</span> ;</p>
            <p><span className="text-warning">let</span> <span className="text-info">number2</span> = <span className="text-danger">50,5</span> ;</p>

            <p> console.log( <span className="text-info">number1</span> + <span className="text-info">number2</span>);</p>

            <p>output: <span className="text-secondary">150.5</span></p>
        </div>

        <br /><br />

        <p>here's a simple example in JavaScript demonstrating the special numeric values:</p>

        <div className="Box">
            <h6>JavaScript</h6>
            <br />

            <span className="text-secondary">// Infinity represents a value too large to represent</span>
            <p><span className="text-warning">let</span> largeNumber  = <span className="text-danger">Infinity</span></p>
            <p><span className="text-danger">console.log</span>(<span className="text-info">"Too large number:"</span>, largeNumber) <span className="text-secondary">// Output: Infinity</span></p>


            <span className="text-secondary">// -Infinity represents a value too small</span>
            <p><span className="text-warning">let</span> smallNumber  = <span className="text-danger">-Infinity</span></p>
            <p><span className="text-danger">console.log</span>(<span className="text-info">"Too small number:"</span>, smallNumber) <span className="text-secondary">// Output: -Infinity</span></p>


            <span className="text-secondary">// NaN (Not a Number) for situations where a valid number can't be determined</span>
            <p><span className="text-warning">let</span> result  = <span className="text-info">"hello"</span> / <span className="text-danger">5</span></p>
        </div>

        <p className="mt-3">In this example, Infinity is used to represent a value too large to be represented by JavaScript's numeric data type. -Infinity represents a value too small to be represented. And NaN is the result when attempting to perform a mathematical operation (division) with a non-numeric value.</p>


        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/String' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Booleans' >Next</Link>
        </div>
    </div>
  )
}

export default Numbers