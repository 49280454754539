import { Link } from "react-router-dom"
import { useEffect } from "react";

function Transition() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='text-start p-3'>
        <h3 className="text-center">Transition</h3>
        <br /><br /><br />

        <p>Transitions in CSS provide a way to create smooth visual effects when an element's properties change.  They bridge the gap between the element's initial state and its final state, making the change appear more natural and polished.</p>

        <p className="mt-5"><strong>Here's how transitions work:</strong></p>

        <ol>
            <li><b>Target the Property</b>: You define the CSS properties you want to <span className="light">transition</span> using the transition shorthand property.</li>
            <li className="mt-2"><b>Specify Duration and Timing</b>: Within the <span className="light">transition</span> property, you set the duration (time it takes) and the timing function (how the animation progresses over time) that controls the smoothness of the change.</li>
            <li className="mt-2"><b>Element State Change</b>:  When the targeted property of the element changes due to user interaction (like hovering over a button) or JavaScript manipulation, the transition kicks in.</li>
        </ol>

        <p className="mt-5"><b>Common Uses of Transitions:</b></p>
        <ul>
            <li><b>Hover effects</b>: Smoothly change an element's background color, opacity, or transform (scale, rotation) when hovered over</li>
            <li className="mt-3"><b>Visibility changes</b>: Transition elements smoothly into or out of view.</li>
            <li className="mt-3"><b>State changes</b>: Animate changes between different element states (e.g., toggling a menu from open to closed).</li>
        </ul>


        <p className="mt-5"><b>Example - Button Hover Effect:</b></p>
        <p className="mt-3">Let's create a button that changes color when hovered over with a smooth transition:</p>

        <div className="text-light p-3 bg-dark">
            <h6>CSS</h6>
            <br />
            <p><span className="tetx-danger">button</span> {`{`}</p>
            <p className="mx-4">background-color: <span className="text-warning">#333</span>;</p>
            <p className="mx-4">color: white;</p>
            <p className="mx-4">padding: <span className="text-warning">10px 20px</span>;</p>
            <p className="mx-4">border: none;</p>
            <p className="mx-4">transition: background-color <span className="text-warning">0.5s</span> ease; <span className="text-secondary">/* Transition properties */</span></p>
            <p>{`}`}</p>
            <p className="mx-4"></p>
            <br />
            <p><span className="text-danger">button</span>:<span className="text-info">hover</span> {`{`}</p>
            <p className="mx-4">background-color: <span className="text-warning">#0095ff</span>;</p>
            <p>{`}`}</p>
        </div>

        <p className="mt-4">In this example:</p>
        <ul>
            <li>The <span className="light">button</span> style defines the initial appearance of the button.</li>
            <li className="mt-2">The <span className="light">transition</span> property specifies that the <span className="light">background-color</span> property will transition over 0.5 seconds with an <span className="light">ease</span> timing function (gradually accelerates and decelerates).</li>
            <li className="mt-2">The <span className="light">button:hover</span> pseudo-class defines the style when the button is hovered over. The background color smoothly transitions to blue thanks to the defined transition.</li>
        </ul>

        <h6 className="mt-5">Additional Considerations:</h6>
        <ul>
            <li>You can chain multiple properties in the <span className="light">transition</span> property (e.g., <span className="light">transition: background-color 0.5s ease, transform 0.3s</span>). Each property will have its own duration and timing function if specified.</li>
            <li>Transitions can be applied to various properties beyond color, including opacity, transform (movement and rotation), and size.</li>
        </ul>


        <p className="mt-5">By incorporating transitions into your CSS, you can enhance the user experience of your web pages by creating a more polished and visually appealing interface with smooth state changes.</p>


        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Animation'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/CSSAnimationLibraries'>Next</Link>
        </div>
    </div>
  )
}

export default Transition