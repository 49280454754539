import { Link } from "react-router-dom"
import { useEffect } from "react";

function BasicSelectors() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3'>
        <h3>Basic selectors</h3>

        <br/>
        <ul className="text-start">
          <li>Type</li>
          <li>ID</li>
          <li>Class</li>
        </ul>
        <br/><br/>
        <p className='text-start'>Basic selectors in CSS are the building blocks for targeting specific elements on your webpage to style them. These selectors allow you to choose which HTML elements will be affected by the CSS rules you define.</p>
        <p className='text-start'> Here's a breakdown of the most common basic selectors:</p>
        <p className='text-start'>1. <b>Element Selectors</b>: These target HTML elements by their tag name. For example, p would select all paragraph elements, h1 would target all heading elements of level 1, and div would select all {`<div>`} elements.</p>

        <p className='text-start'><b>ID Selectors</b>: IDs are unique identifiers assigned to a single element using the id attribute in HTML. To target an element with a specific ID in CSS, use the hash symbol # followed by the ID name. For instance, #main-content would select the element with the ID main-content.</p>

        <p className='text-start'>Class Selectors: Classes are used to group similar elements together. They are assigned using the class attribute in HTML. In CSS, a class selector is denoted by a period . followed by the class name. For example, .special would target all elements that have the class special assigned to them.</p>

        <p className='text-start'>These three selectors form the foundation for applying styles to specific parts of your webpage.  There are other more advanced selector types available in CSS, but mastering these basic ones will give you a strong base for styling your webpages.</p>

        <br/>
        <br/>
        <p>In the following, we will tell you more selectors</p>
        <br/>
        <br/>
        <Link className="px-3" to='/TutorialCss/CSSsyntax' >Previous</Link>
        <Link className="px-3" to='/TutorialCss/DescendantSelectors' >Next</Link>
    </div>
  )
}

export default BasicSelectors