import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routing from './Routes/Routing';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { Row, Col} from 'react-bootstrap';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Row>
            <Col lg={12}>
              <Navbar />
            </Col>
            <Col lg={12}>
              <Routing />
            </Col>
            <Col lg={12}>
              <Footer />
            </Col>
          </Row>
          
        </BrowserRouter>
    </div>
  );
}
export default App;