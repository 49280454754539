import {Row,Col} from 'react-bootstrap';
import WebSite from '../../assets/Image/Social/web.png';
import Instagram from '../../assets/Image/Social/instagram.png';
import Linkedin from '../../assets/Image/Social/linkedin.png';
import LogoSite from '../../assets/LogSite/Logo.png';
import './Footer.css';

const Footer = () => {
  return (
    <div className='Footer'>
        
        <a href="https://styles-css.com/PrivacyPolici">Privacy policy</a>
        <div className='containerFooter'>
            <div>
                <div className='container-items-footer'>
                    <div>
                        <img style={{width: '80px'}} src={LogoSite} alt="styles css logo" />
                    </div>
                    <div className='mt-4'>
                        {/* <p>STYLES - CSS</p> */}
                        
                    </div>
                </div>
            </div>
            <Col lg={3}>
                <div className='container-items-footer'>
                    <b>Contact</b>
                    <ul className='Ulist'>
                        <li><a href="mailto:sheikhlaryounes@gmail.com">Email</a></li>
                        <li><a href='https://styles-css.com/About'><p>About Us</p></a></li>
                    </ul>
                </div>
            </Col>
            <Col lg={3}>
                <div className='container-items-footer'>
                    <b>SERVICES</b>
                    <ul className='Ulist'>
                        <li>Styles</li>
                        <li>Sample</li>
                        <li>Components</li>
                        <li>Generators</li>
                    </ul>
                </div>
            </Col>
            <Col lg={3}>
                    <div className='container-items-footer'>
                        <b>FOLLOW</b>
                        <ul className='Ulist'>
                            <li className='my-1'>
                                <a  target='_blank' href="https://younessheikhlar.com/">
                                    <img src={WebSite} alt="portfolio" /> <span>Website</span>
                                </a>
                            </li>
                            <li className='my-1'>
                                <a  target='_blank' href="https://www.instagram.com/uniqementdev/">
                                    <img src={Instagram} alt="account instagram" /> <span>Instagram</span>
                                </a>
                            </li>
                            <li className='my-1'>
                                <a  target='_blank' href="https://www.linkedin.com/in/younes-sheikhlar/">
                                    <img src={Linkedin} alt="account linkedin" /> <span>Linkedin</span>
                                </a>
                            </li>
                        </ul>
                    </div>
            </Col>
        </div>
        <p className='text-center'>	&#9400; Younes Sheikhlar</p>
    </div>
  )
}

export default Footer