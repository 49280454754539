import { Link } from "react-router-dom"
import { useEffect } from "react";

function BlockAndInlineLayout() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Block and inline layout</h3>
        <br /><br /><br /><br />

        <p className="text-start">Block and inline layouts are fundamental concepts in web page design using HTML and CSS. They determine how elements are positioned and displayed on a web page.</p>

            <ul className="text-start mt-5">
                <li><b>Block-level elements</b>: These elements start on a new line and typically take up the full available horizontal width within their container. Examples include headings (<span className="light">{`<h1>`}</span>) to (<span className="light">{`<h6>`}</span>), paragraphs (<span className="light">{`<p>`}</span>), and divisions (<span className="light">{`<div>`}</span>). You can control their width and height with CSS.</li>
                <li><b>Inline elements</b>: These elements flow along the line with other inline elements, only occupying the horizontal space needed for their content. They don't force a line break before or after themselves. Examples include spans (<span className="light">{`<span>`}</span>), anchors (<span className="light">{`<a>`}</span>), and strong emphasis (<span className="light">{`<strong>`}</span>). Inline elements typically don't respond to width and height properties in CSS.</li>
            </ul>

            <p className="text-start mt-2">By default, browsers assign these display types to HTML elements based on their semantic meaning.  However, you can use the <span className="light">display</span> property in CSS to override the default behavior and achieve different layouts. For instance, you can turn a block-level element into an inline element using <span className="light">display: inline</span>;.</p>

            <p className="text-start"></p>

            <p>You can control block and inline layouts using the display property in CSS. Here's how:</p>

            <h5 className="mt-5">Changing Default Display:</h5>
            <ul className="m-3">
                <li>By default, some HTML elements are block-level (e.g., headings, paragraphs) and some are inline (e.g., spans, anchors).</li>
                <li>To change an element's display type, use the <span className="light">display</span> property with the desired value:</li>
                <ul>
                    <li><span className="light">display: block;</span> - Makes an element behave like a block-level element (starts on a new line, full width).</li>
                    <li className="mt-1"><span className="light">display: inline;</span> - Makes an element behave like an inline element (flows with other content, doesn't break line).</li>
                </ul>
            </ul>

            <h5 className="mt-5">Example:</h5>
            <div className="text-light p-3 bg-dark">
                <h5>HTML</h5>
                <br />
                
                <p><span className="text-warning">{`<span>`}</span>This is a span element. By default it's inline.<span className="text-warning">{`</span>`}</span></p>
                <p><span className="text-warning">{`<br>`}</span></p>
                <p><span className="text-warning">{`<div `}</span> <span className="text-danger"> style</span>=<span className="text-info">"display: inline;"</span><span className="text-warning">{`>`}</span>This div is forced to be inline using CSS.<span className="text-warning">{`</div>`}</span></p>
            </div>

            <h5 className="mt-5">Beyond Block and Inline:</h5>
            <ul>
                <li>There's also <span className="light">display: inline-block;</span> which combines aspects of both:</li>
                <ul>
                    <li>Acts mostly like inline (sits next to other elements).</li>
                    <li>Allows setting width and height (unlike pure inline).</li>
                    <li>Respects top and bottom margins (unlike pure inline).</li>
                </ul>
            </ul>

            <h5 className="mt-5">Additional Display Values:</h5>
            <ul>
                <li><span className="light">display: none;</span> - Hides the element completely.</li>
                <li><span className="light">display: flex;</span> or <span className="light">display: grid; </span>- Used for more complex layouts with rows, columns, or positioning elements.</li>
            </ul>


            <h5 className="mt-5">Remember:</h5>
            <ul>
                <li>Changing the display type might affect other styles. Be mindful of margins, padding, and how the element interacts with surrounding content.</li>
                <li>Experiment with different values to achieve the desired layout for your web page.</li>
            </ul>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/ComponentBox'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/FloatingPositioningLayout'>Next</Link>
        </div>
    </div>
  )
}

export default BlockAndInlineLayout