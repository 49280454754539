import {useState, useEffect} from 'react';
import Styled  from 'styled-components';
// const backgroundContainer = "https://i.pinimg.com/736x/db/81/06/db81068a0199a1169009ab87b9407803.jpg";
import ImageCard from '../../../assets/LogSite/Logo.png';

const ContainerGlass = Styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ccc;
  display: flex;
  flex-direction: column;
  background-color: #0c0c0c;
 
`
const ContainerCardMorphism = Styled.div`
width: 100%;
height: 900px;  
border: 1px solid #000;
`

const ContainerImage = Styled.div`
 width: 300px;
 height: 200px;
 margin:100px 50px;
 position: absolute;
 z-index: 1;
`
// Setting container
const ContainerSetting = Styled.div`
width: 100%;
height: 100%;
background-color: #ccc;
display: flex;
justify-content: space-between;
`
const SpanClickToCopy = Styled.span`
  margin-top: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  color: #ccc;
  &:hover {
    color: yellow;
    box-shadow: 0 0 5px #ccc;
  }
  

`
const GlassMorphismComponent = () => {
  const [ blurCard, setBlurCard] = useState(6);
  const [ colorCard, setColorCard] = useState('rgba(255, 255, 255, 0.2)')

  const [opacity, setOpacity] = useState(1); 
  const [rgbColor,setRgbaColor] = useState('rgba(255, 255, 255, 0.2)')

  const [borderCard, setBoredrCard] = useState(1);

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0); 
  },[])
 

  const handleColorChange = (event) => {
    const { value } = event.target;
    const colorCard = `rgba(${value.slice(-6, -4)}, ${value.slice(-4, -2)}, ${value.slice(-2)}, ${opacity})`;
    setRgbaColor(colorCard);
    setColorCard(colorCard); // Update colorCard state as well
  };

  const handleOpacityChange = (event) => {
    setOpacity(event.target.value);
  };

  
const CardMorphism = Styled.div`
  width: 300px;
  height: 200px;
  margin-top: 80px;
  border-radius: 15px;
  z-index: 2;
  position: absolute;
  // background: rgba(255, 255, 255, 0.2);
  background: ${rgbColor};
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(${blurCard}px);
-webkit-backdrop-filter: blur(${blurCard}px);
border:  solid ${borderCard}px rgba(255, 255, 255, 0.3);
  div {
    width: 100%;
    height: 50%;
  }
`

const CodesContainer = Styled.div`
padding: 10px 5px;
background: #0c0c0c;
border: 1px solid #000;
display: flex;
flex-direction: column;
`
const ContainerVarible = Styled.div`

`

  return (
    <div >
      <h4>Glass morphism generator</h4>
      
      <ContainerGlass>
        {/* ------------  Card  ------------- */}
        <ContainerCardMorphism>
          <div className='w-100 d-flex justify-content-center'>
            <ContainerImage>
              <img style={{width: '300px'}} src={ImageCard} alt="" />
            </ContainerImage>
            <CardMorphism style={{backgroundColor:`${rgbColor}`, opacity:`${opacity}`}}>
              <div>

              </div>
              <div className='text-start p-2 text-light'>
                <p>Card</p>
                <p>Glassmorphism</p>
              </div>
            </CardMorphism>
          </div>
        </ContainerCardMorphism>

        {/* ------------   Setting   ------------- */}
        <ContainerSetting>
          <div className='d-flex flex-column p-5 '>
            <div className='d-flex align-items-center justify-content-between'>
              <input type="range" min="1" max="100" onChange={(event) => setBlurCard(event.target.value)} value={blurCard} />
              <span>Blur: <span id="demo">{blurCard}</span></span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <input type="range" min='0' max='10' onChange={(event) => setBoredrCard(event.target.value)} value={borderCard}  /> <span>Border : {borderCard}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <input type="range" min="0" max="1" step="0.1" value={opacity} onChange={handleOpacityChange} /> <span>Opacity: {opacity}</span> <br />
              <input type="color" value={rgbColor} onChange={handleColorChange} />
            </div>
            
          </div>
          <CodesContainer>
            <code className='text-start text-light '
              
            >
              backdrop-filter: blur({`${blurCard}`}px);
              <br />
              -webkit-backdrop-filter: blur({`${blurCard}`}px);
              <br />
              background:{`${colorCard}`};
              <br />
              opacity:{`${opacity}`};
              <br />
              border:  solid {`${borderCard}`}px rgba(255, 255, 255, 0.3);
            </code>
              <SpanClickToCopy 
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigator.clipboard.writeText(`
                  backdrop-filter: blur(${blurCard}px);
                  -webkit-backdrop-filter: blur(${blurCard}px);
                  background:${colorCard};
                  opacity:${opacity};
                  border:  solid ${borderCard} rgba(255, 255, 255, 0.3);
                  `
                  );
                }}
              >Click to copy</SpanClickToCopy>
          </CodesContainer>
        </ContainerSetting>
      </ContainerGlass>
    </div>
  )
}

export default GlassMorphismComponent;