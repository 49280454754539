import { Link } from "react-router-dom";
import { useEffect } from "react";

function BasicHTMLstructure() {

  useEffect(() => {
    window.scrollTo(0, 0); // اسکرول به بالای صفحه
  }, []);
  
  return (
    <div className='px-3'>
        <h2>A Simple Tutorial on Basic HTML Structure</h2>

        <p className='text-start mt-5'>HTML or "Hypertext Markup Language" is the primary language for building websites. One crucial aspect of learning HTML is grasping its basic structure. In this tutorial, you'll become familiar with the fundamental structure of HTML.</p>

        <h3 className='text-start mt-5'>1. Tags</h3>

        <p className='text-start mt-5'>HTML works with "tags," which are predefined symbols like <tagname> and </tagname>. Each tag delineates the start and end of an element on a web page.</p>


        <h3 className='text-start mt-5'>2. Basic HTML Structure</h3>

        <p className=' mt-5'>The basic HTML structure looks like this:</p>

            <div className='text-start p-4 bg-dark ' style={{color:'#fff'}}>
               <h6>{`<!DOCTYPE html>`}</h6>
               <p>{`<html>`}</p>
               <p className='px-3'>{`<head>`}</p>
               <p className='px-5'>{`<title>Page Title</title>`}</p>
               <p className='px-3'>{`</head>`}</p>
               <p className='px-3'>{`<body>`}</p>
               <p className='px-5'>{`<!-- Page content goes here -->`}</p>
               <p className='px-3'>{`</body>`}</p>
               <p>{`</html>`}</p>
            </div>


        <h3 className='text-start mt-5'>3. Explanation of Basic HTML Structure:</h3>

        <p className='text-start mt-4'>{`<!DOCTYPE html>`}: This tag informs the browser that the HTML version in use is standard.</p>
        <p className='text-start'>{`<html>`}: This tag indicates the beginning of HTML content.</p>
        <p className='text-start'>{`<head>`}: This tag contains information relevant to your webpage's display, such as the page title, styles, and scripts.</p>
        <p className='text-start'>{`<title>`}: This tag specifies the page title, which is displayed in the browser's title bar.</p>
        <p className='text-start'>{`<body>`}: This tag initiates content visible to the user.</p>

        <h3 className='text-start mt-5'>4. Example Usage</h3>
        <p className='mt-5'>As an example, here's a simple site displaying "Hello, World!":</p>
        <div className='text-start p-4 bg-dark  ' style={{color:'#fff'}}>
            <div className='text-end'>Copy</div>
               <h6>{`<!DOCTYPE html>`}</h6>
               
               <p>{`<html>`}</p>
               <p className='mx-3'>{`<head>`}</p>
               <p className='mx-5'>{`<title>Hello, World!</title>`}</p>
               <p className='mx-3'>{`</head>`}</p>
               <p className='mx-3'>{`<body>`}</p>
               <p className='mx-5'>{`<h1>Hello, World!</h1>`}</p>
               <p className='mx-5'>{`<p>This is a simple webpage.</p>`}</p>
               <p className='mx-3'>{`</body>`}</p>
               <p>{`</html>`}</p>
              

            </div>

            <p className='text-start mt-5'>In this example, {`<h1>`} is a header tag indicating a top-level heading, and {`<p>`} is a paragraph tag indicating text content.</p>
            <Link to='/TutorialCss/CommonHTMLTags'>Next</Link>
    </div>
  )
}

export default BasicHTMLstructure