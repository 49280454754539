import { Link } from "react-router-dom";
import { useEffect } from "react";

function String() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
  return (
    <div className='text-start p-3'>
        <h5>String</h5>
        <br /><br /><br />

        <p>In JavaScript, a string is a fundamental data type that represents a sequence of characters. These characters can be letters, numbers, symbols, or even spaces. Strings are used to store and manipulate textual data, which is essential for many programming tasks.</p>


        <p className="mt-3">Here's a breakdown of what strings are and how they work in JavaScript:</p>

        <br />
        <h5>Storing Text Data:</h5>
        <ul className="mt-4">
            <li>You can create strings by enclosing characters within quotation marks. JavaScript allows you to use single quotes ('), double quotes ("), or backticks (backtick characters). These quotes tell JavaScript that everything inside them is part of the string.</li>
            <li className="mt-2">For instance, you can store your name like this: let name = 'Younes';</li>
        </ul>


        <br />
        <br />

        <h5>String Manipulation:</h5>
        <ul className="mt-4">
            <li>JavaScript provides various methods and operators for working with strings. You can combine (concatenate) multiple strings using the plus (+) operator.</li>
            <li className="mt-2">There are also built-in functions to find the length of a string, extract substrings, search for specific characters, and perform many other operations on textual data.</li>
        </ul>

        <br />
        <br />

        <h5>An Important Note:</h5>
        <ul className="mt-4">
            <li>While you can interact with strings using methods, it's important to remember that primitive strings themselves are not objects in JavaScript. However, JavaScript provides a way to mimic object-like behavior for strings when necessary.</li>
        </ul>







        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Variables' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Numbers' >Next</Link>
        </div>
    </div>
  )
}

export default String