import { Link } from "react-router-dom";
import { useEffect } from "react";

function Introduction() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="p-3 text-start">
        <h3 className="text-center">Introduction</h3>
        <br /><br /><br />
        <p>Imagine CSS with superpowers! That's essentially what CSS preprocessors are. They are scripting languages that extend the capabilities of regular CSS, allowing you to write more maintainable and efficient stylesheets.</p>

        <p className="mt-5">Here's how they work: You write your styles in the preprocessor's syntax (like Sass or LESS), which includes features not found in plain CSS. Then, a program called a compiler  processes your code and generates a standard CSS file that browsers can understand.</p>

        <h6 className="mt-5">Benefits of using CSS preprocessors:</h6>

        <ul>
            <li><b>Variables</b>: Define and reuse values throughout your stylesheet, making updates easier.</li>
            <li><b>Mixins</b>: Create reusable code blocks for styles you use repeatedly.</li>
            <li><b>Nesting</b>: Organize your selectors in a more readable hierarchy.</li>
            <li><b>Functions</b>: Perform calculations and manipulations within your styles.</li>
            <li><b>Loops</b>: Automate repetitive tasks for increased efficiency.</li>
        </ul>


        <h6 className="mt-5">Popular CSS Preprocessors</h6>

        <p>There are several CSS preprocessors available, each with its own syntax and features. Here are two of the most widely used:</p>

        <ul>
            <li>Sass (Syntactically Awesome Style Sheets):  A mature and powerful option with a strong community. Sass offers features like nested selectors, mixins, and variables. It comes in two flavors: Sass ( indented syntax) and SCSS (SCSS syntax similar to CSS).</li>
            <li>LESS (Leaner Style Sheets):  Another popular choice known for its clean and readable syntax. LESS offers similar features to Sass, such as variables, mixins, and nesting, but with a slightly different syntax.</li>
        </ul>


        <p className="mt-5">Learning a CSS preprocessor can significantly improve your workflow and the quality of your stylesheets.  They are especially useful for larger projects with complex styles.</p>







        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/AdaptiveDesign'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/CssVariable'>Next</Link>
        </div>
    </div>
  )
}

export default Introduction