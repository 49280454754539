import { useState , useEffect} from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// import '../Box-Shadow/style.css';
function Radius() {

  const [radius, setRadius] = useState(30);
  const [color, setColor] = useState('#009988');
  const [background, setBackground] = useState('#F1D6B8');
  const [borderSize , setBorderSize] = useState('5');
  const [colorBorder , setColorBorder] = useState('#000');
  const [leftShadow , setLeftShadow] = useState('20');
  const [topShadow , setTopShadow] = useState('30');
  const [blur , setBlur] = useState('50');
  const [formBorder , setFormBorder] = useState('solid');
  const [ shadowColor , setShadowColor ] = useState('#000')

  
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0); 
  },[])
 
  return (
    <div className='allHeight py-2 px-3' >
      {/* Meta tags */}
      <Helmet>
      
     <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        <title>Generate CSS codes styleuse</title>
        <meta name="description" content="Styleuse - A tool to easily generate CSS codes like box-shadow and border-radius." />
        <meta name="keywords" content="CSS, CSS code generation, box-shadow, border-radius, CSS tools" />
        <meta name="keywords" content="CSS, تولید کد CSS، سایه جعبه، شعاع مرز، ابزارهای CSS" />
        <meta name="keywords" content="CSS, إنشاء كود CSS، ظل الصندوق، نصف قطر الحدود، أدوات CSS" />
        <meta name="keywords" content="CSS, génération de code CSS, box-shadow, border-radius, outils CSS" />
        <meta name="keywords" content="CSS, CSS-Codegenerierung, Box-Shadow, Border-Radius, CSS-Tools" />
        <meta name="keywords" content="CSS, generación de código CSS, box-shadow, border-radius, herramientas CSS" />
        <meta name="keywords" content="CSS, CSS 代码生成, 盒子阴影, 边框半径, CSS 工具" />
        <meta name="keywords" content="CSS, CSSコード生成, ボックスシャドウ, ボーダー半径, CSSツール" />
        <meta name="keywords" content="CSS, CSS 코드 생성, 상자 그림자, 테두리 반경, CSS 도구" />
        <meta name="keywords" content="CSS, geração de código CSS, box-shadow, border-radius, ferramentas CSS" />
        <meta name="keywords" content="CSS, генерация кода CSS, box-shadow, border-radius, инструменты CSS" />
        <meta name="keywords" content="CSS, CSS कोड निर्माण, बॉक्स शैडो, बॉर्डर रेडियस, CSS टूल्स" />
        <meta name="keywords" content="CSS, CSS kod oluşturma, kutu gölgesi, kenarlık yarıçapı, CSS araçları" />
        <meta name="keywords" content="CSS, generazione di codice CSS, box-shadow, border-radius, strumenti CSS" />
        <meta name="keywords" content="CSS, יצירת קוד CSS, צל תיבה, רדיוס גבול, כלי CSS" />
        <meta name="keywords" content="CSS, generowanie kodu CSS, box-shadow, border-radius, narzędzia CSS" />

      </Helmet>

      {/* Advertising ----------------------------*/}
      <Row className='mt-5 pt-2'>
        <Col lg={2} className='position-relative d-flex justify-content-center' >
          <div className='Advertising  mt-5 pt-5 px-5 '> 
            <amp-ad width="100vw" height="320"
              type="adsense"
              data-ad-client="ca-pub-1631607859297946"
              data-ad-slot="2957199157"
              data-auto-format="mcrspv"
              data-full-width="">
              <div overflow=""></div>
            </amp-ad>
          </div>
        </Col>

      {/* Item ---------------------------------*/}

      <Col lg={8} className='py-5  d-flex flex-column justify-content-center align-items-center' style={{backgroundColor:`${background}`}}>
        {/* Background  */}
        <div style={{width:'100%',display:'flex',alignItems:'center'}} >
          <h4>Background color  </h4>
          <input className='border-2 px-1 mx-2 ' onChange={(event) => setBackground(event.target.value)} defaultValue={'#F1D6B8'} type="color" />
        </div>
        <p className='mt-5'></p>
        <div style={{width:'200px',height:'200px', borderRadius:`${radius}%`, background:`${color}`, border:`${formBorder} ${borderSize}px ${colorBorder}`, boxShadow: `${leftShadow}px ${topShadow}px ${blur}px ${shadowColor}`}}></div>

        <code style={{marginTop:'100px',padding:'50px', position:'relative'}} className='w-75  text-light rounded-5 text-start bg-dark'>
          border-radius : {`${radius}%`};<br />
          background :<span style={{width:'60px',height:'20px', background:`${color}` ,padding:'2px 5px', borderRadius:'4px'}}>{`${color}`}</span>;<br />
          border : {`${formBorder} ${borderSize}px `}<span style={{backgroundColor:`${colorBorder}`,padding:'2px 5px', borderRadius:'4px'}}>{`${colorBorder}`}</span>;<br />
          box-shadow: {`${topShadow}px ${leftShadow}px ${blur}px` } <span style={{backgroundColor:`${shadowColor}`,padding:'2px 5px', borderRadius:'4px'}}>{`${shadowColor}`}</span>

          <span
          onClick={() => {
            navigator.clipboard.writeText(`
              border-radius: ${radius}%;
              background: ${color};
              border: ${formBorder} ${borderSize}px ${colorBorder};
              box-shadow: ${topShadow}px ${leftShadow}px ${blur}px ${shadowColor}
            `)
          }}
        className='bg-dark text-light px-5 py-2 border rounded-4 ' style={{cursor:'pointer',right:'15px',top:'5px', position:'absolute' }}
        >Copy to clipboard</span>
        </code>
        

        {/*    --------------  Setting    ---------------- */}
        <div >

          <div className='w-100 mt-3 d-flex gap-5' >

            {/* Setting ------- 1 */}
            <div lg={6} className='d-flex flex-column '>
                {/* Radius */}
                <div style={{display:'flex',alignItems:'center'}} >
                    <input type="range" className="custom-range" min="0" max="50" defaultValue={30} onChange={(event) => setRadius(event.target.value)} />
                    <h4>{radius}% Border radius</h4>
                </div>

                <div style={{display:'flex',alignItems:'center'}}>
                  {/* Border */}
                    <input onChange={(event) => setBorderSize(event.target.value)} defaultValue={0} type="range" />
                    <h4>{borderSize}px Border size</h4>
                </div>

                

                  {/* Top shadow */}
                <div style={{display:'flex',alignItems:'center'}}>
                  <input onChange={(event) => setTopShadow(event.target.value)} defaultValue={30} min="-100" max="100"  type="range" />
                  <h4>{topShadow}px Top shadow </h4>
                </div>
                 {/* Left shadow */}
              <div style={{display:'flex',alignItems:'center'}}>
                <input onChange={(event) => setLeftShadow(event.target.value)} defaultValue={30} min="-100" max="100"  type="range" />
                <h4>Left shadow </h4>
              </div>

              {/* blur shadow */}
              <div style={{display:'flex',alignItems:'center'}}>
                <input onChange={(event) => setBlur(event.target.value)} defaultValue={'50'} min="0" max="100"  type="range" />
                <h4>Blur </h4>
              </div>
            </div>


            {/* Setting ------ 2 */}

            <div lg={6} className='d-flex flex-column '>
              
              {/* Form border as  solid dashed ... */}

              <div className='d-flex'>
                  <select onChange={(event) => setFormBorder(event.target.value)} name="border" id="borer">
                    <option value="solid">Solid</option>
                    <option value="dashed">dashed</option>
                    <option value="dotted">dotted</option>
                    <option value="double">double</option>
                    <option value="groove">groove</option>
                    <option value="ridge">ridge</option>
                    <option value="inset">inset</option>
                    <option value="outset">outset</option>
                    <option value="none">none</option>
                  </select>
                  <span>&nbsp; &nbsp; </span>
                  <div style={{border:`${formBorder} 10px`,width:'100px',borderBottom:'transparent',borderLeft:'transparent',borderRight:'transparent',}}></div>
                </div>

              {/* BackGround color Item */}
              <div style={{display:'flex',alignItems:'center'}}>
                <input onChange={(event) => setColor(event.target.value)} defaultValue={'#009988'} type="color" />
                <h4>Background item</h4>
              </div>

          

              {/* Color Border */}
              <div style={{display:'flex',alignItems:'center'}}>
                 <input onChange={(event) => setColorBorder(event.target.value)} defaultValue={'#000'} type="color" />
                 <h4>Border color </h4>
              </div>

              {/* Shadow color */}
              <div style={{display:'flex',alignItems:'center'}}>
                <input onChange={(event) => setShadowColor(event.target.value)} defaultValue={'#000'} type="color" />
                <h4>Shadow color </h4>
              </div>
            </div>
          
          </div>

        </div>
      </Col>

      <Col lg={2} className='  d-flex flex-column justify-content-center align-items-center' >
        <div className='vh-lg-100  position-fixed  '>
          <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-1631607859297946"
            data-ad-slot="2957199157"
            data-auto-format="mcrspv"
            data-full-width="">
            <div overflow=""></div>
          </amp-ad>
        </div>
      </Col>
      </Row>
    </div>
  )
}

export default Radius