import Styled from 'styled-components';
import { useState } from 'react';



const Card = ({
    id,
    shadowBox,
    index
}) => {

    const [ isCopied, setIsCopied ] = useState(false);
    const [ isHovered, setIsHovered ] = useState(false);
    const handleClick = () => {
        navigator.clipboard.writeText(shadowBox);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
    };
    const hoverMous = () => {
        setIsHovered(true)
    }
    const leaveMous = () => {
        setIsHovered(false)
    }

  const Box = Styled.div`
    width: 200px;
    height: 200px;
    margin: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: solid 0.1em #ccc;
    background-color: #FFFFFF;
    position: relative;
    cursor: pointer;
    ${shadowBox}
  `
    const Tooltip = Styled.span`
        position: absolute;
        top: -28px;
        background-color: #0c0c0c;
        color: #FFFFFF;
        padding: 5px 10px;
        border-radius: 4px;
    `

  const SpanCopy = Styled.span`
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 15px;
    ${isCopied ? 'opacity: 1;' : 'opacity: 0;'};
    transition: all 0.5;
  `
  return (
    <Box 
        onClick={handleClick}
        onMouseEnter={hoverMous}
        onMouseLeave={leaveMous}
    >
        {
            isHovered? <Tooltip>just click on box</Tooltip> : null
        }
        
        {index+1}
        {
            isCopied? <SpanCopy>Copied</SpanCopy> : null
        }
        
    </Box>
  )
}

export default Card