import { Link } from "react-router-dom";
import { useEffect } from "react";

function CSSsyntax() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <div className='p-3'>
        <h3>CSS syntax</h3>
        <br/>
        <br/>
        <br/>
        <p className='text-start'>CSS syntax is the set of rules that define how to write CSS code. It's like a language with its own grammar and vocabulary, used to tell a web browser how to style the elements on a webpage. </p>
        <p className="text-start mt-5">Here's a breakdown of the key components:</p>
        <h5 className='text-start mt-5'>Selectors</h5>
        <ul className='text-start'>
            <li>These act like pointers, identifying the HTML elements you want to style.</li>
            <li>There are different types of selectors, like tag selectors (e.g., <b>h1</b> for all heading elements), class selectors (e.g., .special for elements with the class "special"), and ID selectors (e.g., <b>#unique-element</b> for an element with the ID <b>"unique-element"</b>).</li>
        </ul>

        <h5 className='text-start mt-5'>Declarations</h5>
        <ul className='text-start'>
            <li>Once you've selected the elements, you use declarations to define how you want to style them.</li>
            <li>A declaration consists of a property and a value.</li>
            <li>The property specifies what aspect of the element you want to change (e.g., color, font-size, background-color).</li>
            <li>The value assigns a specific characteristic to that property (e.g., red, 16px, #f0f0f0).</li>
        </ul>

        <h5 className='text-start mt-5'>Values</h5>
        <ul className='text-start'>
            <li>Values come in various forms depending on the property.</li>
            <li>They could be colors (e.g., red, #ff0000), sizes (e.g., 10px, 1em), or even keywords like bold for font weight.</li>
        </ul>

        <h5 className='text-start mt-5'>Putting it all together</h5>
        <p className='text-start'>Imagine you want to turn all the paragraph elements ({`<p>`}) on your webpage blue and bold. </p>
        <br/>
        <p className='text-start'>Here's the CSS code:</p>

        <div className="mt-5 bg-dark text-light text-start p-3">
            <h4>CSS</h4>
            <br/>
            <br/>
            <br/>
            <p>{`p {`}</p>
            <p className='px-4'>{`color: blue;`}</p>
            <p className='px-4'>{`font-weight: bold;`}</p>
            <p>{`}`}</p>
        </div>

        <p className="text-start mt-3">In this example:</p>
        <ul className="text-start">
            <li><b>p </b>is the selector (targets all <b>{`<p>`}</b> elements).</li>
            <li><b>color</b> and <b>font-weight</b> are the properties.</li>
            <li><b>blue</b> and <b>bold</b> are the values assigned to those properties.</li>
        </ul>

        <p className="text-start mt-3">Remember, multiple declarations for a selector are separated by semicolons, and the whole thing is wrapped in curly braces.</p>
        <br/>
        <br/>
        <br/>
        <Link className="px-3" to='/TutorialCss/StructureofACSSfile'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/BasicSelectors' >Next</Link>
    </div>
  )
}

export default CSSsyntax