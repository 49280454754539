import { Row, Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import diamond from '../../../../assets/Image/Landing-pge/MainOne/cube.png';
import Planet from '../../../../assets/Image/Landing-pge/MainOne/Planet.png';
import elastic from '../../../../assets/Image/Landing-pge/MainOne/Ring.png';
import Ring from '../../../../assets/Image/Landing-pge/MainOne/RingTwo.png';
const MainOne = () => {
  return (
    <div className='MainOne'>
        <h1>Glassmorphism</h1>
        <Row className=' px-2'>
          <Col lg={6} className='d-flex   '>
            <div className='ContainerCard  '>
              <div className=''>
                <img className='diamond' src={diamond} alt='diamond'/>
                <img className='Planet' src={Planet} alt='Planet'/>

                <img className='Ring' src={Ring} alt='Ring'/>
                <img className='elastic' src={elastic} alt='elastic'/>
              </div>
              <div className=''>
                  <div className='CardMorphismMainOne '>
                    <h4 className='pt-3 text-light'>Glassmorphism</h4>
                  </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className='ContainerText '>
                <div className='w-75  textContainer'>
                  <h4 className='text-start px-3 '>Generator</h4>
                  <p>Glassmorphism Generator is a tool that allows designers to easily create glassmorphic elements without having to write any code. Simply enter the desired dimensions, colors, and blur radius, and the generator will create a glassmorphic element that can be copied and pasted into your design.</p>

                  <p>Using a Glassmorphism Generator can save designers a significant amount of time and effort. It can also help to ensure that the glassmorphic elements in your design are consistent and well-crafted.</p>
                  <div className=' d-flex justify-content-start '>
                    <Link to='https://styles-css.com/Glassemorphism' className='text-start border rounded'>You want to try it</Link>
                  </div>
                </div>
            </div>
          </Col>
        </Row>
        <div className='container description my-5 py-5'>
          <h2>Here are some of the benefits of using a Glassmorphism Generator</h2>
          <p><strong>Saves time and effort:</strong>  With a generator, you can create glassmorphic elements in a fraction of the time it would take to code them from scratch.</p>
          <p><strong>Ensures consistency:</strong> A generator can help you to create glassmorphic elements that are consistent in terms of size, color, and blur radius.</p>
          <p><strong>Provides a variety of options:</strong> Most generators offer a variety of options for customizing your glassmorphic elements, such as color, opacity, and blur.</p>
        </div>
    </div>
  )
}

export default MainOne