import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Row,Col} from 'react-bootstrap';
import Styled from 'styled-components';
import CopyIcon from '../../../assets/Icons/Samples/Gradient/save.png'
const Div = Styled.div`
width: 100%;
height: 500px;
border: solid 1px #ccc;
border-radius: 5px;
cursor: pointer;

@media screen and (max-width: 992px) {
    height: 100px;
}
input {
    left: 0;
}
`

const Paragraph = Styled.p`
    margin-top:20px;
    color:#000;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
`
const Img = Styled.img`
width: 20px;
cursor: pointer;
`

const ParamsPaletteColor = () => {
    const [ item , setItem] = useState({});
    const itemID = useParams().idColorPalette;
    // Change color states
    const [ changeOneColor, setChangeOneColor ] = useState();

    const DataOne = process.env.REACT_APP_URL_API;
    const DataTwo = 'http://localhost:5000';

async function getDataColor() {
  try {
    const response = await fetch(`${DataOne}/api/palette/${itemID}`);
    if (!response.ok) {
      // If the first API fails, try the second one
      console.warn(`Failed to fetch data from ${DataOne}`);
      const responseTwo = await fetch(`${DataTwo}/api/palette/${itemID}`);
      if (!responseTwo.ok) {
        throw new Error(`Failed to fetch data from both APIs`);
      }
      return responseTwo.json();
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw to potentially handle it at a higher level
  }
}

useEffect(() => {
    getDataColor()
    .then(data => setItem(data.data))
    .catch(error => console.error('Error setting item:', error));
}, [item]);


// copy color hex
 const handleCopy = (color) => {
    navigator.clipboard.writeText(color)
      .then(() => {
        // Handle success (optional)
      })
      .catch((error) => {
        // Handle error (optional)
      });
  };

//   copy color Rgba
const handleCopyRgba = (rgbaColor) => {
    navigator.clipboard.writeText(rgbaColor)
    .then(() => {
        // Handle success (optional)
      })
      .catch((error) => {
        // Handle error (optional)
      });
}
  const hexToRGBA = (hex = "#000000", alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const changeOneColorHandler = (color) => {
    setChangeOneColor(color, true);
}
 
  return (
    <div className='p-5'>
        <h3 className='mt-5 py-3'>You can customize your colors</h3>

        <Row className='container m-auto'>
            <Col lg={3}>
                {/* One color */}
                <Div style={{backgroundColor:`${item.oneColor}`}} >
                    
                </Div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{item.oneColor}</Paragraph> 
                    <Img onClick={() => handleCopy(item.oneColor)} src={CopyIcon}  alt="colors" />
                </div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{hexToRGBA(item.oneColor, 1)}</Paragraph>
                    <Img onClick={() => handleCopy(hexToRGBA(item.oneColor, 1))} src={CopyIcon}  alt="colors" />
                </div>
                <input type="color" value={item.oneColor} onChange={(e) => changeOneColorHandler(e.target.value)} name="" id="" />

            </Col>
            <Col lg={3}>
                {/* Two color */}
                <Div style={{backgroundColor:`${item.towColor}`}}></Div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{item.towColor}</Paragraph>
                    <Img onClick={() => handleCopy(item.towColor)} src={CopyIcon}  alt="colors" />
                </div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                  <Paragraph>{hexToRGBA(item.towColor, 1)}</Paragraph>
                  <Img onClick={() => handleCopy(hexToRGBA(item.towColor, 1))}  src={CopyIcon}  alt="colors" />
                </div>
                <input type="color" value={item.towColor} name="" id="" />
            </Col>
            <Col lg={3}>
                {/* Three color */}
                <Div style={{backgroundColor:`${item.threeColor}`}}></Div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{item.threeColor}</Paragraph>
                    <Img  onClick={() => handleCopy(item.threeColor)} src={CopyIcon}  alt="colors" />
                </div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{hexToRGBA(item.threeColor, 1)}</Paragraph>
                    <Img onClick={() => handleCopy(hexToRGBA(item.threeColor, 1))} src={CopyIcon}  alt="colors" />
                </div>
                <input type="color" value={item.threeColor} name="" id="" />
            </Col>
            <Col lg={3}>
                {/* Four color */}
                <Div style={{backgroundColor:`${item.fourColor}`}}></Div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{item.fourColor}</Paragraph>    
                    <Img  onClick={() => handleCopy(item.fourColor)} src={CopyIcon}  alt="colors" />
                </div>
                <div className='d-flex justify-content-between align-items-center px-3'>
                    <Paragraph>{hexToRGBA(item.fourColor, 1)}</Paragraph>
                    <Img onClick={() => handleCopy(hexToRGBA(item.fourColor, 1))} src={CopyIcon}  alt="colors" /> 
                </div>               
                <input type="color" value={item.fourColor} name="" id="" />
            </Col>
        </Row>
    </div>
  )
}

export default ParamsPaletteColor