import { Link } from "react-router-dom";
import { useEffect } from "react";


function ImportantNotes() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        
        <h3 className="text-center">Important Notes</h3>
        <br /><br /><br />

        <p>Following CSS best practices and standards will make your code cleaner, more maintainable, and future-proof. Here are some key areas to focus on:</p>

        <p className="mt-5"><b>Organization and Readability:</b></p>


        <ul>
            <li><b>Structure and Naming:</b>
                <ul>
                    <li>Use a CSS preprocessor like Sass or Less for better organization and features like variables and mixins.</li>
                    <li>Organize your stylesheet logically, separating sections with comments.</li>
                    <li>Employ a consistent naming convention for classes and IDs (BEM, OOCSS, SMACSS are popular options). Descriptive names make code easier to understand.</li>
                </ul>
            </li>
            <li>
                <b>Separation:</b>
                <ul>
                    <li>Avoid inline styles whenever possible. They make code harder to maintain and update.</li>
                    <li>Use external stylesheets for most of your styling.</li>
                </ul>
            </li>
        </ul>



        <p className="mt-5"><b>Code Maintainability:</b></p>

        <ul>
            <li>
                <b>DRY Principle (Don't Repeat Yourself):</b>
                <ul>
                    <li>Write reusable styles by using classes and inheritance.</li>
                    <li>Avoid duplicating code throughout your stylesheet.</li>
                </ul>
            </li>

            <li>
                <b>Specificity:</b>
                <ul>
                    <li>Understand how CSS specificity works to avoid unintended style overrides.</li>
                    <li>Use specific selectors only when necessary.</li>
                </ul>
            </li>
            <li>
                <b>Nesting:</b>
                <ul>
                    <li>Minimize nesting of selectors to keep your code clean and manageable.</li>
                </ul>
            </li>
        </ul>

        <p className="mt-5"><b>Modern Practices and Responsiveness:</b></p>
        <ul>
            <li>
                <b>Responsive Design:</b>
                <ul>
                    <li>Use media queries to adapt your styles for different screen sizes and devices. Ensure your website displays well across desktops, tablets, and mobile phones.</li>
                </ul>
            </li>
            <li>
                <b>Modern CSS Features:</b>
                <ul>
                    <li>Leverage features like flexbox and grid layout for more efficient and flexible layouts.</li>
                </ul>
            </li>
            <li>
                <b>Browser Compatibility:</b>
                <ul>
                    <li>Test your styles across different browsers to ensure consistent rendering.</li>
                </ul>
            </li>
        </ul>


        <p><b>Additional Tips:</b></p>
        <ul>
            <li>
                <b>Reset Styles:</b>
                <ul>
                    <li>Consider using a CSS reset stylesheet to normalize browser defaults and provide a consistent baseline for styling.</li>
                </ul>
            </li>
            <li>
                <b>Color Palettes:</b>
                <ul>
                    <li>Define a limited color palette for your website and use variables or CSS custom properties for consistent color usage.</li>
                </ul>
            </li>
            <li>
                <b>Documentation:</b>
                <ul>
                    <li>Comment your code to explain complex logic or non-obvious choices.</li>
                </ul>
            </li>
        </ul>

        <p className="mt-5">By following these best practices, you'll write cleaner, more maintainable, and future-proof CSS code that will make your web projects a breeze to develop and maintain.  Remember, these are just general guidelines, and the specific practices you use will depend on the complexity of your project.</p>


        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/OptimizingCss'>Previous</Link>
            {/* <Link  className="px-3" to='/TutorialCss/ImportantNotes'>Next</Link> */}
        </div>
    </div>
  )
}

export default ImportantNotes