import { Link } from "react-router-dom";
import { useEffect } from "react";

function BoxModel() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='px-3'>
        <h3>Structure of the CSS box model</h3>
        <br /><br /><br />

        <p className="text-start">The CSS box model is a fundamental concept that describes how elements in HTML are rendered on a web page. It consists of several layers or components that surround the content of an element. The structure of the CSS box model includes the following layers:</p>
        <br />

        <p className="text-start">1.<b>Content</b>: This is the innermost part of the box and contains the actual content of the element, such as text, images, or other media.</p>
        <p className="text-start">2.<b>Padding</b>: The padding is the space between the content and the border of the element. It provides cushioning or space around the content, helping to separate it from the border.</p>
        <p className="text-start">3.<b>Border</b>: The border surrounds the padding and content of the element. It can have a specified width, style, and color, and it acts as a visual boundary for the element.</p>
        <p className="text-start">4.<b>Margin</b>: The margin is the space outside the border of the element. It creates separation between the element and other elements in the layout. Margin is transparent, meaning it doesn't have a background color or border by default.</p>

        <br /><br />
        <p className="text-start">Here's a visual representation of the CSS box model:</p>

        <div>
            <img src="https://i.pinimg.com/originals/b2/65/31/b265310e438d81a2a20220ad119b53a3.png" alt="" />
        </div>

        <p className="text-start mt-5">When you specify properties like width, height, padding, border, and margin for an element in CSS, you are essentially controlling the dimensions and spacing of these layers within the box model.</p>
        <br />
        <p className="text-start">For example, if you set the width of an element to 200 pixels, it will include the content width, padding, and border. If you add margin to this element, the total width of the element including margin will be greater than 200 pixels.</p>

        <br />
        <p className="text-start">Understanding the CSS box model is essential for properly positioning and styling elements on a web page. It helps ensure consistent spacing and layout across different elements.</p>

        {/* btn  */}
        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/Floating'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/ComponentBox'>Next</Link>
    </div>
  )
}

export default BoxModel