import { useEffect } from "react";
import { Link } from "react-router-dom";

function Function() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
  return (
    <div className='text-start p-3'>
        <h5>Function</h5>
        <br /><br /><br />

        <p>
        Defining functions in JavaScript follows the same principles as in other scripting languages. Here's how it works in JavaScript:
        </p>
        <br /><br />
        <p><b>Function Definition:</b></p>


        <ol>
            <li className="mt-3"><b>Function keyword:</b> Similar to Python, JavaScript uses the function keyword to declare a <span className="light">function</span>.</li>
            <li className="mt-3"><b>Function name:</b> Choose a meaningful name that reflects the function's purpose (e.g., <span className="light">calculateArea</span>, span.lightdisplayMessage).</li>
            <li className="mt-3"><b>Parameters (optional):</b> Parameters are listed within parentheses after the function name, separated by commas. They act as inputs to the function.</li>
            <li className="mt-3"><b>Function body:</b> The code block enclosed in curly braces {} defines the actions the function performs. This is where you write the logic to achieve the function's task.</li>
            <li className="mt-3">b
            Return statement (optional): A function can optionally use a return statement to send a value back to the code that called the function.</li>
        </ol>

        <br /><br />

        <p>Here's an example of a JavaScript function that calculates the area of a rectangle:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-info">function</span> <span className="text-warning">calculateArea</span> ( length, width) {`{`}</p>
            <p className="mx-4"><span className="text-secondary">// Function body: calculate and return the area</span></p>
            <p className="mx-4"><span className="text-info">return</span> length * width ;</p>
            <p>{`}`}</p>
        </div>

        <br /><br /><br />
        <p>In this example, the function <span className="light">calculateArea</span> takes two parameters, <span className="light">length</span> and width. Inside the function body, it multiplies these values and returns the area using the <span className="light">return</span> statement. The script then calls the function with specific values for length and width, storing the returned area in a variable.</p>

        <br /><br /><br />
        <p>JavaScript also offers another way to define functions using function expressions, but the core concept remains the same: creating reusable blocks of code that perform specific tasks.</p>


        <br /><br />
        <p>By effectively using functions, you can make your JavaScript code more organized, maintainable, and easier to understand.</p>






        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/ForEach' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Parameters' >Next</Link>
        </div>
    </div>
  )
}

export default Function