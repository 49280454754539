import {useEffect, useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// list links
import List_menu_Js from './Components_JS/Links/Data_Links';
import BtnLikn from './Components_JS/Btn_Likn';
import Helmet from 'react-helmet';

// icon from react-icons.
import { MdOutlineMenuOpen } from "react-icons/md";

function Tutorial_JS() {
    const [btnList , setBtnList] = useState(false);
    const [btnBorder, setBtnBorder] = useState('noneBorderBtn');
    const [listSide, setListSide] = useState('hidden-side-beurger');
    
    const [ListOfMenus] = useState(List_menu_Js);
    
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/Tutorial_JS/Introduction_js')
    }, []);

    const BtnList_handler = () => {
        if( btnList == false) {
          setBtnList(true);
          setListSide('visible-side-beurger');
          setBtnBorder('BorderBtn')
        }
        else if(btnList == true) {
          setBtnList(false);
          setListSide('hidden-side-beurger');
          setBtnBorder('noneBorderBtn')
        }
    }

  return (
    <div className=''>
        <Row className='ContainerTutorialPages'>
        <Helmet>
        <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        </Helmet>
        <h3 className=''>JavaScript</h3>
            <Col lg={2}>
              {/* Adds */}
              
            </Col>
            <Col lg={8} className='main'>
            <div className={`${listSide} side-menu`}>

<div className="container-list">
{
  ListOfMenus.map(menu => <BtnLikn key={menu.id} {...menu} />)
}
</div>

</div>
            <button onClick={BtnList_handler} className={`bt-list ${btnBorder}`} style={{width:'100px'}}>{listSide  && <MdOutlineMenuOpen /> }List</button>
                <Row>
                    <Col lg={3} className=''>
                        <div className='fixed-list'>
                            <div className="container-link-tutorial">
                            {
                                ListOfMenus.map(menu => <BtnLikn key={menu.id} {...menu}/>)
                            }
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} className='px-5'>
                        <Outlet />
                    </Col>
                </Row>
            </Col>
            <Col lg={2}>
              {/* Adds */}
              
            </Col>
        </Row>
    </div>
  )
}

export default Tutorial_JS