import { Link } from "react-router-dom"
import { useEffect } from "react";

function AddImage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const BackgroundImage = {
        maxWidth: '500px',
        height:'200px',
        marginBottom: '50px',
    }

  return (
    <div className='text-start p-3'>
        <h3 className='text-center'>Adding image</h3>
        <br /><br /><br />

        <p>There are two main ways to add images to a web page: using HTML and using CSS.</p>
        <h5 className='mt-5'>HTML:</h5>
        <p>The most common way to add an image is with the <span className="light">{`<img>`}</span> element in HTML. This is an empty element, meaning it doesn't have a closing tag. Here's the basic structure:</p>

        <div className="mt-3 bg-dark text-light p-3">
            <h5>HTML</h5>
            <br />
            <p><span className='text-warning'>{`<img src`}</span>=<span className="text-info">"path/to/your/image.jpg" </span> <span className="text-warning"> alt</span>=<span className="text-info">"Text describing the image" </span><span className="text-warning">{`>`}</span></p>
                <p className="mx-5">Or</p>
            <p><span className='text-warning'>{`<img src`}</span>=<span className="text-info"> "your link " </span> <span className="text-warning"> alt</span>=<span className="text-info">"Text describing the image" </span><span className="text-warning">{`>`}</span></p>


        </div>

        <ul className="mt-5">
            <li><span className="light">src</span>: This attribute specifies the location of the image file. The path can be relative (e.g., "images/mypic.png") or absolute (a full URL).</li>
            <li><span className="light">alt</span>: This attribute is crucial for accessibility. It provides alternative text for the image, which is displayed if the image can't be loaded or for screen readers.</li>
        </ul>

        <h5 className="mt-5">CSS</h5>
        <p>You can also use CSS to set an image as the background of an element. This is useful when you want the image to be more integrated into the layout of your page. Here's an example:</p>
        <div className="mt-3 bg-dark text-light p-3">
            <h5>CSS</h5>
            <br />
            <p><span className="text-danger">body</span>{`{`}</p>
                <p className="mx-4">background-image: <span className="text-info">url("path/to/your/image.jpg");</span></p>
                <p className="mx-4">background-repeat: no-repeat; <span className="text-secondary">/* Optional: control how the image repeats */</span></p>
                <p className="mx-4">background-position: center; <span className="text-secondary">/* Optional: control the position of the image */</span></p>
            <p>{`}`}</p>

        </div>
        <p className="mt-3">In this case, the <span className="light">background-image</span> property specifies the image file. You can also use other properties like <span className="light">background-repeat</span> and <span className="light">background-position</span> to control how the image is displayed on the background.</p>

        <h5 className="mt-5">Here are some additional points to consider:</h5>
        <ul>
            <li>It's generally recommended to use the <span className="light">{`<img>`}</span> element for images that are content within the page itself, and CSS background images for decorative elements.</li>
            <li>Always include the <span className="light">alt</span> attribute for <span className="light">{`<img>`}</span> elements to improve accessibility.</li>
            <li>Make sure your image sizes are optimized for web use to improve page loading speed.Make sure your image sizes are optimized for web use to improve page loading speed.</li>
        </ul>

        <div style={BackgroundImage} >
            <div>
                <img style={{width:'100%'}} src="https://images.pexels.com/photos/440731/pexels-photo-440731.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            </div>
        </div>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Gradients'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/SettingImage'>Next</Link>
        </div>
    </div>
  )
}

export default AddImage