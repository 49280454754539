import { Link } from "react-router-dom";
import { useEffect } from "react";

function ForLoop() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>For loop</h5>
        <br /><br />
        <p>In JavaScript, a for loop is a control flow statement that allows you to repeatedly execute a block of code a certain number of times. It consists of three parts: initialization, condition, and iteration.</p>

        <p className="mt-4">Here's the basic syntax:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">for </span>(<span className="text-info">initialization</span> ; <span className="text-info">condition</span> ; <span className="text-info">iteration</span> ) {`{`}</p>
            <p className="mx-4 text-secondary">// code to be executed</p>
            <p>{`}`}</p>
        </div>

        <br />
        <ul>
            <li><b>Initialization:</b> This part is executed before the loop starts. It usually initializes a counter variable.</li>
            <li><b>Condition:</b> This part specifies the condition for continuing the loop. If the condition evaluates to <span className="light">true</span>, the loop continues; if it evaluates to <span className="light">false</span>, the loop terminates.</li>
            <li><b>Iteration:</b> This part is executed at the end of each loop iteration. It typically increments or decrements the counter variable.</li>
        </ul>

        <p className="mt-5">Here's an example of a for loop that prints numbers from 1 to 5:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">for </span>(<span className="text-info">let</span> i = <span className="text-danger">1</span> ; i {`<=`} <span className="text-danger">5</span> ; <span className="text-info"> i++</span> ) {`{`}</p>
            <p className="mx-4"><span className="text-danger">console.log</span>( i )</p>
            <p>{`}`}</p>

        </div>

            <p className="mt-2">This loop initializes i to 1, checks if i is less than or equal to 5, executes the loop body (which logs the value of i), and then increments i by 1 on each iteration. Once i becomes greater than 5, the loop terminates.</p>



        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/LogicalOperators' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/While' >Next</Link>
        </div>

    </div>
  )
}

export default ForLoop