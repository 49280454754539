import { Link } from "react-router-dom"
import { useEffect } from "react";

function Width() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3'>
        <h3>Width in css</h3>
        <br/>
        <br/>
        <p className="text-start">In CSS, the width property is used to specify the width of an element. It defines the horizontal size of an element's content area. The width property can be applied to various types of elements including block-level elements like <span className="light">{`<div>`}</span>, <span className="light">{`<p>`}</span>, and <span className="light">{`<section>`}</span>, as well as inline-level elements like <span className="light">{`<span>`}</span>.</p>

        <p className="text-start mt-5">The width property accepts different types of values:</p>

        <p className="text-start mt-2"><b>1. Fixed Width</b>: You can specify a fixed width in pixels (px), points (pt), inches (in), centimeters (cm), millimeters (mm), or other absolute units. For example:</p>

        <div className="bg-dark p-3 text-start text-light">
            <h5>css</h5>
            <br />
            <p className="text-start"><span className="text-danger">width</span>: <span className="text-warning">300px</span>;</p>
        </div>
        <p className="text-start mt-5"><b>2. Percentage Width</b>: You can specify a width as a percentage of the containing element's width. For example, if you want an element to take up 50% of its parent element's width, you can use:</p>
        <div className="bg-dark p-3 text-start text-light">
            <h5>css</h5>
            <br />
            <p className="text-start"><span className="text-danger">width</span>: <span className="text-warning">50%</span>;</p>
        </div>

        <p className="text-start mt-5"><b>3. Auto Width</b>: The auto value allows the browser to automatically determine the width based on the content and the available space. For block-level elements, this typically means that the element will expand to fill the available width of its containing block, unless constrained by other CSS properties or content.</p>

        <p className="text-start mt-5"><b>4. Fit Content Width</b>: The fit-content value allows the element to expand to fit its content, but it won't exceed a specified maximum width if provided. For example:</p>
        <div className="bg-dark p-3 text-start text-light">
            <h5>css</h5>
            <br />
            <p className="text-start"><span className="text-danger">width</span>: <span className="text-info">fit-content</span>(<span className="text-warning">200px</span>);</p>
        </div>

        <p className="text-start mt-5"><b>5. Max Width</b>: You can also set a maximum width for an element using the max-width property. This property limits the maximum width that an element can have. For example:</p>

        <div className="bg-dark p-3 text-start text-light">
            <h5>css</h5>
            <br />
            <p className="text-start"><span className="text-danger">width</span>: <span className="text-warning">500px</span>;</p>
        </div>


        <p className="text-start mt-5">Using the width property along with other CSS properties like max-width, min-width, and box-sizing, you can control the size and layout of elements on a webpage, making it responsive and visually appealing.






</p>




        {/* btn  */}
        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/TextEffects'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/Position'>Next</Link>
    </div>
  )
}

export default Width