import { useState } from 'react';
import styled from 'styled-components';
import SettingIcon from '../../../assets/Icons/Samples/Gradient/setting.png';
import SaveIcon from '../../../assets/Icons/Samples/Gradient/save.png';
import CloseBtnIcon from '../../../assets/Icons/Samples/Gradient/close.png';
import './CircleSlider.css';
import RadioButtonGroup from './RadioButtonGroup'; // Import RadioButtonGroup component

const Card = styled.div`
  width: 320px;
  height: 230px;
  margin: 20px 12px;
  padding: 5px;
  position: relative;
  border: solid 1px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 10px 5px #ccc;
`;
const Gradient = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;
const Seting = styled.div`
  width: 100px;
  height: 49px;
  background-color: #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 12px 0 5px 0;
`;
const ContainerSeting = styled.div`
  width: 90%;
  height: 40px;
  border-radius: 7px 0 5px 0;
  border: solid 1px #ccc;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: -5px -5px 10px #ccc, 5px 5px 10px #ccc;
`;
const Image = styled.img`
  width: 20px;
  border: none;
  marginTop: 6px;
  cursor: pointer;
  @media only screen and (max-width: 666px) {
    width: 15px;
    margin-left: 5px;
  }
`;
const ModalContainer = styled.div`
  position: fixed;
  top: 13%;
  left: 10%;
  width: 80%;
  height: 80%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 2px #0c0c0c;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
`;
const CopiedAlarm = styled.div`
  display: flex:
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 2px #0c0c0c;
  letter-spacing: normal;

  animation: breatheText 0.5s linear;

  @keyframes breatheText {
    0% {
      letter-spacing: normal;
    }
    50% {
      letter-spacing: 2px; /* Wider spacing at midpoint */
    }
    100% {
      letter-spacing: normal;
    }
  }
`;
// Transform shape of Radial-gradient

const GradiantTest = ({
  styleGradiant,
  degNumber,
  colorType,
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [degValue, setDegValue] = useState(degNumber);
  const [selectedOption, setSelectedOption] = useState('');

  const ContainerCardGradiant = styled.div`
    position: relative;
  `;

  function handleChange(event) {
    setSelectedOption(event.target.value);
  }

  const copyGradientStyle = () => {
    navigator.clipboard.writeText(
      `background: ${styleGradiant}(${degValue}deg,${colorType}`
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const openModal = () => {
    setIsShowModal(true);
  };

  const closeModal = () => {
    setIsShowModal(false);
  };

  const changeDegree = (e) => {
    setDegValue(e.target.value);
  };

  const styleDirectional = {
    '-webkit-transform': `rotate(36deg)`,
    transform: `rotate(36deg)`,
};
const Directional = styled.div`
'-webkit-transform': rotate(${degValue}deg);
    transform: rotate(${degValue}deg);
`

  return (
    <ContainerCardGradiant>
      <div className='position-relative'>
        <Card className='d-flex  align-items-start '>
          <Gradient style={{ background: `${styleGradiant}(${degValue}deg,${colorType}` }}>
            {isCopied && <CopiedAlarm>Copied</CopiedAlarm>}
          </Gradient>
          <div className='d-flex justify-content-between pt-2'>
            <Seting>
              <ContainerSeting>
                <button onClick={openModal} className='px-2' style={{ background: 'transparent', border: 'none' }}><Image src={SettingIcon} /></button>
                <button className='mx-2 mt-1 px-1' style={{ background: 'transparent', border: 'none', }}><Image src={SaveIcon} alt="save" onClick={copyGradientStyle} /></button>
              </ContainerSeting>
            </Seting>
          </div>
        </Card>
      </div>
      {isShowModal && (
        <ModalContainer style={{ background: `${styleGradiant}(${degValue}deg,${colorType}` }}>
          <div className='pt-2 d-flex justify-content-end px-2' onClick={closeModal}><img style={{ cursor: 'pointer', backgroundColor: '#000', borderRadius: '50%', border: 'solid 1px' }} src={CloseBtnIcon} alt='close' /></div>
          <div>
            {isCopied && <CopiedAlarm>Copied</CopiedAlarm>}
            <p></p>
            <div className=' d-flex flex-column  justify-content-center align-items-center bg-dark rounded'>
              <div className='w-75'>
                <div className='input-container  w-100'>
                  <p className='text-light'>
                    {degValue} deg
                  </p>
                  <div className=''>
                    <div className='inputRange  text-light d-flex align-items-center'>
                      <span>0</span><input className='mx-1' onChange={changeDegree} type="range" value={degValue} min='0' max='360' /><span>360</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex w-25 justify-content-around align-items-center'>
                  <span className='Shape text-light'>{selectedOption == 'linear-gradient'? <Directional className='Directional'></Directional> :  <div className='Ring'></div>}</span>
                <div className="container-radio-button ">
                  <RadioButtonGroup selectedOption={selectedOption} handleChange={handleChange} />
                </div>
                <div className='mt-2'>
                  <p className='btn border text-light' onClick={() => copyGradientStyle()}>Copy</p>
                </div>
              </div>
            </div>
          </div>
        </ModalContainer>
      )}
    </ContainerCardGradiant>
  );
};

export default GradiantTest;
