import { Link } from "react-router-dom";
import { useEffect } from "react";

function CssVariable () {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='p-3 text-start'>
      <h3 className='text-center'>SCSS - LESS</h3>
      <br /><br /><br />
      <ul>
        <li><a href="#Variables">Variables</a></li>
        <li><a href="#Functions">Functions</a></li>
        <li><a href="#Mixins">Mixins</a></li>
      </ul>
      <h5 className="mt-5">Organizing CSS with Variables, Functions, and Mixins</h5>
      <span id="Variables"></span>
      <p>CSS preprocessors like Sass and LESS offer powerful tools to keep your styles organized and maintainable. Here's how variables, functions, and mixins can help:</p>

      <ol className="mt-5">
        <li><b>Variables</b>:</li>
        <ul className="mt-3">
          <li>Define a single source of truth for frequently used values like colors, fonts, and spacing.</li>
          <li>Update styles globally by changing the variable definition.</li>

          <h6 className="mt-5">Example (Sass):</h6>

          <div className="bg-dark text-light p-3">
            <h6>SCSS</h6>
            <br />

            <p ><span className="text-warning">$primary-color</span>: <span className="text-warning">#007bff</span>;</p>
            <p ><span className="text-warning">$secondary-color</span>: <span className="text-warning">#ffc107</span>;</p>
            <br />
            <p><span className="text-warning">body</span> {`{`}</p>
            <p className="mx-3"><span>background-color:</span><span className="text-warning"> $primary-color</span>;</p>
            <p className="mx-3"><span>color:</span><span className="text-warning"> $secondary-color</span>;</p>
            <p>{`}`}</p>

            <br />
            <p><span className="text-danger">.button</span> {`{`}</p>
            <p className="mx-3"><span>background-color:</span><span className="text-warning"> $primary-color</span>;</p>
            <p className="mx-3"><span>border-color:</span><span className="text-warning"> $secondary-color</span>;</p>
            <p>{`}`}</p>


          <span id="Functions"></span>
          </div>
        </ul>
        <br />
        <li className="mt-5"><b>Functions</b>:
          <ul>
            <li>Perform calculations and manipulations within your styles.</li>
            <li>Create reusable logic for complex styling needs.</li>
            <h6 className="mt-5">Example (LESS):</h6>
            <div className="bg-dark text-light p-3">
              <h5>Less</h5>
              <br />
              <p><span className="text-warning">darken</span>(color , amount) {`{`}</p>
                <p className="mx-4"><span className="text-warning">return adjust-color</span>(color , -$amount);</p>
              <p>{`}`}</p>
              <br />
              <p><span className="text-danger">.text-highlight </span> {`{`}</p>
                <p className="mx-4">color: darken (<span className="text-warning">#333 , 10%</span>)</p>
              <p>{`}`}</p>

            <span id="Mixins"></span>
            </div>
          </ul>
        </li>
        <br />
        <li className="mt-5">
          <b>Mixins:</b>
          <ul>
            <li>Create reusable code blocks for styles you use repeatedly.</li>
            <li>Improve code readability and reduce duplication.</li>
            <li>Optionally accept arguments for customization.</li>

            <h6 className="mt-5">Example (Sass):</h6>
            <div className="bg-dark text-light p-3">
              <h6>SCSS</h6>
              <br />
              <p><span className="text-warning">@mixin</span> button-style (<span className="text-warning">$color, $hover-color</span>) {`{`}</p>
                <p className="mx-4">background-color: <span className="text-warning">$color</span>;</p>
                <p className="mx-4">color: white;</p>
                <p className="mx-4">padding: <span className="text-warning">10px 20px</span>;</p>
                <p className="mx-4">border: none;</p>
                <p className="mx-4">border-radius: <span className="text-warning">4px</span>;</p>
                
                <br />

                <p className="mx-4">&:<span className="text-warning">hover </span>{`{`}</p>
                <p className="mx-5">background-color: <span className="text-warning">$hover-color</span>;</p>
                <p className="mx-4">{`}`}</p>
              <p>{`}`}</p>

                <br />
              
              <p><span className="text-danger">.primary-button</span> {`{`}</p>
                <p className="mx-4"><span className="text-warning"> @include</span> button-style( <span className="text-info">$primary-color</span>, darken ( <span className="textinfo">$primary-color, 10%</span>))</p>
              <p>{`}`}</p>

              <br />

              <p><span className="text-danger">.secondary-button</span> {`{`}</p>
                <p className="mx-4"><span className="text-warning"> @include</span> button-style( <span className="text-info">$primary-color</span>, lighten ( <span className="textinfo">$secondary-color, 20%</span>))</p>
              <p>{`}`}</p>


            </div>
          </ul>
        </li>
      </ol>
      <p>These are just a few examples. With variables, functions, and mixins, you can achieve a high level of organization and maintainability in your CSS codebase.</p>

      <p className="mt-5">bHere are some additional benefits:</p>

      <ul>
        <li><b>Reduced code duplication</b>: Less code means less chance of errors and easier maintenance.</li>
        <li><b>Improved readability</b>: Clear variable names and well-defined mixins make code easier to understand.</li>
        <li><b>Theming</b>: Easily switch between different color schemes or design styles by modifying variables.</li>
      </ul>

      <p className="mt-5">By leveraging these features of CSS preprocessors, you can write cleaner, more efficient, and future-proof stylesheets.</p>



        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Introduction'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/CompilingPreprocessors'>Next</Link>
        </div>
    </div>
  )
}

export default CssVariable