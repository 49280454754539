import MainOne from "./Components/MainOne";
import MainTwo from "./Components/MainTwo";
import MainThree from "./Components/MainThree";
import MainFour from "./Components/MainFour";

import "./Main.css";

const Main = () => {
  return (
    <>
      <div className="Main ">
        <MainOne />
        <MainTwo />
        {/* <MainThree /> */}
        {/* <MainFour /> */}
      </div>
    </>
  );
};

export default Main;
