import {Link} from 'react-router-dom';
import { useEffect } from 'react';

function StructureofACSSfile() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <div className='p-3'>
        <h3>Structure of a CSS file</h3>
        <h5 className='text-start mt-5'>The structure of a CSS file follows two main levels:</h5>
        <p className='text-start mt-5'>1. <b>Rules</b>: These are the fundamental building blocks of CSS and define how HTML elements will be styled. A rule consists of two parts:</p>
        <ul className='text-start'>
            <li><b>Selector</b>: This specifies the HTML element(s) that the rule applies to. Selectors can target elements by tag name, class name, ID, or a combination of these.</li>
            <li><b>Declaration</b>: This defines the specific styles that will be applied to the selected element(s). It consists of a property (e.g., font-size, color, background-color) and a value (e.g., 16px, blue, #f0f0f0) separated by a colon (:).</li>
        </ul>

        <p className='text-start mt-5'>Organization:  While there's no strict requirement, for readability and maintainability, it's recommended to organize your CSS in a logical way. Here are some common approaches:</p>
        <ul className='text-start'>
            <li><b>Grouping by element type</b>: Place all styles for headings (h1, h2, etc.) together, followed by styles for paragraphs (p), and so on.</li>
            <li><b>Grouping by class or ID</b>: If you have styles specific to classes or IDs, group them together.</li>
        </ul>

        <p className='mt-5'>Here's a basic example:</p>
        <div className='bg-dark text-light text-start p-3'>
            <p className='text-secondary'>{`/* Targetting all h1 elements */`}</p>
            <p>{`h1 {`}</p>
            <p className='px-4'>{`font-size: 2em;`}</p>
            <p className='px-4'>{`color: red;`}</p>
            <p>{`}`}</p>
            <p className='mt-5 text-secondary'>{`/* Targetting all elements with the class "important" */`}</p>
            <p>{`.important {`}</p>
            <p className='px-4'>{`font-weight: bold;`}</p>
            <p className='px-4'>{`border: 1px solid black;`}</p>
            <p>{`}`}</p>
        </div>


          <p className='text-start mt-5'>In larger projects, you might consider more advanced techniques like:</p>
          <ul className='text-start'>
            <li><b>CSS preprocessors (SASS, LESS)</b>: These extend CSS with features like variables, mixins, and nesting, making it more powerful and manageable for complex styles.</li>
            <li><b>Separation of concerns</b>: Break down your styles into multiple CSS files for different parts of your website (e.g., layout, typography, components).</li>
          </ul>
          <p className='text-start mt-3'>Remember, the key is to keep your CSS organized, readable, and maintainable as your project grows.</p>
        <br/>
        <br/>
        <Link  className='p-2' to='/TutorialCss/HistoryandpurposeofCSS'>Previus</Link>
        <Link  className='p-2' to='/TutorialCss/CSSsyntax'>Next</Link>
    </div>
  )
}

export default StructureofACSSfile