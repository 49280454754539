import { Link } from "react-router-dom";
import { useEffect } from "react";

function ForEach() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>forEach</h5>
        <br /><br />
        
        <p>for each is not a built-in construct in JavaScript. However, you might be referring to forEach, which is a method available on arrays in JavaScript.</p>


        <p className="mt-5">The forEach method is used to iterate over each element in an array and execute a provided function once for each array element. Here's a basic example:</p>

        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p>array.<span className="text-warning">forEach</span>{`(`}<span className="text-info">function</span>(element) {`{`}</p>
            <p className="mx-4 "><span className="text-danger">consol.log</span>(element)</p>
            <p>{`});`}</p>
        </div>

        <p className="mt-3">This will output:</p>
        <div className="Box">
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p>4</p>
            <p>5</p>
        </div>

        <p className="mt-5">The forEach method takes a callback function as its argument, and this function is called with three arguments: the current element being processed in the array, the index of that element, and the array itself. However, it's common to see just the first argument (element) being used in practice.</p>


        <p className="mt-3">Here's another example using the index parameter:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <p><span className="text-warning">const</span>arry = {`[`} <span className="text-info">'a', 'b', 'c'</span> {`]`};</p>
            <p><array className="span text-danger">forEach</array>{`(`} <span className="text-info">function</span>( element, index ) {`{`}</p>
            <p className="mx-4"><span className="text-danger">console.log</span>(<span className="success">`Index </span><span className="text-info">${`{index}`}</span>: <span className="text-info">${`{element}`}</span>`);</p>
            <p>{`})`};</p>
        </div>



        <p className="mt-3">This will output:</p>

        <div className="Box">
            <p><span className="text-danger">Index 0</span> <span className="text-success">a</span></p>
            <p><span className="text-danger">Index 1:</span> <span className="text-success">b</span></p>
            <p><span className="text-danger">Index 2:</span> <span className="text-success">c</span></p>
        </div>


        <p className="mt-5">Using forEach is a concise way to iterate over arrays compared to traditional for loops. It also helps to make the code more readable and maintainable.</p>


        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/DoWhile' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Function' >Next</Link>
        </div>
    </div>
  )
}

export default ForEach