import {useState, useEffect} from 'react';
import './spinerStyle.css';
import Styled from 'styled-components';

const Box = Styled.div`
    width: 100px;
    height:100px;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    
`
const Div = Styled.div`
    border: solid 1px #ccc;
    border-radius: 5px;
    margin: 30px;
    padding-top: 2px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
`
const Span = Styled.span`
    color: #fff;
`

const Modal = Styled.div`
    color: #fff;
    position: absolute;
    background-color: rgb(33,37,41);
    padding-top: 5px;
    
    top: 8%;
    left: 17.5%;
    width: 65%;
    min-height: 90vh;
    z-index: 3;
    display: flex;
    flex-direction: column;
    
`
const Section = Styled.div`
width: 100%;
height: 100%;
border-radius: 5px;
display: flex;
justify-content: center;
gap: 4px;
// overflow: auto;

div {
     width: 100%;
     height: auto;
     padding: 5px 0 15px 0;
     border: solid 1px #ccc;
     border-radius: 5px;
}
`
const Button_close = Styled.button`
color: #fff;
    &:hover {
        background-color: yellow;
        color: black;
    }
`

const SpinerComponent = () => {
    const [modal_1, setModal_1] = useState(false);
    const [modal_2, setModal_2] = useState(false);
    const [modal_3, setModal_3] = useState(false);
    const [modal_4, setModal_4] = useState(false);
    const [modal_5, setModal_5] = useState(false);
    const [modal_6, setModal_6] = useState(false);
    const [modal_7, setModal_7] = useState(false);
    const [modal_8, setModal_8] = useState(false);
    const [modal_9, setModal_9] = useState(false);
    const [modal_10, setModal_10] = useState(false);
    const [modal_11, setModal_11] = useState(false);
    const [modal_12, setModal_12] = useState(false);
    const [modal_13, setModal_13] = useState(false);
    const [modal_14, setModal_14] = useState(false);
    const [modal_15, setModal_15] = useState(false);
    const [modal_16, setModal_16] = useState(false);
    const [modal_17, setModal_17] = useState(false);
    const [modal_18, setModal_18] = useState(false);
    const [modal_19, setModal_19] = useState(false);
    const [modal_20, setModal_20] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // اسکرول به بالای صفحه
    }, [
        modal_1,modal_2,modal_3,modal_4,modal_5,modal_6,modal_7,modal_8,modal_9,modal_10,modal_11,modal_12,modal_13,modal_14,modal_15,modal_16,modal_17,modal_18,modal_19,modal_20
    ])
    

    const textDivTag = `<div class='spiner'></div>`;
    // 

    const textTagChildStart = `<div className='spiners'>`
    const textTagChildEnd = `</div>`

    const textChildStart = `<div className='child-spiner'>`
    const textChildEnd = `</div>`

  return (
    <div className='pt-5'>
        <div className='pt-5 '>
            <h1 className='text-light'>Spinners</h1>

            <div className='w-100 d-flex flex-wrap justify-content-center align-items-center'>
                {/* SPINER 1 */}
                <Div>
                    <Span >1</Span>
                    <Box onClick={() => setModal_1(true)}>
                        <div className='spiners spiner-1'>
                        </div>
                    </Box>
                    { modal_1 && (
                            <Modal>
                                <p>Spiner 1</p>
                            <Button_close className='btn border' onClick={() => setModal_1('')}>Close</Button_close>
                            <Section>
                                <div className=''>
                                    <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 30px</p>
                                        <p className='px-5'>height: 30px</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border: groove 31px #fffafa;</p>
                                        <p className='px-5'>animation: spiner 1.4s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>
                                        <p className="px-5">@keyframes spiner : &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                border: groove 31px #fffafa;
                                                animation: spiner 1.4s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                        `)
                                   }}>Copy</span>
                                </div>
                            </Section>
                            
                            </Modal>
                    ) }
                    
                </Div>
                    {/* SPINER 2 */}
                <Div>
                    <Span>2</Span>
                    <Box onClick={() => setModal_2(true)}>
                        <div className='spiners spiner-2'>
                        </div>
                    </Box>
                    { modal_2 && (
                            <Modal>
                                <p>Spiner 2</p>
                                <Button_close className='btn border ' onClick={() => setModal_2()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 30px</p>
                                        <p className='px-5'>height: 30px</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'> border: dashed 31px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.4s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>
                                        <p className='px-5'>@keyframes spiner &#123;</p>
                                        <p className='px-5'>0% &#123;transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className='px-5'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                background: transparent ;
                                                border: dashed 31px #ffffff;
                                                animation: spiner 1.4s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg);}
                                            }
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 3 */}
                <Div>
                    <Span >3</Span>
                    <Box onClick={() => setModal_3(true)}>
                        <div className='spiners spiner-3'>
                        </div>
                    </Box>
                    { modal_3 && (
                            <Modal>
                                <p>Spiner 3</p>
                            <Button_close className='btn border' onClick={() => setModal_3('')}>Close</Button_close>
                            <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 70px;</p>
                                        <p className='px-5'>height: 70px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border: dashed 11px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 2.4s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 70px;
                                                height: 70px;
                                                border-radius: 50%;
                                                border: dashed 11px #ffffff;
                                                animation: spiner 2.4s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                            </Section>
                            
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 4 */}
                <Div>
                    <Span>4</Span>
                    <Box onClick={() => setModal_4(true)}>
                        <div className='spiners spiner-4'>
                        </div>
                    </Box>
                    { modal_4 && (
                            <Modal>
                                <p>Spiner 4</p>
                            <Button_close className='btn border' onClick={() => setModal_4()}>Close</Button_close>
                            <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 50px;</p>
                                        <p className='px-5'>height: 50px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dashed 20px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.7s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>
                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 50px;
                                                height: 50px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dashed 20px #ffffff;
                                                animation: spiner 1.7s linear infinite;
                                            }

                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                            </Section>
                            
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 5 */}
                <Div>
                    <Span>5</Span>
                    <Box onClick={() => setModal_5(true)}>
                        <div className='spiners spiner-5'>
                        </div>
                    </Box>
                    { modal_5 && (
                            <Modal>
                                <p>Spiner 5</p>
                            <Button_close className='btn border' onClick={() => setModal_5()}>Close</Button_close>
                            <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 40px;</p>
                                        <p className='px-5'>height: 40px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dashed 24px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.7s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 40px;
                                                height: 40px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dashed 24px #ffffff;
                                                animation: spiner 1.7s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                        `)
                                   }}>Copy</span>
                                </div>
                            </Section>
                            
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 6 */}
                <Div >
                    <Span>6</Span>
                    <Box onClick={() => setModal_6(true)}>
                        <div className='spiners spiner-6'>
                        </div>
                    </Box>
                    { modal_6 && (
                            <Modal>
                                <p>Spiner 6</p>
                                <Button_close className='btn border' onClick={() => setModal_6()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 50px</p>
                                        <p className='px-5'>height: 50px</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>/* The color of the border should be the same as the background color */</p>
                                        <p className='px-5'>border: dashed 32px  rgb(33,37,41)</p>
                                        <p className="px-5">background-color: rgb(235, 237, 240)</p>
                                        <p className='px-5'>animation: spiner 1.7s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 50px;
                                                height: 50px;
                                                border-radius: 50%;
                                                background: transparent;
                                                /* The color of the border should be the same as the background color */
                                                border: dashed 32px  rgb(33,37,41);
                                                background-color: rgb(235, 237, 240);
                                                animation: spiner 1.7s linear infinite;
                                                animation: spiner 1.7s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 7 */}
                <Div>
                    <Span>7</Span>
                    <Box onClick={() => setModal_7(true)}>
                        <div className='spiners spiner-7'>
                        </div>
                    </Box>
                    { modal_7 && (
                            <Modal>
                                <p>Spiner 7</p>
                                <Button_close className='btn border' onClick={() => setModal_7()}>Close</Button_close>
                                <Section>
                                <div>
                                    <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 30px</p>
                                        <p className='px-5'>height: 30px</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>/* The color of the border should be the same as the background color */</p>
                                        <p className='px-5'>border: dashed 32px  rgb(33,37,41);</p>
                                        <p className="px-5">background-color: rgb(235, 237, 240)</p>
                                        <p className='px-5'>animation: spiner 1.7s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                background: transparent;
                                                /* The color of the border should be the same as the background color */
                                                border: dashed 32px  rgb(33,37,41);
                                                background-color: rgb(235, 237, 240);
                                                animation: spiner 1.7s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 8 */}
                <Div>
                    <Span>8</Span>
                    <Box onClick={() => setModal_8(true)}>
                        <div className='spiners spiner-8'>
                        </div>
                    </Box>
                    { modal_8 && (
                            <Modal>
                                <p>Spiner 8</p>
                                <Button_close className='btn border' onClick={() => setModal_8()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 50px</p>
                                        <p className='px-5'>height: 50px</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: #ffffff;</p>
                                        <p className='px-5'>border: dashed 20px rgb(33,37,41)</p>
                                        <p className='px-5'>animation: spiner 2.2s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 50px;
                                                height: 50px;
                                                border-radius: 50%;
                                                background: #ffffff;
                                                border: dashed 20px rgb(33,37,41);
                                                animation: spiner 2.2s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 9 */}
                <Div>
                     <Span>9</Span>
                     <Box onClick={() => setModal_9(true)}>
                        <div className='spiners spiner-9'>
                        </div>
                     </Box>
                     { modal_9 && (
                            <Modal>
                                <p>Spiner 9</p>
                                <Button_close className='btn border' onClick={() => setModal_9()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 50px;</p>
                                        <p className='px-5'>height: 50px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border: dotted 30px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.2s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                            .spiner {
                                                /* Styles-css.com */
                                                width: 50px;
                                                height: 50px;
                                                border-radius: 50%;
                                                border: dotted 30px #ffffff;
                                                animation: spiner 1.2s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 10 */}
                <Div>
                    <Span>10</Span>
                    <Box onClick={() => setModal_10(true)}>
                        <div className='spiners spiner-10'>
                        </div>
                    </Box>
                    { modal_10 && (
                            <Modal>
                                <p>Spiner 10</p>
                                <Button_close className='btn border' onClick={() => setModal_10()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 1px;</p>
                                        <p className='px-5'>height: 1px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dotted 49px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.2s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 1px;
                                                height: 1px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dotted 49px #ffffff;
                                                animation: spiner 1.2s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 11 */}
                <Div>
                    <Span>11</Span>
                    <Box onClick={() => setModal_11(true)}>
                        <div className='spiners spiner-11'>
                        </div>
                    </Box>
                    { modal_11 && (
                            <Modal>
                                <p>Spiner 11</p>
                                <Button_close className='btn border' onClick={() => setModal_11()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 30px;</p>
                                        <p className='px-5'>height: 30px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dotted 38px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.2s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dotted 38px #ffffff;
                                                animation: spiner 1.2s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 12 */}
                <Div>
                    <Span>12</Span>
                    <Box onClick={() => setModal_12(true)}>
                        <div className='spiners spiner-12'>
                        </div>
                    </Box>
                    { modal_12 && (
                            <Modal>
                                <p>Spiner 12</p>
                                <Button_close className='btn border' onClick={() => setModal_12()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 60px;</p>
                                        <p className='px-5'>height: 60px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dotted 25px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 1.5s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 60px;
                                                height: 60px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dotted 25px #ffffff;
                                                animation: spiner 1.5s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 13 */}
                <Div>
                    <Span>13</Span>
                    <Box onClick={() => setModal_13(true)}>
                        <div className='spiners spiner-13'>
                        </div>
                    </Box>
                    { modal_13 && (
                            <Modal>
                                <p>Spiner 13</p>
                                <Button_close className='btn border' onClick={() => setModal_13()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-5'>.spiner &#123;</p>
                                        <p className='px-5'>width: 60px;</p>
                                        <p className='px-5'>height: 60px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent;</p>
                                        <p className='px-5'>border: dotted 18px #ffffff;</p>
                                        <p className='px-5'>animation: spiner 2s linear infinite;</p>
                                        <p className='px-5'>&#125;</p>

                                        <p className="px-5">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-5">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 60px;
                                                height: 60px;
                                                border-radius: 50%;
                                                background: transparent;
                                                border: dotted 18px #ffffff;
                                                animation: spiner 2s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 14 */}
                <Div>
                    <Span>14</Span>
                    <Box onClick={() => setModal_14(true)}>
                        <div className=' loader'>
                        </div>
                    </Box>
                    { modal_14 && (
                            <Modal>
                                <p>Spiner 14</p>
                                <Button_close className='btn border' onClick={() => setModal_14()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-5'>{textDivTag}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                            <div class='spiner'></div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                        <p className='px-5'>width: 300px;</p>
                                        <p className='px-5'>aspect-ratio: 1;</p>
                                        <p className='px-5'>display: grid;</p>
                                        <p className='px-5'>animation: l14 4s infinite;</p>
                                        <p className='px-4 text-warning'>&#125;</p>

                                        <p className='px-4 text-warning'>.spiner::before,</p>
                                        <p className='px-4 text-warning'>.spiner::after&#123;</p>
                                        <p className='px-5'>content: "";</p>
                                        <p className='px-5'>grid-area: 1/1;</p>
                                        <p className='px-5'>border: 8px solid;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-color: red red #0000 #0000;</p>
                                        <p className='px-5'> mix-blend-mode: darken;</p>
                                        <p className='px-5'>animation: l14 1s infinite linear;</p>
                                        <p className='px-4 text-warning'>&#125;</p>

                                        <p className='px-4 text-warning'>.spiner::after  &#123;</p>
                                        <p className='px-5'>border-color: #0000 #0000 rgb(255, 255, 255) rgb(255, 255, 255);</p>
                                        <p className='px-5'>animation-direction: reverse;</p>
                                        <p className='px-4 text-warning'>&#125;</p>

                                        <p className="px-4 text-warning">@keyframes spiner &#123;</p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className="px-4 text-warning">&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                            /* Styles-css.com */
                                            width: 70px;
                                            aspect-ratio: 1;
                                            display: grid;
                                            animation: l14 4s infinite;
                                        }
                                        .spiner::before,
                                        .spiner::after {    
                                          content: "";
                                          grid-area: 1/1;
                                          border: 8px solid;
                                          border-radius: 50%;
                                          border-color: red red #0000 #0000;
                                          mix-blend-mode: darken;
                                          animation: l14 1s infinite linear;
                                        }
                                        .spiner::after {
                                          border-color: #0000 #0000 rgb(255, 255, 255) rgb(255, 255, 255);
                                          animation-direction: reverse;
                                        }
                                        @keyframes l14{ 
                                          100%{transform: rotate(1turn)}
                                        }

                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 15 */}
                <Div>
                    <Span>15</Span>
                    <Box onClick={() => setModal_15(true)}>
                        <div className='spiners spiner-15'>
                            <div className='child-spiner-15'>

                            </div>
                        </div>
                    </Box>
                    { modal_15 && (
                            <Modal>
                                <p>Spiner 15</p>
                                <Button_close className='btn border' onClick={() => setModal_15()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildEnd}</p>
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiners spiner-15'>
                                            <div className='child-spiner-15'>
                                    
                                            </div>
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                        <p className='px-5'>width: 300px;</p>
                                        <p className='px-5'>height: 300px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-left: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-right: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-top: solid 10px transparent;</p>
                                        <p className='px-5'>border-bottom: solid 10px transparent;</p>
                                        <p className='px-5'>animation: spiner 2s linear infinite;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className='px-4 text-warning'>.child-spiner &#123;</p>
                                        <p className='px-5'>width: 300px;</p>
                                        <p className='px-5'>height: 300px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-top: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-bottom: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-left: solid 10px transparent;</p>
                                        <p className='px-5'>border-right: solid 10px transparent;</p>
                                        <p className='px-5'>display: flex;</p>
                                        <p className='px-5'>justify-content: center;</p>
                                        <p className='px-5'>align-items: center;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className="px-4"><span className='text-danger'>@keyframes spiner</span> <span className='text-warning'>&#123;</span></p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 70px;
                                                height: 70px;
                                                border-radius: 50%;
                                                border-left: solid 10px #ffffff;
                                                border-right: solid 10px #ffffff;
                                                border-top: solid 10px transparent;
                                                border-bottom: solid 10px transparent;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                animation: spiner 2s linear infinite;
                                            }
                                            .child_spiner {
                                                width: 60%;
                                                height: 60%;
                                                border-radius: 50%;
                                                border-top: solid 10px #ffffff;
                                                border-bottom: solid 10px #ffffff;
                                                border-left: solid 10px transparent;
                                                border-right: solid 10px transparent;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 16 */}
                <Div>
                    <Span>16</Span>
                    <Box onClick={() => setModal_16(true)}>
                        <div className='spiners spiner-16'>
                            <div className='child-spiner-16'>

                            </div>
                        </div>
                    </Box>
                    { modal_16 && (
                            <Modal>
                                <p>Spiner 16</p>
                                <Button_close className='btn border' onClick={() => setModal_16()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildEnd}</p>
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiners spiner-15'>
                                            <div className='child-spiner-15'>
                                    
                                            </div>
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                        <p className='px-5'>width: 70px;</p>
                                        <p className='px-5'>height: 70px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-left: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-right: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-top: solid 10px;</p>
                                        <p className='px-5'>border-bottom: solid 10px ;</p>
                                        <p className='px-5'>animation: spiner 2s linear infinite;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className='px-4 text-warning'>.child-spiner &#123;</p>
                                        <p className='px-5'>width: 70px;</p>
                                        <p className='px-5'>height: 70px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-top: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-bottom: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-left: solid 10px;</p>
                                        <p className='px-5'>border-right: solid 10px;</p>
                                        <p className='px-5'>animation: spiner 1.7s  infinite reverse;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className="px-4"><span className='text-danger'>@keyframes spiner</span> <span className='text-warning'>&#123;</span></p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                            /* styles-css.com */
                                            width: 70px;
                                            height: 70px;
                                            border-radius: 50%;
                                            border-top: solid 10px #ffffff;
                                            border-bottom: solid 10px #ffffff;
                                            border-left: solid 10px transparent;
                                            border-right: solid 10px transparent;
                                            animation: spiner 1.5s  forwards infinite ;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            }
                                            .child_spiner {
                                                width: 60%;
                                                height: 60%;
                                                border-radius: 50%;
                                                border-top: solid 10px #ffffff;
                                                border-bottom: solid 10px #ffffff;
                                                border-left: solid 10px transparent;
                                                border-right: solid 10px transparent;
                                                animation: spiner 1.5s  reverse infinite ;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 17 */}
                <Div>
                    <Span>17</Span>
                    <Box onClick={() => setModal_17(true)}>
                        <div className="spiner-17">
                            <div className='child-spiner-17'>

                            </div>
                        </div>
                    </Box>
                    { modal_17 && (
                            <Modal>
                                <p>Spiner 17</p>
                                <Button_close className='btn border' onClick={() => setModal_17()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildEnd}</p>
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiners spiner-15'>
                                            <div className='child-spiner-15'>
                                    
                                            </div>
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                        <p className='px-5'>width: 100px;</p>
                                        <p className='px-5'>height: 100px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-left: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-right: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-top: solid 10px;</p>
                                        <p className='px-5'>border-bottom: solid 10px ;</p>
                                        <p className='px-5'>animation: spiner 2s linear infinite;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/* Child */}
                                        <p className='px-4 text-warning'>.child-spiner &#123;</p>
                                        <p className='px-5'>width: 40%;</p>
                                        <p className='px-5'>height: 40%;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent ;</p>
                                        <p className='px-5'>/* The color of the border should be the same as the background color */</p>
                                        <p className='px-5'>border: dashed 31px  rgb(33,37,41);</p>
                                        <p className='px-5'>background-color: rgb(235, 237, 240);</p>
                                        <p className='px-5'>animation: spiner 1.7s  infinite reverse;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className="px-4"><span className='text-danger'>@keyframes spiner</span> <span className='text-warning'>&#123;</span></p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 100px;
                                                height: 100px;
                                                border-radius: 50%;
                                                border-left: solid 10px #ffffff;
                                                border-right: solid 10px #ffffff;
                                                border-top: solid 10px transparent;
                                                border-bottom: solid 10px transparent;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                animation: spiner 2s linear infinite;
                                            }
                                            .child_spiner {
                                                width: 40%;
                                                height: 40%;
                                                border-radius: 50%;
                                                background: transparent ;
                                                /* The color of the border should be the same as the background color */
                                                border: dashed 31px  rgb(33,37,41);
                                                background-color: rgb(235, 237, 240);
                                                animation: spiner 1.7s  infinite reverse;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 18 */}
                <Div>
                    <Span>18</Span>
                    <Box onClick={() => setModal_18(true)}>
                        <div className="spiner-18">
                            <div className='child-spiner-18'>

                            </div>
                        </div>
                    </Box>
                    { modal_18 && (
                            <Modal>
                                <p>Spiner 18</p>
                                <Button_close className='btn border' onClick={() => setModal_18()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildStart}</p>
                                        <p className='text-light text-start px-5'>{textChildEnd}</p>
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiners spiner-15'>
                                            <div className='child-spiner-15'>
                                    
                                            </div>
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>

                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                        <p className='px-5'>width: 100px;</p>
                                        <p className='px-5'>height: 100px;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>border-left: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-right: solid 10px #ffffff;</p>
                                        <p className='px-5'>border-top: solid 10px;</p>
                                        <p className='px-5'>border-bottom: solid 10px ;</p>
                                        <p className='px-5'>display: flex;</p>
                                        <p className='px-5'>justify-content: center;</p>
                                        <p className='px-5'>align-items: center;</p>
                                        <p className='px-5'>animation: spiner 2s linear infinite;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/* Child */}
                                        <p className='px-4 text-warning'>.child-spiner &#123;</p>
                                        <p className='px-5'>width: 40%;</p>
                                        <p className='px-5'>height: 40%;</p>
                                        <p className='px-5'>border-radius: 50%;</p>
                                        <p className='px-5'>background: transparent ;</p>
                                        <p className='px-5'>/* The color of the border should be the same as the background color */</p>
                                        <p className='px-5'>border: dashed 29px  rgb(33,37,41);</p>
                                        <p className='px-5'>background-color: rgb(235, 237, 240);</p>
                                        <p className='px-5'>animation: spiner 1.7s  infinite backwards;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                        {/*  */}
                                        <p className="px-4"><span className='text-danger'>@keyframes spiner</span> <span className='text-warning'>&#123;</span></p>
                                        <p className="px-5">0%&#123; transform: rotate(0)&#125;</p>
                                        <p className="px-5">100%&#123;transform: rotate(360deg)&#125;</p>
                                        <p className='px-4 text-warning'>&#125;</p>
                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 100px;
                                                height: 100px;
                                                border-radius: 50%;
                                                border-left: solid 10px #ffffff;
                                                border-right: solid 10px #ffffff;
                                                border-top: solid 10px;
                                                border-bottom: solid 10px ;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                animation: spiner 2s linear infinite;
                                            }
                                            .child_spiner {
                                                width: 40%;
                                                height: 40%;
                                                border-radius: 50%;
                                                background: transparent ;
                                                /* The color of the border should be the same as the background color */
                                                border: dashed 31px  rgb(33,37,41);
                                                background-color: rgb(235, 237, 240);
                                                animation: spiner 1.7s  infinite backwards;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 19 */}
                <Div>
                    <Span>19</Span>
                    <Box onClick={() => setModal_19(true)}>
                        <div className="spiner-19">
                            
                        </div>
                    </Box>
                    { modal_19 && (
                            <Modal>
                                <p>Spiner 19</p>
                                <Button_close className='btn border' onClick={() => setModal_19()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiners spiner-15'>
                                            
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>
                                        <p className='px-4 text-warning'>.spiner &#123;</p>
                                            <p className="px-5">width: 100px;</p>
                                            <p className="px-5">height: 100px;</p>
                                            <p className="px-5">border-radius: 50%;</p>
                                            <p className="px-5">border-top: solid 10px red;</p>
                                            <p className="px-5">border-bottom: solid 10px #ffffff;</p>
                                            <p className="px-5">border-left: solid 10px #ffffff;</p>
                                            <p className="px-5">border-right: solid 10px #ffffff;</p>
                                            <p className="px-5">animation: spiner 1s linear infinite;</p>

                                        <p className='px-4 text-warning'>&#125;</p>

                                        

                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        
                                        .spiner {
                                                /* styles-css.com */
                                                width: 100px;
                                                height: 100px;
                                                border-radius: 50%;
                                                border-top: solid 10px red;
                                                border-bottom: solid 10px #ffffff;
                                                border-left: solid 10px #ffffff;
                                                border-right: solid 10px #ffffff;
                                                animation: spiner 1s linear infinite;
                                            }
                                            @keyframes spiner {
                                                0%{ transform: rotate(0)}
                                                100%{ transform: rotate(360deg)}
                                            }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
                    {/* SPINER 20 */}
                <Div>
                    <Span>20</Span>
                    <Box onClick={() => setModal_20(true)}>
                        <div className='spiner-20'>
                            
                        </div>
                        
                    </Box>
                    { modal_20 && (
                            <Modal>
                                <p>Spiner 20</p>
                                <Button_close className='btn border' onClick={() => setModal_20()}>Close</Button_close>
                                <Section>
                                <div>
                                <p>Html</p>
                                    <code> 
                                        <p className='text-light text-start px-3'>{textTagChildStart}</p>
                                        
                                        <p className='text-light text-start px-3'>{textTagChildEnd}</p>
                                    </code>
                                    
                                   <span className='text-light btn border ' onClick={() => {
                                        navigator.clipboard.writeText(`
                                        <div className='spiner'>
                                            
                                        </div>
                                        `)
                                   }}>Copy</span>
                                </div>
                                <div >
                                    <p>CSS</p>
                                    <code className='text-start text-light'>
                                        <p className="text-warning px-4">.spiner &#123;</p>
                                        <p className="px-5">width: 100px;</p>
                                        <p className="px-5">height: 100px;</p>
                                        <p className="px-5">border: 5px solid #FFF;</p>
                                        <p className="px-5">border-bottom-color: transparent;</p>
                                        <p className="px-5">border-radius: 50%;</p>
                                        <p className="px-5">display: inline-block;</p>
                                        <p className="px-5">box-sizing: border-box;</p>
                                        <p className="px-5">animation: spiner 1s linear infinite;</p>
                                        <p className="text-warning px-4">&#125;</p>

                                        <p className=" px-4"><span className='text-danger'>@keyframes</span><span className='px-1'>spiner</span> <span className='text-warning'>&#123;</span></p>
                                        <p className='px-5'>0% &#123; transform: rotate(0) &#125;</p>
                                        <p className='px-5'>100% &#123; transform: rotate(360deg) &#125;</p>
                                        <p className="text-warning px-4">&#125;</p>

                                    </code>
                                    <span  onClick={() => {
                                        navigator.clipboard.writeText(`
                                        .spiner {
                                            /* styles-css.com */
                                            width: 100px;
                                            height: 100px;
                                            border: 5px solid #FFF;
                                            border-bottom-color: transparent;
                                            border-radius: 50%;
                                            display: inline-block;
                                            box-sizing: border-box;
                                            animation: spiner 1s linear infinite;
                                        }
                                        @keyframes spiner {
                                            0%{ transform: rotate(0)}
                                            100%{ transform: rotate(360deg)}
                                        }
                                            
                                        `)
                                   }}>Copy</span>
                                </div>
                                </Section>
                            </Modal>
                    ) }
                </Div>
            </div>
        </div>
    </div>
  )
}

export default SpinerComponent