import { useState } from "react";
import { Link } from "react-router-dom";
import DataMenu from '../../Data_menu/DataMenu';
import './Btn_menu.css';


export default function Menu() {
  const [hoveredMenu, setHoveredMenu] = useState(null); // Track hovered menu
  const handleMouseEnter = (title) => setHoveredMenu(title);
  const handleMouseLeave = () => setHoveredMenu(null);

  return (
    <div className="listsTitleMenu">
      <ul className="ul_list_menu">
      {DataMenu.map((menu) => (
        <li
          key={menu.TitleMenu}
          className="menu-title"
          onMouseEnter={() => handleMouseEnter(menu.TitleMenu)}
        >
          {menu.TitleMenu}
        </li>
      ))}
      </ul>

      {hoveredMenu && (
        <div className="container-list "
        onMouseLeave={handleMouseLeave}
        >
          <div className="container  d-flex flex-wrap">
          {DataMenu.find((menu) => menu.TitleMenu === hoveredMenu)?.onderMenu.map(
            (item) => (
              <Link
                className="Link-menu"
                key={item.id}
                to={item.link}
              >
                {item.title}
              </Link>
            )
          )}
          </div>
        </div>
      )}
    </div>
  );
}