import { Link } from "react-router-dom";
import { useEffect } from "react";

function Comparison() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>Comparison</h5>
        <br /><br />

        <p>In JavaScript, comparisons are used to check if values are equal or different, greater than or less than each other, and so on. They are essential for making decisions and controlling the flow of your program.</p>

        <p className='mt-5'>Here's a breakdown of comparisons in JavaScript:</p>

        <p><b>Comparison Operators:</b></p>
        <p>JavaScript provides several comparison operators, each with a specific purpose:</p>

        <ul className="mt-3">
            <li className="mt-3"><b>Equality (==):</b> This operator checks if two values are equal, but it can be a bit loose with data types. It might convert one value to another type before comparison. For example, "2" == 2 evaluates to true because the string "2" is converted to the number 2 before comparison.</li>
            <li className="mt-3"><b>Strict Equality (===):</b> This operator performs a stricter comparison. It checks if two values are equal in value and data type. "2" === 2 is true, but "2" === 2.0 is false (string vs number).</li>
            <li className="mt-3"><b>Inequality (!=):</b> This operator checks if two values are not equal.</li>
            <li className="mt-3"><b>Greater Than ({`>`}):</b> Checks if one value is greater than another.</li>
            <li className="mt-3"><b>Less Than ({`<`}):</b> Checks if one value is less than another.</li>
            <li className="mt-3"><b>Greater Than or Equal To ({`>=`}):</b> Checks if one value is greater than or equal to another.</li>
            <li className="mt-3"><b>Less Than or Equal To ({`<=`}):</b> Checks if one value is less than or equal to another.</li>
        </ul>



        <p><b>Using Comparisons:</b></p>
        <p>Comparison operators are often used within conditional statements like if, else if, and else to control the program flow based on the comparison result.</p>
        <p>For example:</p>
        <div className="Box">
            <h6>JavaScript</h6>
            <br />

            <p><span className="text-warning">let</span> <span className="text-info">age</span> =  <span className="text-danger">18</span></p>
            <p className="mt-3"><span className="text-warning">if</span> (<span className="text-info">age</span>  {`>=`} <span className="text-danger">18</span> ) {`{`}</p>
                <p className="mx-4"><span className="text-light">console.log</span>(<span className="text-success">"You are eligible to vote."</span>);</p>
            <p>{`}`} <span className="text-warning">else</span> {`{`}</p>
                <p className="mx-4"><span className="text-light">console.log</span>(<span className="text-success">"You are not eligible to vote."</span>);</p>
            <p>{`}`}</p>
        </div>


        <p className="mt-3">In this example, the if statement checks if age is greater than or equal to 18. If the condition is true, the message "You are eligible to vote" is printed. Otherwise, the message "You are not eligible to vote" is printed.</p>

        <p><b>Things to Consider:</b></p>

        <ul>
            <li>When comparing different data types, JavaScript might perform type coercion (conversion) using the == operator. Be cautious of this behavior and use === for stricter comparisons.</li>
            <li>For object comparisons, === only checks for reference equality (objects point to the same memory location), not deep equality (comparing object properties).</li>
        </ul>



        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Arithmetic' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/LogicalOperators' >Next</Link>
        </div>
    </div>
  )
}

export default Comparison