const DataMenu = [
    {
        TitleMenu: 'Tutorial',
        onderMenu : [
            {id: 1, link:'/TutorialCss', title: 'CSS'},
            {id: 2, link:'/Tutorial_JS', title: 'JS'},
        ]
    },
    {
        TitleMenu: 'Generator',
        onderMenu : [
            { id: 1, link:'/rgba' , title:'Change color code' },
            { id: 2, link:'/Glassemorphism' , title:'Glass morphism' },
            { id: 3, link:'/Radius' , title:'Box-shadow' },
            { id: 4, link:'/ColumnarText' , title:'Column Text' },
        ]
    },
    {
        TitleMenu: 'Sample',
        onderMenu : [
            {id: 1, link:'/gradient' , title:'Gradient'},
            {id: 2, link:'/boxshadow' , title:'Boxs-hadow'},
            {id: 3, link:'/palettecolor' , title:'Palette color'},
            {id: 4, link:'/Spiner' , title:'Loader'},
        ]
    }
    
   

]


export default DataMenu;