import { Link } from "react-router-dom";
import { useEffect } from "react";

function Arithmetic() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className='text-start p-3'>
        <h5>Arithmetic</h5>

        <br /><br />
        <p>Arithmetic in JavaScript refers to its capability to perform mathematical operations using numbers. Just like basic math, JavaScript provides several operators that allow you to add, subtract, multiply, divide, and more on numerical data.</p>


        <p className="mt-3">Here are the common arithmetic operators in JavaScript:</p>

        <ul>
            <li className="mt-3"><b>Addition (+):</b> Adds two numbers.</li>
            <li className="mt-3"><b>Subtraction (-):</b> Subtracts one number from another.</li>
            <li className="mt-3"><b>Multiplication (*):</b> Multiplies two numbers.</li>
            <li className="mt-3"><b>Division (/):</b> Divides one number by another.</li>
            <li className="mt-3"><b>Modulus (%):</b> Calculates the remainder after dividing one number by another.</li>
            <li className="mt-3"><b>Exponentiation ()**:</b> Raises a number to a power.</li>
        </ul>

        <p className="mt-3">JavaScript also follows the order of operations (operator precedence) similar to what you learned in math class. Multiplication and division are generally performed before addition and subtraction. You can use parentheses to override the default order.</p>

        <p className="mt-5">Here's an example that demonstrates some basic arithmetic operations in JavaScript:</p>

        <div className="Box">
            <h6>JavaScript</h6>
            <br />
            <span className="text-secondary">// Define some variables with numbers</span>
            <p><span className="text-danger">let</span> price = <span className="text-danger">10</span>;</p>
            <p><span className="text-danger">let</span> quantity = <span className="text-danger">2</span>;</p>
            <p><span className="text-danger">let</span> discount = <span className="text-danger">0.1</span>;</p>

            <span className="text-secondary mt-2">// Calculate total price before discount</span>
            <p><span className="text-danger">let</span> totalPrice  = <span className="text-info">price</span> * <span className="text-info">quantity</span>;</p>

            <span className="text-secondary mt-2">// Calculate discount amount</span>
            <p><span className="text-danger">let</span> discountAmount = <span className="text-info">totalPrice</span> * <span className="text-info">discount</span>;</p>

            <span className="text-secondary mt-2">// Calculate final price after discount</span>
            <p><span className="text-danger">let</span> finalPrice = <span className="text-info">totalPrice</span> - <span className="text-info">discountAmount</span>;</p>

            <span className="text-secondary mt-2">// Display the results</span>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Original Price: $"</span> + price)</p>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Quantity: "</span> + quantity)</p>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Discount (%): "</span> + discount * <span className="text-danger">100</span>) <span className="text-secondary">// Convert decimal to percentage</span></p>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Discount Amount: $"</span> + discountAmount.toFixed (<span className="text-danger">2</span>)); <span className="text-secondary">// Round to 2 decimal places</span></p>
            <p><span className="text-warning">console</span>.log(<span className="text-info">"Final Price: $"</span>(<span className="text-danger">2</span>));</p>
        </div>

        <p className="mt-3">This code defines variables for price, quantity, and discount. It then performs calculations for total price, discount amount, and final price using arithmetic operators. Finally, it displays the results using console.log statements.</p>


        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Booleans' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/Comparison' >Next</Link>
        </div>

    </div>
  )
}

export default Arithmetic