import { Link } from "react-router-dom"
import { useEffect } from "react";

function Gradients() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const GradientColorLinear = {
        background: 'rgb(70,34,179)',
        background: 'linear-gradient(90deg, rgba(70,34,179,1) 48%, rgba(200,36,155,1) 100%)'
    }
    const GradientColorRadial = {
        background: 'rgb(70,34,179)',
        background: 'radial-gradient(circle, rgba(70,34,179,1) 48%, rgba(200,36,155,1) 100%)'
    }

  return (
    <div className='text-start p-3'>
        <h3 className='text-center'>Gradients</h3>
        <br /><br /><br />

        <p>Gradients are a powerful tool in web design that allow you to create smooth transitions between two or more colors.  They can be used to add depth, dimension, and visual interest to your web pages.  There are three main types of gradients in CSS: linear, radial, and conic.</p>

        <ul className="mt-5">
            <li><b>Linear Gradients</b>: These are the simplest and most common type of gradient. They create a smooth transition between colors along a straight line. You can define the direction of the gradient, such as from top to bottom, left to right, or diagonally.</li>
            <li><b>Radial Gradients</b>: Radial gradients create a circular transition of colors, emanating outward from a center point.  This can be useful for creating effects like buttons or backgrounds that look like spheres.  You can define the center point of the gradient and how the colors spread from there.</li>
            <li><b>Conic Gradients</b>: Conic gradients are the most complex of the three types. They create a circular transition of colors, but instead of radiating outward from a center, the colors transition around the circumference of a circle, like a sliced cone.  This can be used to create interesting and unique effects.</li>
        </ul>

        <p><b>Linear Gradients:</b> {`linear-gradient(36deg,hsla(252, 40%, 29%, 1) 0%, hsla(270, 77%, 71%, 1) 100%`}</p>
        <p><b>Radial Gradients:</b> {`linear-gradient(36deg,hsla(252, 40%, 29%, 1) 0%, hsla(270, 77%, 71%, 1) 100%`}</p>

        <h6>An example of Gradient:</h6>
        <div className="d-flex align-items-center">
            <div className="w-25 h-25 border" style={GradientColorLinear}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </div>

            <div className="w-25 h-25 mx-3 border" style={GradientColorRadial}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </div>
        </div>

        <p className="mt-3">To better understand this topic, you can see the gradients from the sample section of this site</p>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/Colors'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/AddImage'>Next</Link>
        </div>
    </div>
  )
}

export default Gradients