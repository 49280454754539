import './StyleBoxShadowPage.css';
import Loading from '../../../Components/LOADING/Loading';
import CardShadowBox from '../../../Components/Box-shadow/Card';
import { Helmet } from 'react-helmet';
import {Row, Col} from 'react-bootstrap';
import { useState, useEffect } from 'react';

 const BoxShadow = ({  }) => {
  const [ boxShadows, setBoxShadows ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0); 
  },[])
 
  const URL_API = process.env.REACT_APP_URL_API;

  useEffect(() => {
      fetch(`${URL_API}/api/boxShadow`)
      .then(res => res.json())
        .then(res => {
          setBoxShadows(res.data);
          setIsLoading(false)
        })
        .catch(error => console.log(error));
  }, [])



  return (
    <div className='px-3 BoxShadowPage '>
      <Helmet>
      
      <meta name="google-adsense-account" content="ca-pub-1631607859297946" />
        <title>Generate CSS codes styleuse</title>
        <meta name="description" content="Styleuse - A tool to easily find CSS codes like box-shadow." />
        <meta name="keywords" content="CSS, CSS code  box-shadow, CSS tools" />
      </Helmet>

      <Row className='w-100 m-auto h-100'>
      <Col lg={2} className='position-relative  d-flex justify-content-center zIndex-2 border' >
        <div className='Advertising  mt-5 pt-5 px-5 '> 
          <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-1631607859297946"
            data-ad-slot="2957199157"
            data-auto-format="mcrspv"
            data-full-width="">
            <div overflow=""></div>
          </amp-ad>
        </div>
      </Col>

        <Col lg={8}>
          {/* <CardBoxShadow /> */}
          {/*  */}
          {/* <CardBoxShadow2 /> */}
          <h5>Box - Shadow</h5>
            <div className='d-flex flex-wrap mt-5  justify-content-center   align-items-center  zIndex-1'>
            {
            isLoading?  <Loading /> : (
            <div className='d-flex flex-wrap mt-5  justify-content-center   align-items-center  zIndex-1'>
            {
              boxShadows.map((item , index) => <CardShadowBox key={item.id} index={index} {...item} />)
            }
            </div>
          )
        }
        
            </div>
        </Col>

        <Col lg={2} className='pt-5 d-flex   justify-content-center  border'>
          <div className='Advertising  mt-5 pt-5 px-5 '> 
            <amp-ad width="100vw" height="320"
              type="adsense"
              data-ad-client="ca-pub-1631607859297946"
              data-ad-slot="2957199157"
              data-auto-format="mcrspv"
              data-full-width="">
              <div overflow=""></div>
            </amp-ad>
          </div>
        </Col>
      </Row>
      
    </div>
  )
}


export default BoxShadow;