import { Link } from "react-router-dom";
import { useEffect } from "react";

function Devices() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Devices</h3>

        <p>Targeting different devices with CSS is achieved through media queries, as we discussed earlier. Here's a deeper dive into how to use them for desktops, tablets, and mobiles:</p>



        <p>bMobile-First Approach:</p>

        <p>It's recommended to follow a mobile-first approach. This means designing your website for mobile devices first and then using media queries to enhance the layout for larger screens. This ensures a smooth user experience on smaller viewports, which are increasingly common.</p>


        <p className="mt-5"><b>Breakpoints and Media Queries:</b></p>
        <ul>
            <li><b>Breakpoints</b>: These are specific screen width values where your website's layout might need adjustments. Common breakpoints might be around 600px for tablets and 900px for desktops.</li>
            <li><b>Media Queries</b>: Use media queries to target styles based on these breakpoints. Here's the general syntax:</li>
        </ul>

        <div className="mt-5 bg-dark text-light p-3">
            <h6>CSS</h6>
            <br />

            <p><span className="text-info">@media </span> screen <span className="text-info"> and </span> ( max-width: <span className="text-warning">600px</span>) <span className="text-secondary">{`{ /* Styles for mobile */ }`}</span></p>
            <p><span className="text-info">@media </span> screen <span className="text-info"> and </span> ( max-width: <span className="text-warning">600px</span>) <span className="text-secondary">{`{ /* Styles for tablet */ }`}</span></p>
            <p><span className="text-info">@media </span> screen <span className="text-info"> and </span> ( max-width: <span className="text-warning">900px</span>) <span className="text-secondary">{`{ /* Styles for tablet */ }`}</span></p>
            <p><span className="text-info">@media </span> screen <span className="text-info"> and </span> ( min-width: <span className="text-warning">900px</span>) <span className="text-secondary">{`{ /* Styles for desktop */ }`}</span></p>
        </div>
        <p className="mt-3">In this example:</p>
        <ul>
            <li>The first query applies styles to screens with a maximum width of 600px (mobile).</li>
            <li>The second query applies styles to screens between 600px and 900px (tablet).</li>
            <li>The third query applies styles to screens wider than 900px (desktop).</li>
        </ul>

        <h6 className="mt-5">What to target with Media Queries:</h6>
        <ul>
            <li><b>Font size and line height</b>: Adjust these for better readability on smaller screens.</li>
            <li><b>Images</b>: Use responsive images or media queries to serve different sized images for different devices.</li>
            <li><b>Layout</b>: Change the number of columns, element positioning, and margins/padding to optimize for each device.</li>
            <li><b>Navigation</b>: Mobile menus might require a hamburger menu or other space-saving designs.</li>
        </ul>

        <h6 className="mt-5">Testing and Best Practices:</h6>
        <ul>
            <li>Use browser developer tools or device emulators to test your responsive design across different screen sizes.</li>
            <li>Consider using a flexbox or grid layout system for more responsive layouts.</li>
            <li>Don't target specific devices directly, focus on media features like screen width and orientation.</li>
        </ul>

        <p className="mt-5">By following these guidelines and using media queries effectively, you can create a website that delivers a great user experience on desktops, tablets, and mobile phones.</p>

        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/MediaQueries'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/AdaptiveDesign'>Next</Link>
        </div>
    </div>
  )
}

export default Devices