import { Link } from "react-router-dom"
import { useEffect } from "react";

function TextEffects() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const styles = {
        width: '200px',
        height: '200px',
        backgroundColor: '#f0f0f0',
        boxShadow:' 5px 5px 10px #888888',
    }

  return (
    <div className='p-3'>
        <h3>TextEffects</h3>
        <h4 className='text-start mt-4'>shadows, indents, and outlines</h4>

        <h5 className="text-start mt-5">Shadows</h5>
        <p className="text-start">In CSS (Cascading Style Sheets), the term <span className="light">"shadows"</span> refers to a visual effect that can be applied to elements on a web page to create depth and dimensionality. Shadows can be added using the box-shadow property. This property allows you to cast a shadow from the frame of almost any element.</p>

        <p className="text-start mt-5">The box-shadow property takes several parameters:</p>
        <ol className="text-start">
            <li><b>Horizontal offset</b>: The distance of the shadow from the element horizontally.</li>
            <li><b>Vertical offset</b>: The distance of the shadow from the element vertically.</li>
            <li><b>Blur radius</b>: Optional. The amount of blur applied to the shadow.</li>
            <li><b>Spread radius</b>: Optional. The size of the shadow.</li>
            <li><b>Color</b>: Optional. The color of the shadow.</li>
        </ol>

        <p className="text-start mt-5">Here's a basic example of how box-shadow is used:</p>
        <div className="text-start bg-dark text-light p-3">
            <p>{`.box {`}</p>
            <p className="mx-3">{`width: 200px;`}</p>
            <p className="mx-3">{`height: 200px;`}</p>
            <p className="mx-3">{`background-color: #f0f0f0;`}</p>
            <p className="mx-3">{`box-shadow: 5px 5px 10px #888888;`}</p>
            <p>{`}`}</p>
        </div>
        <p className="text-start mt-4">In this example:</p>

        <ul className="text-start">
            <li>The shadow will be 5 pixels to the right and 5 pixels below the box.</li>
            <li>The <span className="light">blur radius</span> is set to 10 pixels, creating a softer shadow.</li>
            <li>The spread radius is not specified, so it defaults to 0, meaning the shadow is the same size as the element.</li>
            <li>The shadow color is <span className="light">#888888</span>.</li>
        </ul>
        <p className="text-start">This creates a box with a shadow that gives it a raised appearance. You can adjust the values of box-shadow to achieve different shadow effects, such as inset shadows or multiple shadows.</p>

        <div style={styles}>

        </div>

        <p className="mt-5">You can get all kinds of shadow boxes with different layers and colors from the sample section of this page</p>

        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/MarginPadding'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/Width'>Next</Link>
    
    </div>
  )
}

export default TextEffects