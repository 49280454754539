import { Link } from "react-router-dom";
import { useEffect } from "react";

function CompilingPreprocessors() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Compiling Preprocessor code to Plain CSS</h3>
        <br /><br /><br />

        <p>While you write your styles in a preprocessor's syntax (like Sass or LESS), browsers can't understand it directly. That's where compilation comes in.</p>

        <p className="mt-5"><b>The Compilation Process:</b></p>

        <ol>
            <li><b>Preprocessor Code</b>: You write your styles using the features offered by the preprocessor (variables, mixins, functions etc).</li>
            <li><b>Compiler</b>: A program called a compiler reads your preprocessor code.</li>
            <li><b>Plain CSS Output</b>: The compiler processes your code, resolving variables, mixins, and other features. It then generates a standard CSS file that browsers can understand.</li>
        </ol>


        <p className="mt-5"><b>How to Compile:</b></p>
        <p>There are several ways to compile your preprocessor code, depending on your workflow and tools:</p>

        <ul>
            <li><b>Command Line</b>: Many preprocessors offer command-line tools for compilation. You can run a command specifying the preprocessor source file and the desired output CSS file.</li>
            <li><b>Build Tools</b>: Popular build tools like Gulp, Webpack, or Grunt can be integrated with preprocessors to automate the compilation process as part of your development workflow.</li>
            <li><b>GUI Compilers</b>: Some preprocessors offer graphical user interface (GUI) tools that allow you to compile files with a few clicks.</li>
        </ul>


        <p className="mt-5"><b>Benefits of Compiling:</b></p>

        <ul>
            <li><b>Browser Compatibility</b>: Ensures your styles work across all browsers by converting them to standard CSS.</li>
            <li><b>Optimization</b>: Many compilers offer minification options to reduce the size of the generated CSS file for faster loading times.</li>
        </ul>

        <p className="mt-5"><b>Popular Compiler Tools:</b></p>

        <ul>
            <li><b>Sass</b>: The Sass compiler is available as a command-line tool or can be integrated with build tools.</li>
            <li><b>LESS</b>: Similar to Sass, LESS offers a command-line compiler and integrations with build systems.</li>
        </ul>

        <p className="mt-5"><b>Additional Tips:</b></p>

        <ul>
            <li>Configure your compiler to automatically compile your preprocessor files whenever you make changes.</li>
            <li>Consider using source maps during development, which helps map the compiled CSS back to the original preprocessor code for easier debugging.</li>
        </ul>


        <p className="mt-5">By following these steps, you can ensure your preprocessor code gets compiled into clean, browser-compatible CSS, keeping your development workflow efficient and your styles optimized for performance.</p>









        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/CssVariable'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/BrowserInspect'>Next</Link>
        </div>
    </div>
  )
}

export default CompilingPreprocessors