import {useState} from 'react';
import './Btn_menu_beurger.css';

function Btn_menu_beurger() {
    const [btnSituation, setBtnSituation] = useState(false);
    const [btnMargin, setBtnMargin] = useState('')
    const [topBtn, setTopBtn] = useState('');
    const [bottomBtn, setBottomBtn] = useState('');
    const [hiddenBtn, setHiddenBtn] = useState('');

    const BeurgerBtn = () => {
        if(btnSituation == false) {
            setBtnSituation(true);
            setBtnMargin('Margin_btn_menu_beurger');
            setTopBtn('top_btn');
            setBottomBtn('bottom_btn');
            setHiddenBtn('hidden');
        }
        else if(btnSituation == true) {
            setBtnSituation(false);
            setBtnMargin('');
            setTopBtn('');
            setBottomBtn('');
            setHiddenBtn('')
        }
    }
  return (
    <button 
        onClick={BeurgerBtn}
        className={`Btn_menu_beurger ${btnMargin}`}
        
    >
        <div className={`${topBtn}`}></div>
        <div className={`${hiddenBtn}`}></div>
        <div className={`${bottomBtn}`}></div>
    </button>
  )
}

export default Btn_menu_beurger