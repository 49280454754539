import { Link } from "react-router-dom";
import { useEffect } from "react";

function OptimizingCss() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3 text-start'>
        <h3 className='text-center'>Optimizing CSS code performance</h3>
        <br /><br /><br />
        <p>Optimizing CSS code performance is crucial for a fast-loading website. Here are some key strategies to consider:</p>

        <p className="mt-5"><b>Reduce CSS file size:</b></p>


        <ul>
            <li><b>Minification</b>: This removes unnecessary characters like whitespace and comments, making the file smaller without affecting functionality.</li>
            <li><b>Shorthand and inheritance</b>: Use CSS properties that combine multiple declarations into one, reducing code duplication.</li>
            <li><b>Unused CSS removal</b>: Regularly audit your code to remove styles that aren't being used.</li>
        </ul>

        <p className="mt-5"><b>Optimize CSS delivery:</b></p>

        <ul>
            <li><b>Segmentation</b>: Break down CSS into multiple files for different parts of your website. This allows browsers to load only the styles required for the current page.</li>
            <li><b>Critical CSS</b>: Identify the essential styles needed for initial page rendering and load them first. Non-critical styles can be loaded asynchronously.</li>
            <li><b>Preloading and server push</b>: Techniques where the browser anticipates and fetches critical resources before they're requested, improving perceived load speed.</li>
        </ul>


        <p className="mt-5"><b>Other optimization techniques:</b></p>
        <ul>
            <li><b>Font optimization</b>: Limit the number of fonts used, choose appropriate weights and styles, and consider using variable fonts.</li>
            <li><b>Efficient selectors</b>: Write selectors that target specific elements effectively to avoid unintended style application.</li>
            <li><b>CSS preprocessors</b>: Tools like Sass or Less can improve code maintainability and reduce repetition.</li>
        </ul>


        <p className="mt-5"><b>Remember:</b></p>
        <ul>
            <li><b>Testing</b>: Always test your optimizations to ensure they don't break your website's functionality or layout.</li>
            <li><b>Balance</b>: While smaller file sizes are important, prioritize readability and maintainability in your code.</li>
        </ul>



        <p className="mt-5">By following these strategies, you can significantly improve the performance of your website's CSS and deliver a faster user experience.</p>


        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/BrowserInspect'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/ImportantNotes'>Next</Link>
        </div>
    </div>
  )
}

export default OptimizingCss