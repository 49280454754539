import { Link } from "react-router-dom"
import { useEffect } from "react";

function Position() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='p-3'>
        <h3>Position</h3>
        <br />
        <br />
        <br />
        <ul className="text-start">
            <li ><a href="#Static">Static</a></li>
            <li><a href="#Relative">Relative</a></li>
            <li><a href="#Fixed">Fixed</a></li>
            <li><a href="#Sticky">Sticky</a></li>
        </ul>

        <h5 id='Static' className="text-start mt-5">Static</h5>

        <p className="text-start">In CSS, <span >"static"</span> is the default positioning property for an element. When an element is positioned statically, it follows the normal flow of the document. This means that it will be displayed in the order it appears in the HTML markup, and its position will not be affected by other positioned elements.</p>

        <p className="text-start mt-5">Here's a brief overview of what "static" positioning entails:</p>
        <ol className='text-start'>
            <li className='mt-1'><b>Default Behavior</b>: Elements are positioned statically by default if no positioning is specified.</li>
            <li className='mt-1'><b>Normal Flow</b>: Elements are laid out in the document flow according to their order in the HTML markup.</li>
            <li className='mt-1'><b>No Offset Properties</b>: The properties top, right, bottom, and left have no effect on statically positioned elements because they don't apply.</li>
        </ol>

        <p className="text-start mt-5">Here's an example:</p>
        <div className="text-start p-3 text-light bg-dark">
            <p>{`<!DOCTYPE html>`}</p>
            <p>{`<html>`}</p>
            <p>{`<head>`}</p>
            <p className='mx-3'>{`<style>`}</p>
            <p className='mx-5'><span className="text-danger">.static</span>{`{`}</p>
            <p className='mx-5 px-3'><span className='text-info'>position</span>: {`static;`}</p>
            <p className='mx-5 px-3'><span className='text-info'>border: </span>{`2px solid red;`}</p>
            <p className='mx-5'>{`}`}</p>
            <p className='mx-3'>{`</style>`}</p>
            <p>{`</head>`}</p>
            <p>{`<body>`}</p>
             <p className='mt-5'>{`<div `} <span className='text-danger'> class</span> =<span className="text-info">"static"</span>{`>`}</p>
            <p className='mx-5'>{`This is a statically positioned div.`}</p>
            <p>{`</div>`}</p>
            <p>{`</body>`}</p>
            <p>{`</html>`}</p>
        </div>

        <p className="text-start mt-4">In this example, the <span className="light">.static</span> div will appear in the document flow like any other block-level element. Its position will not be affected by any other positioned elements on the page, and it won't respond to offset properties like <span className="light">top</span>, <span className="light">right</span>, <span className="light">bottom</span>, or <span className="light">left</span>.</p>
        <br id='Relative' />
        <br />
        <h5  className="text-start mt-5">Relative</h5>
        <p className="text-start">In CSS, <span className="light">"relative"</span> is a positioning property that allows you to position an element relative to its normal position in the document flow. When you set an element's position to <span className="light">"relative"</span> (position: relative;), it will be positioned relative to where it would normally appear in the document.</p>

        <p className="text-start">Here's how it works:</p>

        <p className="text-start"><b>1. Normal Flow</b>: By default, elements are positioned in the normal flow of the document. This means they appear one after another according to the order they are written in the HTML.</p>
        <p className="text-start"><b>2. Relative Positioning</b>: When you set an element's position to "relative", it stays in the normal flow, but you can then use the top, right, bottom, and left properties to move it relative to its normal position. For example:</p>


        <div className="text-start p-3 bg-dark text-light">
            <h5>Css</h5>
            <br />
            <br />
            <p><span className="text-danger">.box</span> {`{`}</p>
            <p className="px-5"><span className="text-info">position</span>: relative;</p>
            <p className="px-5"><span className="text-info">top</span>: <span className="text-danger">20px</span>;</p>
            <p className="px-5"><span className="text-info">left</span>: <span className="text-danger">30px</span>;</p>
            <p>{`}`}</p>
        </div>
        <p className="text-start">This would move the .box element 20 pixels down and 30 pixels to the right from where it would normally appear in the document flow.</p>

        <p className="text-start"><b>3.Relative to Self</b>: When you use top, right, bottom, and left properties with relative positioning, the element is moved relative to its own position. This means if you set top: 20px;, the element will be moved down 20 pixels from its normal position.</p>

        <p className="text-start mt-5">Relative positioning is often used in conjunction with absolute or fixed positioning to create complex layouts where elements are positioned relative to one another.</p>

        <br id="Fixed"/>
        <br />
        <h5 className="text-start">Fixed</h5>
        <p className="text-start">In CSS, the <span className="light">position: fixed;</span> property is used to position an element relative to the browser window or the viewport, rather than relative to its containing element. When an element is set to <span className="light">position: fixed;</span>, it is removed from the normal document flow, meaning it doesn't affect the positioning of other elements. Instead, it remains in a fixed position even when the page is scrolled.</p>

        <p className="text-start">For example, if you have a navigation bar that you want to remain at the top of the viewport regardless of scrolling, you could use position: fixed; to achieve this effect:</p>
        <div className="text-start p-3 text-light bg-dark">
            <p><span className="text-danger">.navbar</span>{`{`}</p>
            <p className="mx-5"><span className="text-info">position</span>: fixed;</p>
            <p className="mx-5"><span className="text-info">top</span>: <span className="text-danger"> 0</span>;</p>
            <p className="mx-5"><span className="text-info">left</span>: <span className="text-danger"> 0</span>;</p>
            <p className="mx-5"><span className="text-info">width</span>: <span className="text-danger"> 100%</span>;</p>
            <p className="mx-5"><span className="text-info">background-color</span>: <span className="text-danger"> #333</span>;</p>
            <p className="mx-5"><span className="text-info">color</span>: white;</p>
            <p className="mx-5"><span className="text-info">padding</span>: <span className="text-danger"> 10px</span>;</p>
            <p>{`}`}</p>
        </div>
        <p className="text-start mt-3">This CSS would fix the navbar at the top of the viewport, ensuring it stays visible as the user scrolls down the page.</p>

        <br id="Sticky"/>
        <br />
        <br />

        <h5 className="text-start">Sticky </h5>
        <p className="text-start mt-5">In CSS, <span className="light">"sticky"</span> is a positioning value that combines elements of both <span className="light">"fixed"</span> and <span className="light">"relative" </span>positioning. When an element is set to <span className="light">"position: sticky;"</span>, it behaves like a relatively positioned element until it reaches a specified scroll position, at which point it becomes fixed to the viewport.</p>
        <p className="text-start mt-3">The sticky positioning is often used for elements like headers or navigation bars that you want to remain visible as users scroll down a webpage, but then stay in place once they've scrolled past them. You can define the scroll position at which the element becomes sticky by using the <span className="lght">"top"</span>, <span className="light">"bottom"</span>, <span className="light">"left"</span>, or <span className="light">"right"</span> properties.</p>

        <p className="text-start mt-5">Here's a basic example:</p>

        <div className="text-light text-start bg-dark p-3">
            <p><span className="text-danger">.header</span>{`{`}</p>
            <p className="px-4"><span className="text-info">position</span>: sticky</p>
            <p className="px-4"><span className="text-info">top</span>:<span className="text-danger"> 0</span></p>
            <p className="px-4"><span className="text-info">background-color</span><span className="text-danger"> #ffffff</span></p>
            <p className="px-4"><span className="text-info">z-index</span><span className="text-danger"> 100</span></p>
            <p>{`}`}</p>
        </div>

        <p className="text-start mt-4">In this example, the <span className="light">".header"</span> element would remain in place at the top of the viewport as long as the user scrolls down, and then it would stay fixed at the top of the page once the user scrolls past it.</p>




        {/* btn  */}
        <br />
        <br />
        <Link className="px-3" to='/TutorialCss/Width'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/Floating'>Next</Link>
    </div>
  )
}

export default Position