import React from 'react';
import './spectrumStyle.css';
import {Row,Col} from 'react-bootstrap';

const SpectrumColor = () => {
  return (
    <div className='SpectrumColor pt-5'>
        <Row className='mt-5'>
            <Col lg={2} className='position-relative d-flex justify-content-center' >
            <div className='Advertising  mt-5 pt-5 px-5 '>
            </div>
            </Col>
            <Col lg={8} >
             <h1>Spectrum</h1>
             <h1>Spectrum</h1>
             <h1>Spectrum</h1>
             <h1>Spectrum</h1>
             <h1>Spectrum</h1>
             <h1>Spectrum</h1>
            </Col>
            <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
              <div className='vh-lg-100  position-fixed  '>
                 
              </div>
            </Col>
        </Row>

    </div>
  )
}

export default SpectrumColor