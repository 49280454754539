import { useState , useEffect } from 'react';
import GradiantTest from '../../../Components/Sample/Gradient/GradiantTest';
import {Row,Col} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Loading from '../../../Components/LOADING/Loading';
import './GradientSampleStyle.css';

const Gradient = () => {
    const [items , setItems] = useState ([]);
    const [loading, setLoading] = useState(true); // اضافه کردن متغیر loading

    const URL_API = process.env.REACT_APP_URL_API;
    useEffect(() => {
      fetch(`${URL_API}/api/Gradiant`)
        .then(res => res.json())
        .then(res => {
            setItems(res.data);
            setLoading(false); // دیگر در حالت بارگیری نیست
        })
        .catch(error => console.log(error));
    }, []);
    useEffect(() => {
      // Scroll to top
      window.scrollTo(0, 0); 
    },[])
   

    const totalItem =  items.length
  return (
   <div className='SampleGradient pt-5'>
      <Helmet>
      
     <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        <title>Gradient</title>
        <meta name="description" content="Styles-css - A tool to easily find CSS codes like gradient." />
        <meta name="keywords" content="CSS, gradient color, gradient style, color gradient, gradient background" />
        <meta name="keywords" content="CSS, couleur dégradée, style dégradé, dégradé de couleur, arrière-plan dégradé" />
        <meta name="keywords" content="CSS, color degradado, estilo degradado, degradado de color, fondo degradado" />
        <meta name="keywords" content="CSS, Farbverlauf, Verlaufsstil, Farbverlauf, Verlaufshintergrund" />
        <meta name="keywords" content="CSS, colore sfumato, stile sfumato, sfumatura di colore, sfondo sfumato" />
        <meta name="keywords" content="CSS, 渐变色, 渐变样式, 颜色渐变, 渐变背景" />
        <meta name="keywords" content="CSS, 漸層色, 漸層樣式, 顏色漸層, 漸層背景" />
        <meta name="keywords" content="CSS, グラデーションカラー, グラデーションスタイル, カラーグラデーション, グラデーション背景" />
        <meta name="keywords" content="CSS, 그라데이션 색상, 그라데이션 스타일, 색상 그라데이션, 그라데이션 배경" />
        <meta name="keywords" content="CSS, لون متدرج, نمط متدرج, تدرج اللون, خلفية متدرجة" />
        <meta name="keywords" content="CSS, cor gradiente, estilo gradiente, gradiente de cor, fundo gradiente" />
        <meta name="keywords" content="CSS, градиентный цвет, градиентный стиль, градиент цвета, градиентный фон" />
        <meta name="keywords" content="CSS, degrade renk, degrade stili, renk degrade, degrade arka plan" />
        <meta name="keywords" content="CSS,گرادیان رنگ خطی,گرادیان رنگ دایره ای,گرادیان, رنگ , رنگ گرادیان, رنگ متقارن,گرادیان رنگ افقی,گرادیان رنگ عمودی,گرادیان رنگ رادیال" />

        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1631607859297946"
        crossorigin="anonymous"></script>
      </Helmet>
     
    <Row className='pt-5 '>
      <Col lg={2} className='position-relative d-flex justify-content-center zIndex-2' >
        <div className='Advertising  mt-5 pt-5 px-5 '> 
          <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-1631607859297946"
            data-ad-slot="2957199157"
            data-auto-format="mcrspv"
            data-full-width="">
            <div overflow=""></div>
          </amp-ad>
        </div>
      </Col>
      
      <Col lg={8} className='p-5   '>
        <div className='  w-100 '>
          <p className=' w-100   mt-5 pt-3' key={totalItem.id}>{totalItem} Ready-made and customizable samples Gradient</p>
        </div>
        {
          loading ? <Loading /> : (
            <div className='d-flex flex-wrap mt-5  justify-content-center   align-items-center  zIndex-2'>
            {
              // items.map((item , index) => <CardGradient key={item.id} index={index} {...item} />)
              items.map(item => <GradiantTest key={item.id}  {...item}/>)
            }
            </div>
          )
        }
        
      </Col>

      <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
        <div className='vh-lg-100  position-fixed  '>
          <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-1631607859297946"
            data-ad-slot="2957199157"
            data-auto-format="mcrspv"
            data-full-width="">
            <div overflow=""></div>
          </amp-ad>
        </div>
      </Col>
    </Row>
   </div>
  )
}

export default Gradient