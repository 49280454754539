import { Link } from "react-router-dom";
import { useEffect } from "react";

function BrowserInspect() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


  return (
    <div className='p-3 text-start'>
        <h3 className="text-center">Browser inspect</h3>

        <br /><br /><br />

        <p>Browser developer tools are essential allies for web developers, especially when it comes to debugging and inspecting CSS code. These tools provide a powerful way to analyze how styles are applied to elements on a webpage, helping you identify and fix any issues with your CSS.</p>

        <p className="mt-4">Here's a breakdown of how you can leverage browser developer tools for CSS debugging:</p>


        <p className="mt-5"><b>Accessing DevTools:</b></p>
        <p>Most modern browsers come with built-in developer tools. Here are some common ways to access them:</p>
        <ul>
            <li><b>Right-click</b> on an element on the webpage and select "Inspect Element" or "Inspect".</li>
            <li>Use the keyboard shortcut:
                <ul>
                    <li>Windows/Linux: Ctrl + Shift + I</li>
                    <li>Mac: Cmd + Option + I</li>
                </ul>
            </li>
        </ul>
        <div style={{width:'60%', minHeight:'350px' , marginBottom:'30px'}}>
            <img style={{width:'100%', height:'100%'}} src="https://www.wikihow.com/images/thumb/e/e8/Inspect-Element-on-Chrome-Step-6.jpg/aid9521892-v4-728px-Inspect-Element-on-Chrome-Step-6.jpg" alt="inspect" />
        </div>

        <p><b>Inspecting Applied CSS:</b></p>
        <p>Once you've opened DevTools, you'll typically find two main panels:</p>

        <ul>
            <li><b>Elements Panel</b>: This panel displays the HTML structure of the webpage. You can click on any element to see its corresponding code.</li>
            <li><b>Styles Panel</b>: This panel shows the CSS styles applied to the selected element. It will list all the rules (from your stylesheet or inline styles) that are affecting the element's appearance.</li>
        </ul>

        <p><b>Key Features for Debugging:</b></p>
        <ul>
            <li><b>Live Editing</b>: Most DevTools allow you to modify CSS values directly in the Styles panel. This lets you experiment with changes and see the results instantly on the webpage, making it easier to pinpoint the cause of styling issues.</li>
            <li><b>Specificity Checker</b>: Having multiple CSS rules targeting the same element can lead to unexpected results. DevTools can often highlight rules with low specificity and indicate which rule is ultimately applied.</li>
            <li><b>Disabling Styles</b>: You can temporarily disable specific CSS rules or stylesheets to isolate the source of a problem. This helps you understand how different styles are affecting the layout or appearance.</li>
        </ul>


        <p><b>Additional Resources:</b></p>

        <ul>
            <li>
                <b>Browser Specifics</b>: While the core functionalities are similar, there might be slight variations between different browsers' DevTools. Here are some resources to get you started:
                <ul>
                    <li className="mt-2"><b>Chrome DevTools</b>: <span className="light">https://developer.chrome.com/docs/devtools</span></li>
                    <li className="mt-2"><b>Firefox DevTools</b>: <span className="light">https://firefox-source-docs.mozilla.org/devtools-user/</span></li>
                    <li className="mt-2"><b>Safari Web Inspector</b>: <span className="light">https://help.salesforce.com/s/articleView?id=sf.s1_branded_apps_playground_safari_devtools.htm&language=en_US&type</span></li>
                    <li className="mt-2"><b>Edge DevTools</b>: <span className="light">https://learn.microsoft.com/en-us/microsoft-edge/devtools-guide-chromium/landing/</span></li>
                </ul>
            </li>
            <li className="mt-4">
            <b>Debugging CSS</b>: A comprehensive guide from Mozilla Developer Network (MDN) on debugging CSS using DevTools: <span className="light">https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Debugging_CSS</span>
            </li>
        </ul>



        <p className="mt-5">By mastering these techniques, you'll be well on your way to becoming a CSS debugging pro!</p>





        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/CompilingPreprocessors'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/OptimizingCSS'>Next</Link>
        </div>
    </div>
  )
}

export default BrowserInspect