import { Link } from "react-router-dom"
import { useEffect } from "react";

function FlexBox() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // The styles are for examples
    const FlexDirection = {
        width:'100%',
        height:'200px',
        marginTop:'10px',
        padding:'3px',
        display: 'flex',
        flexDirection: 'column',
        border: 'solid 1px '
    }

    const JustifyContent = {
        width:'100%',
        height:'200px',
        marginTop:'10px',
        padding:'3px',
        display: 'flex',
        justifyContent: 'space-between',
        border: 'solid 1px '
    }

    const AlignItemsCenter = {
        width:'100%',
        height:'200px',
        marginTop:'10px',
        padding:'3px',
        display: 'flex',
        alignItems: 'center', 
        border: 'solid 1px '
    }

    const AlignItemsEnd = {
        width:'100%',
        height:'200px',
        marginTop:'10px',
        padding:'3px',
        display: 'flex',
        alignItems: 'flex-end', 
        border: 'solid 1px '
    }

    const FlexGrow = {
        width:'100%',
        height:'200px',
        marginTop:'10px',
        padding:'3px',
        display: 'flex',
        border: 'solid 1px '
    }
    const Grow1 = {
        flexGrow: '2',
        border:'solid 1px ',
        height:'70px',
    }
    const Grow2 = {
        flexGrow: '1',
        border:'solid 1px ',
        height:'70px',
    }
    const Grow3 = {
        flexGrow: '1',
        border:'solid 1px ',
        height:'70px',
    }

    const Shrink1 = {
        border:'solid 1px ',
        height:'70px',
        width:'70px'
    }
    const Shrink2 = {
        width:'140px',
        border:'solid 1px ',
        height:'70px',
        
    }
    const Shrink3 = {
        border:'solid 1px ',
        height:'70px',
        width:'70px'
    }

    const AlignSelfEnd = {
        alignSelf: 'end',
        border:'solid 1px ',
        height:'70px',
        width:'70px'
    }
    const AlignSelfCenter = {
        alignSelf: 'center',
        border:'solid 1px ',
        height:'70px',
        width:'70px'
    }

    const Item = {
        width:'70px',
        height:'70px',
        border: 'solid 1px #000',
        display: 'flex',
        justifyContent: 'center',
    }


  return (
    <div className='text-start p-3'>
        <h3 className='text-start text-center'>Flex - Box</h3>
        <br /><br /><br />

        <p>Flexbox, short for Flexible Box Layout, is a powerful and widely used method for designing layouts on webpages using CSS. It offers a more efficient way to arrange elements within a container, making them responsive and adaptable to different screen sizes.</p>


        <h5 className="mt-5">Here's a breakdown of what flexbox is all about:</h5>
        <ul>
            <li><b>One-dimensional layout</b>: Flexbox excels at arranging elements in rows or columns. You can think of it as a container that sorts its content in a single direction, either horizontally or vertically.</li>
            <li><b>Responsiveness</b>: A key advantage of flexbox is its ability to respond to changes in screen size. Flexbox can automatically adjust the sizing and distribution of elements within the container to ensure they fit well on any device.</li>
            <li><b>Flexibility</b>: As the name suggests, flexbox offers a high degree of flexibility in how you arrange elements. You can control how items grow or shrink to fill available space, or how they align within the container.</li>
        </ul>

        <p className="mt-5">Overall, flexbox makes it easier to create clean, modern, and responsive web layouts that look good on any device.</p>
        <p className="mt-5">Here are some examples of commonly used flexbox properties:</p>

        <h5>Container Properties:</h5>
        <ul>
            <li><b>Flex-direction</b>: This property controls the main axis along which the flex items are laid out. By default, it's set to <span className="light">row</span>, which arranges items horizontally in a <span className="light">row</span> from left to right. You can change it to column to stack them vertically.</li>
        </ul>
        <div className="text-light bg-dark p-3 ">
            <p><span className="text-danger">.container</span>{`{`}</p>
            <p className="mx-4">display: flex; <span className="text-secondary">/* Makes the container a flexbox */</span></p>
            <p className="mx-4">flex-direction: column; <span className="text-secondary">/* Stacks items vertically */</span></p>
            <p>{`}`}</p>
        </div>
            <div style={FlexDirection}>
                <div style={Item}>item 1</div>
                <div style={Item}>item 2</div>
                <div style={Item}>item 3</div>
            </div>
        {/*  */}
        <ul className="mt-4">
            <li><b>justify-content</b>: This property controls how flex items are distributed along the main axis (the row or column). For example, <span className="light">justify-content: space-between</span> will spread items out evenly with space between them, while <span className="light">justify-content: center;</span> will center them within the container.</li>
        </ul>

        <div className="text-light bg-dark p-3">
            <p><span className="text-danger">.container</span>{`{`}</p>
            <p>display: flex;</p>
            <p className="mx-4">justify-content: space-between; <span className="text-secondary">/* Evenly distributes items with space between */</span></p>
            <p>{`}`}</p>
        </div>

        <div style={JustifyContent}>
            <div style={Item}>item 1</div>
            <div style={Item}>item 2</div>
            <div style={Item}>item 3</div>
        </div>

        {/* AlignItems */}

        <ul className="mt-4">
            <li><b>align-items</b>: This property controls how flex items are vertically aligned within the container's cross axis (the axis perpendicular to the main axis). For example, align-items: center will center items vertically, and align-items: flex-end will position them at the bottom of the container.</li>
        </ul>

        <div className="text-light bg-dark p-3">
            <p><span className="text-danger">.container</span>{`{`}</p>
            <p className="mx-4">display: flex;</p>
            <p className="mx-4">align-items: center; <span className="text-secondary">/* Vertically centers items within the container */</span></p>
            <p>{`}`}</p>
        </div>
        <br />
        <p className="mt-3">align-item: center;</p>
        <div style={AlignItemsCenter}>
            <div style={Item}>item 1</div>
            <div style={Item}>item 2</div>
            <div style={Item}>item 3</div>
        </div>

        <p className="mt-5">align-item: flex-end;</p>
        <div style={AlignItemsEnd}>
            <div style={Item}>item 1</div>
            <div style={Item}>item 2</div>
            <div style={Item}>item 3</div>
        </div>

        <h5 className="mt-5">Item Properties:</h5>
        <ul>
            <li><b>flex-grow</b>: This property controls how much a flex item will grow to fill any extra space in the container along the main axis. A value of 1 means the item will grow proportionally to other items with similar values.</li>
        </ul>
        <div className="text-light bg-dark p-3">
            <p><span className="text-danger">.item1 </span>{`{`}</p>
            <p className="mx-4">flex-grow: 2; <span className="text-secondary">/* Makes item1 grow twice as much as other items */</span></p>
            <p>{`}`}</p>
        </div>

        <div style={FlexGrow}>
            <div style={Grow1}>item 1</div>
            <div style={Grow2}>item 2</div>
            <div style={Grow3}>item 3</div>
        </div>

        <ul className="mt-5">
            <li><b>flex-shrink</b>: This property controls how much an item will shrink if there's not enough space in the container. A value of 0 means the item won't shrink at all, while a higher value indicates it will shrink more readily.</li>
        </ul>
        <div className="text-light bg-dark p-3">
            <p><span className="text-danger">.item2 </span>{`{`}</p>
            <p className="mx-4">flex-shrink: 0;<span className="text-secondary">/* Prevents item2 from shrinking */</span></p>
            <p>{`}`}</p>
        </div>
        <div style={FlexGrow}>
            <div style={Shrink1}>item 1</div>
            <div style={Shrink2}>item 2</div>
            <div style={Shrink3}>item 3</div>
        </div>

        <ul className="mt-5">
            <li><b>align-self</b>: This property allows you to override the align-items property for a specific flex item. It controls how that particular item is aligned on the cross axis.</li>
        </ul>
        <div className="text-light bg-dark p-3">
            <p><span className="text-danger">.item3 </span>{`{`}</p>
            <p className="mx-4">align-self: flex-end;<span className="text-secondary">/*  Aligns item3 to the bottom regardless of the container's alignment */</span></p>
            <p>{`}`}</p>
        </div>
        <div style={FlexGrow}>
            <div style={Item}>item 1</div>
            <div style={Item}>item 2</div>
            <div style={AlignSelfEnd}>item 3</div>
        </div>

        <p className="mt-5">align-self: center;</p>

        <div style={FlexGrow}>
            <div style={Item}>item 1</div>
            <div style={AlignSelfCenter}>item 2</div>
            <div style={Item}>item 3</div>
        </div>


        <p className="mt-5">In addition to the basic properties mentioned in the previous answer, Flexbox has other properties that you can use to control the layout of your elements more precisely. Here is a list of some of these properties:</p>

        <ul>
            <li><b>order</b>: Specifies the order in which items are displayed within the container.</li>
            <li><b>flex-wrap</b>: Controls how items wrap to new lines.</li>
            <li><b>align-content</b>: Controls how items are aligned in the free space of the container.</li>
            <li><b>gap</b>: Sets the spacing between items in the main and cross directions.</li>
            <li><b>flex-basis</b>: Determines the initial size of items before flex-grow and flex-shrink are applied.</li>
            <li><b>max-width</b>: Specifies the maximum allowed width for items.</li>
            <li><b>min-width</b>: Specifies the minimum allowed width for items.</li>
            <li><b>max-height</b>: Specifies the maximum allowed height for items.</li>
            <li><b>min-height</b>: Specifies the minimum allowed height for items.</li>
        </ul>


        {/* btn  */}
        <br />
        <br />
        <div className="text-center">
            <Link className="px-3" to='/TutorialCss/BlockAndInlineLayout'>Previous</Link>
            <Link className="px-3" to='/TutorialCss/Grid'>Next</Link>
        </div>
    </div>
  )
}

export default FlexBox

