import { Link } from "react-router-dom";
import { useEffect } from "react";



function HistoryOfJavaScript() {

  useEffect(() => {
    window.scrollTo(0, 0);
})

  return (
    <div className='text-start p-3'>

        <p>Here's a quick rundown of JavaScript's history:</p>

        <br /><br /><br />

        <ul>
          <li><b>1995:</b> Created by Brendan Eich at Netscape in just 10 days! Originally called Mocha, then LiveScript, it eventually landed on JavaScript.</li>
          <li><b>Goal:</b> Make web pages more interactive and dynamic, moving beyond static HTML content.</li>
          <li><b>Early adopter:</b> Netscape Navigator 2.0 browser.</li>
          <li><b>Standardization:</b> Netscape submitted JavaScript to ECMA (European Computer Manufacturers Association) in 1996. This led to the creation of the ECMAScript standard (ECMAScript 1) in 1997.</li>
          <li><b>Browser wars:</b> Though Netscape introduced JavaScript, Microsoft's Internet Explorer also adopted it, ensuring its widespread use.</li>
          <li><b>Evolution:</b> JavaScript has continued to evolve with new features and versions of the ECMAScript standard being released regularly.</li>
          <li><b>Beyond the browser:</b> The rise of Node.js allowed JavaScript to be used for server-side development as well.</li>
        </ul>

        <br />

        <p>JavaScript's transformation from a simple web scripting language to a powerful tool for both front-end and back-end development has been remarkable.</p>

        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/Introduction_js' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/JavaScriptCodeInHTML' >Next</Link>
        </div>
    </div>
  )
}

export default HistoryOfJavaScript