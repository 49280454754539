import {Link, NavLink} from 'react-router-dom';
import {useState} from 'react';
import styled from 'styled-components';
import LOGO from '../../assets/LogSite/Logo.png';
import BellWhite from '../../assets/Icons/bellWhite.png';
import './navbar.css';
import Btn_menu_beurger from './Components/Btn_menu_beurger/Btn_menu_beurger';
import Btn_menu from './Components/Btn-menu/Btn_menu';


// Menu links are located in Data_menu and used in Btn_menu component and then entered into Navbar component.
// <Btn_menu /> is a component that imported in Navbar and MenuBeurger.

const MenuBeurger = styled.div`
   position:absolute;
   width: 100%;
   padding: 5px;
   background-color: #102626;
`

const Navbar = () => {
    const [listBeurger, setListBeurger] = useState(false);

const BtnBeurger = () => {
    if(listBeurger == false) {
        setListBeurger(true);
    }
    else if (listBeurger == true) {
        setListBeurger(false);
    }
}
  return (
    <div className='Navbar'>
        <div className='Navbar-container    '>
            <Link to='/' className='text-decoration-none px-2'><img src={LOGO} alt="css"  style={{width:'50px'}}/></Link>
            <div className=' container   Container-menu ' >
                {/* Buttons  Dropdown menu  */}
                <Btn_menu />
            </div>
            {/* Button menu beurger */}
            <div onClick={BtnBeurger}>
                <Btn_menu_beurger   />
            </div>
            <div className='' >
                <Link to='/notification'>
                    <img  src={BellWhite} alt="bell" style={{color:'#fffff', }} />
                </Link>
            </div>
        </div>

        {/* listBeurger */}

        {listBeurger && (
            <MenuBeurger className='menuHambeurger'>
                <Btn_menu />
            </MenuBeurger>
        )}
        
    </div>
  )
}

export default Navbar