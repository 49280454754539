const List_ment = [
    {
        id: 1,
        HeaderTitle: "Introduction to HTML",
        MenuList : [
            { LinkMenu: 'TutorialCss/BasicHTMLstructure', TitleMenu: 'Basic HTML structure'},
            { LinkMenu: 'TutorialCss/CommonHTMLTags', TitleMenu: 'Common HTML tags'},
            { LinkMenu: 'TutorialCss/CreatingHeadings', TitleMenu: 'Creating headings'},
            { LinkMenu: 'TutorialCss/Paragraphs', TitleMenu: 'Paragraphs'},
            { LinkMenu: 'TutorialCss/lists', TitleMenu: 'lists'},
            { LinkMenu: 'TutorialCss/Table', TitleMenu: 'Table'}
        ]
    },
    {
        id: 2,
        HeaderTitle: "Introduction to CSS",
        MenuList: [
            {LinkMenu: 'TutorialCss/HistoryandpurposeofCSS', TitleMenu: 'History and purpose of CSS'},
            {LinkMenu: 'TutorialCss/StructureofACSSfile', TitleMenu: 'Structure of a CSS file'},
            {LinkMenu: 'TutorialCss/CSSsyntax', TitleMenu: 'CSS syntax'}
        ]
    },
    {
        id: 3,
        HeaderTitle: "CSS Selectors",
        MenuList: [
            {LinkMenu: 'TutorialCss/BasicSelectors', TitleMenu: 'Basic selectors'},
            {LinkMenu: 'TutorialCss/DescendantSelectors', TitleMenu: 'Child, sibling, and descendant selectors'},
            {LinkMenu: 'TutorialCss/AttributeSelectors', TitleMenu: 'Pseudo-selectors'}
        ]
    },
    {
        id: 4,
        HeaderTitle: "Text Formatting",
        MenuList: [
            {LinkMenu: 'TutorialCss/FontSize', TitleMenu: 'Font size text'},
            {LinkMenu: 'TutorialCss/MarginPadding', TitleMenu: 'Margins, padding'},
            {LinkMenu: 'TutorialCss/TextEffects', TitleMenu: 'Text effects'}
        ]
    },
    {
        id: 5,
        HeaderTitle: "Dimension in css",
        MenuList: [
           {LinkMenu: 'TutorialCss/Width', TitleMenu: 'Width'},
           {LinkMenu: 'TutorialCss/Position', TitleMenu: 'Position elements'},
           {LinkMenu: 'TutorialCss/Floating', TitleMenu: 'Floating elements'}
        ]
    },
    {
        id: 6,
        HeaderTitle: "Box Model",
        MenuList: [
            {LinkMenu: 'TutorialCss/BoxModel', TitleMenu: 'Structure of box model'},
            {LinkMenu: 'TutorialCss/ComponentBox', TitleMenu: 'Components  box model'}
        ]

    },
    {
        id: 7,
        HeaderTitle: "Layout Systems",
        MenuList: [
            {LinkMenu: 'TutorialCss/BlockAndInlineLayout', TitleMenu: 'Block and inline layout'},
            {LinkMenu: 'TutorialCss/FloatingPositioningLayout', TitleMenu: 'Floating and positioning for layout'},
            {LinkMenu: 'TutorialCss/FlexBox', TitleMenu: 'Flexbox'},
            {LinkMenu: 'TutorialCss/Grid', TitleMenu: 'Grid'}
        ]
    },
    {
        id: 8,
        HeaderTitle: "Colors and Gradients",
        MenuList: [
            {LinkMenu: 'TutorialCss/Colors', TitleMenu: 'Colors'},
            {LinkMenu: 'TutorialCss/Gradients', TitleMenu: 'Gradients'}
        ]
    },
    {
        id: 9,
        HeaderTitle: "Images and Backgrounds",
        MenuList: [
            {LinkMenu: 'TutorialCss/AddImage', TitleMenu: 'Image'},
            {LinkMenu: 'TutorialCss/SettingImage', TitleMenu: 'Setting image'}
        ]
    },
    {
        id: 10,
        HeaderTitle: "Animations and Transitions",
        MenuList: [
            {LinkMenu: 'TutorialCss/Animation', TitleMenu: 'Animation'},
            {LinkMenu: 'TutorialCss/Transition', TitleMenu: 'Transition'},
            {LinkMenu: 'TutorialCss/CSSAnimationLibraries', TitleMenu: 'CSS animation libraries'}
        ]
    },
    {
        id: 11,
        HeaderTitle: "Media Queries",
        MenuList: [
            {LinkMenu: 'TutorialCss/MediaQueries', TitleMenu: 'Media Queries'},
            {LinkMenu: 'TutorialCss/Devices', TitleMenu: 'Desktop, tablet, mobile'},
            {LinkMenu: 'TutorialCss/AdaptiveDesign', TitleMenu: 'Adaptive design'}
        ]
    },
    {
        id: 12,
        HeaderTitle: "CSS Preprocessors",
        MenuList: [
            {LinkMenu: 'TutorialCss/Introduction', TitleMenu: 'Introduction'},
            {LinkMenu: 'TutorialCss/CssVariable', TitleMenu: 'Css variables'},
            {LinkMenu: 'TutorialCss/CompilingPreprocessors', TitleMenu: 'Compiling preprocessors'}
        ]
    },
    {
        id: 13,
        HeaderTitle: "Browser Developer Tools",
        MenuList: [
            {LinkMenu: 'TutorialCss/BrowserInspect', TitleMenu: 'Browser inspect'},
            {LinkMenu: 'TutorialCss/OptimizingCSS', TitleMenu: 'Optimizing CSS'}
        ]        
    },{
        id: 14,
        HeaderTitle: "Points",
        MenuList: [
            {LinkMenu: 'TutorialCss/ImportantNotes', TitleMenu: 'Important Notes'}
        ]        
    }
]

export default List_ment;


// {

//     id: ,
//     HeaderTitle: "",
//     MenuList: [
//         {LinkMenu: 'TutorialCss/', TitleMenu: ''},
//         {LinkMenu: 'TutorialCss/', TitleMenu: ''},
//         {LinkMenu: 'TutorialCss/', TitleMenu: ''},
//     ]

// }