import React, { useState } from 'react';
import Styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const Button = Styled.button`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: #365486;
  color: #fff;
  &:hover {
    background-color: #789461;
  }
`
const ContainerColor = Styled.div`
  width: 300px;
  height: 100px;
  border: solid 1px #000;
  border-radius: 4px;
`
const Span = Styled.span`
  padding: 0.2em 1em;
  cursor: pointer;
  border: solid 1px #000;
  border-radius: 4px;

  &:hover {
    background-color: #789461;
    color: #fff;
  }
`
const HslToRgbConverter = () => {
  const [hslColor, setHslColor] = useState('');
  const [rgbaColor, setRgbaColor] = useState('');
  const [hexColor, setHexColor] = useState('');

  const controlHandler = (e) => {
    setHslColor(e.target.value);
  };

  const hslToRgb = (h, s, l) => {
    // Validate input values
    if (isNaN(h) || isNaN(s) || isNaN(l) || h < 0 || h > 360 || s < 0 || s > 100 || l < 0 || l > 100) {
      console.error('Invalid HSL values. Please enter valid HSL values.');
      return ''; // Return an empty string or any default value
    }
    
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;
    
    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    const rgbaColor = `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, 1)`;
    return rgbaColor;
  };

  const rgbToHex = (r, g, b) => {
    return '#' + ((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1);
  };

  const sendHandler = () => {
    const hslValues = hslColor.match(/\d+/g).map(value => parseFloat(value.trim()));
    const rgba = hslToRgb(hslValues[0], hslValues[1], hslValues[2]);
    setRgbaColor(rgba);
    const rgbValues = rgba.match(/\d+/g).map(value => parseFloat(value.trim()));
    const hex = rgbToHex(rgbValues[0], rgbValues[1], rgbValues[2]);
    setHexColor(hex);
  };

  return (
    <div className='container m-auto d-flex flex-column'>
      <h1 className='p-5'>HSL to RGBA Converter</h1>
      <div className='w-50 d-flex justify-content-between m-auto'>
        <input
          className='w-75'
          type="text"
          onChange={controlHandler}
          placeholder="Enter HSL code (e.g., 180, 50%, 50%)"
        />
        <Button onClick={sendHandler} type='button'>
          Convert
        </Button>
      </div>
      <Row className='m-auto mt-3'>
        <Col lg={12} className='d-flex justify-content-center'>
          <div>
            <p>HSL code</p>
            <ContainerColor style={{ backgroundColor: hslColor }}></ContainerColor>
            <p className='mt-2'>{hslColor}</p><Span onClick={() => {navigator.clipboard.writeText(hslColor)}}>copy</Span>
          </div>
        </Col>
        <Col lg={12} className='d-flex justify-content-center'>
          <div className='d-flex m-auto  gap-5 border mt-3 p-3'>
            <div>
              <p>RGBA code</p>
              <ContainerColor style={{ backgroundColor: rgbaColor }}></ContainerColor>
              <p className='mt-2'>{rgbaColor}</p><Span onClick={() => {navigator.clipboard.writeText(rgbaColor)}}>copy</Span>
            </div>
            <div>
              <p>Hex code</p>
              <ContainerColor style={{ backgroundColor: hexColor }}></ContainerColor>
              <p className='mt-2'>{hexColor}</p><Span onClick={() => {navigator.clipboard.writeText(hexColor)}}>copy</Span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HslToRgbConverter;
