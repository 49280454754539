import { Link } from "react-router-dom"
import { useEffect } from "react";


function Floating() {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className='p-3'>
      <h3>Floating</h3>
      <br /><br /><br />

      <p className="text-start">Floating elements in HTML and CSS are a layout technique used to position elements side by side horizontally, typically within a container. When an element is floated, it is taken out of the normal flow of the document and shifted to the left or right until it reaches the edge of its containing element or another floated element.</p>
      <br /><br />
      <h5 className="text-start">Here's a brief overview of how floating elements work:</h5>
      <p className="text-start mt-4">1. <b>Float Property</b>: In CSS, the <span className="light">float</span> property is used to float elements. It can take one of three values: <span className="light">left</span>, <span className="light">right</span>, or <span className="light">none</span>. When set to <span className="light">left</span> or <span className="light">right</span>, the element will float to the left or right of its containing element, allowing content to flow around it.</p>
      <p className="text-start">2. <b>Clearing Floats</b>: When elements are floated, they can sometimes cause layout issues with subsequent content not flowing around them properly. To prevent this, the <span className="light">clear</span> property is used. You can apply <span className="light">clear: both</span> to an element to ensure that it appears below any floated elements that precede it.</p>
      <p className="text-start">3. <b>Controlling Widths</b>: Floating elements usually need explicit widths set, especially when you want them to sit side by side. This ensures that they don't overlap or misalign.</p>

      <br /><br />
      <p className="text-start">Floating elements were historically used extensively for creating multi-column layouts before the advent of modern CSS layout techniques like Flexbox and CSS Grid. However, they are still useful in certain situations, such as creating simple grids or aligning images and text.</p>
      
      <br /><br />
      <h5 className="text-start">Here's an example of how you might use floating elements in CSS:</h5>


      <div className="text-start p-3 bg-dark text-light">
        <p>{`<!DOCTYPE html>`}</p>
        <p>{`<html>`}</p>
        <p>{`<head>`}</p>
        <p className='mx-3'>{`<style>`}</p>
        <p className='mx-5'><span className="text-danger">.container </span>{`{`}</p>
        <p className='mx-5 px-3'><span className="text-info">width</span>: <span className="text-danger">600px</span>;</p>
        <p className='mx-5 px-3'><span className="text-info">margin</span>: <span className="text-danger"> 0</span> auto</p>
        <p className='mx-5'>{`}`}</p>
        <p className='mx-5'><span className="text-danger">.box </span>{`{`}</p>
        <p className='mx-5 px-3'><span className="text-info">width</span>:<span className="text-danger">200px</span></p>
        <p className='mx-5 px-3'><span className="text-info">height</span>:<span className="text-danger">200px</span></p>
        <p className='mx-5 px-3'><span className="text-info">float</span> : left</p>
        <p className='mx-5 px-3'><span className="text-info">margin-right</span>:<span className="text-danger"></span> 20px; <span className="text-secondary">/* Add some margin for spacing */</span> </p>
        <p className="mx-5">{`}`}</p>
        <p className='mx-5'><span className="text-danger">.clear</span> {`{`}</p>
        <p className='mx-5 px-3'><p><span className="text-info">clear</span> : both</p></p>
        <p className="mx-5">{`}`}</p>
        <p className='mx-3'>{`</style>`}</p>
        <p>{`</head>`}</p>
        <p>{`<body>`}</p>
        <p className='mx-3'>{`<div`} <span className='text-danger'>class</span> = <span className='text-info'>"container"</span> {`>`}</p>
        <p className='mx-5'>{`<div`} <span className='text-danger'>class</span>=<span className='text-info'>"box"</span> <span className='text-danger'>style</span>=<span className='text-info'>"background-color: red;"</span> {`></div>`}</p>
        <p className='mx-5'>{`<div`} <span className='text-danger'>class</span>=<span className='text-info'>"box"</span> <span className='text-danger'>style</span>=<span className='text-info'>"background-color: red;"</span> {`></div>`}</p>
        <p className='mx-5'>{`<div`} <span className='text-danger'>class</span>=<span className='text-info'>"box"</span> <span className='text-danger'>style</span>=<span className='text-info'>"background-color: red;"</span> {`></div>`}</p>
        <p className='mx-5'>{`<div`} <span className='text-danger'>class</span>=<span className='text-info'>"box"</span> <span className='text-danger'>style</span>=<span className='text-info'>"background-color: red;"</span> {`></div>`}</p>
        <p className='mx-3'> {`</div>`}</p>
        <p>{`</body>`}</p>
        <p>{`</html>`}</p>
        <p></p>
        <p></p>
      </div>
      <p className="text-start mt-5">In this example, three <span className="light">.box</span> elements are floated to the left within a <span className="light">.container</span>. The <span className="light">.clear</span> div at the end ensures that the container expands to contain the floated elements properly.</p>

      {/* btn  */}
      <br />
        <br />
        <Link className="px-3" to='/TutorialCss/Position'>Previous</Link>
        <Link className="px-3" to='/TutorialCss/BoxModel'>Next</Link>
    </div>
  )
}

export default Floating