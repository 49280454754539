import { Link } from "react-router-dom";
import { useEffect } from "react";

function While() {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

  return (
    <div className="text-start p-3">
        <h5>While</h5>
        <br /><br />

        <p>In JavaScript, while is a loop statement that executes a block of code as long as a specified condition is true. The basic syntax looks like this:</p>

        <div className="Box">
            <h6>JavaSCript</h6>
            <br />
            <p><span className="text-warning">while </span>(condition) {`{`}</p>
            <p className="mx-4 text-secondary">// code block to be executed</p>
            <p>{`}`}</p>
        </div>

        <p className="mt-5">Here, condition is an expression evaluated before each iteration. If this condition evaluates to true, the code inside the block will execute. After the block executes, the condition is evaluated again. This process continues until the condition evaluates to false, at which point the loop terminates, and the program moves on to the next statement after the loop.</p>

    <p className="mt-5">For example, consider the following code that counts from 1 to 5 using a while loop:</p>
    <div className="Box">
        <h6>JavaScript</h6>
        <br />
        <p><span className="text-warning">while </span>( count {`<=`} <span className="text-danger">5</span>) {`{`}</p>
        <p className="mx-4"><span className="text-danger">console.log</span>(count)</p>
        <p className="mx-4">count++</p>
        <p>{`}`}</p>
    </div>
    <p>This code will print:</p>
    <div className="Box">
        <p>1</p>
        <p>2</p>
        <p>3</p>
        <p>4</p>
        <p>5</p>
    </div>

    <p className="mt-5">The loop starts with count set to 1. The condition count <b>{`<= 5`}</b> is true, so the code inside the block executes, logging the value of count. After each iteration, count is incremented by 1 (<b>count++</b>). This process repeats until count is no longer less than or equal to 5.</p>




        <br /><br />
        <div className="text-center">
            <Link className="mx-2" to='/Tutorial_JS/ForLoop' >Previous</Link>
            <Link className="mx-2" to='/Tutorial_JS/DoWhile' >Next</Link>
        </div>
    </div>
  )
}

export default While