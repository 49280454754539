import './paletteCard.css';
import { Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import Styled from 'styled-components';
import EditeIcon from '../../assets/Icons/Samples/ColorPalette/Edite.png';

const Span = Styled.span`
font-size:'12px';
color: red;
`

const CardPaletteColor = (
    {
      oneColor,
      towColor,
      threeColor,
      fourColor,
      _id
    }
) => {
  const [itemHover1, setItemHover1] = useState(false);
  const [itemHover2, setItemHover2] = useState(false);
  const [itemHover3, setItemHover3] = useState(false);
  const [itemHover4, setItemHover4] = useState(false);

  useEffect(() => {
    // انیمیشن کپی شدن متن
  }, [oneColor, towColor, threeColor, fourColor]);


  return (
      <div className='PaletteColorCard' style={{position:'realative'}}>
      {/* oneColor */}
            <Link style={{position:'absolute', marginLeft:'110px', marginTop:'10px'}}
            to={`/ParamsPaletteColor/${_id}`}
              
            ><img src={EditeIcon} style={{width:'30px',color:'#fff'}} className='Rotate'/></Link>
        <div

          onMouseEnter={() => setItemHover1(true)}
          onMouseLeave={() => setItemHover1(false)}
          className='child-1' style={{backgroundColor:`${oneColor}`}}
          onClick={() => {
            navigator.clipboard.writeText(oneColor);
            // انیمیشن کپی شدن متن
            const span = document.querySelector(".child-1 span");
            span.style.animation = "copy 0.5s ease-in-out";
          }}
        >
          
          {
            itemHover1? <span>{oneColor}</span> : ''
          }
        </div>
        <div className='child-2'>
          {/* Tow Color */}
            <div
              onMouseEnter={() => setItemHover2(true)}
              onMouseLeave={() => setItemHover2(false)}
              onClick={() => {
                navigator.clipboard.writeText(towColor);
                // انیمیشن کپی شدن متن
                const span = document.querySelector(".child-2 span");
                span.style.animation = "copy 0.5s ease-in-out";
              }}
              className='left'  style={{backgroundColor:towColor}}>{
              itemHover2? <span>{towColor}</span> : ''
            } </div>
            {/* Three Color */}
            <div
              onMouseEnter={() => setItemHover3(true)}
              onMouseLeave={() => setItemHover3(false)}
              onClick={() => {
                navigator.clipboard.writeText(threeColor);
                // انیمیشن کپی شدن متن
                const span = document.querySelector(".child-2 span");
                span.style.animation = "copy 0.5s ease-in-out";
              }}
            className='item-center'  style={{backgroundColor:threeColor}}>
              {
                itemHover3? <span>{threeColor}</span> : ''
              }
            </div>
            {/* Four Color */}
            <div
              onMouseEnter={() => setItemHover4(true)}
              onMouseLeave={() => setItemHover4(false)}
              onClick={() => {
                navigator.clipboard.writeText(fourColor);
                // انیمیشن کپی شدن متن
                const span = document.querySelector(".child-2 span");
                span.style.animation = "copy 0.5s ease-in-out";
              }}
              className='right'  style={{backgroundColor:fourColor}}>
              {
                itemHover4? <span>{fourColor}</span> : ''
              }
            </div>
        </div>
    </div>
  )
}

export default CardPaletteColor