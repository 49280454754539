import {Row, Col} from 'react-bootstrap';
import CardPalette from '../../../Components/Palette-color/CardPaletteColor';
import Loading from '../../../Components/LOADING/Loading';
import './PalettesColor.css';
import { Helmet } from 'react-helmet';
import {useState , useEffect}  from 'react';
const PalettesColor = () => {
    const [ items , setItems ] = useState([]);
    const [loading, setLoading] = useState(true);
    const URL_API = process.env.REACT_APP_URL_API;
    useEffect(() => {
        fetch(`${URL_API}/api/palette`)
        .then(res => res.json())
        .then(res => {
          setItems(res.data); // تنظیم لیست کاربران
          setLoading(false);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }, []);

    useEffect(() => {
      // Scroll to top
      window.scrollTo(0, 0); 
    },[])
   
    
  return (
    <div className='Palette-page'>
      <Helmet>
      
     <meta name="google-adsense-account" content="ca-pub-1631607859297946"></meta>
        <title>Gradient</title>
        <meta name="keywords" content="پالت رنگی, رنگ شناسی, رنگ درمانی, روانشناسی رنگ, طراحی رنگ, ترکیب رنگ, چرخ رنگ, رنگ های مکمل, رنگ های متضاد, رنگ های هم خانواده, رنگ های خنثی, رنگ های گرم, رنگ های سرد" />
        <meta name="keywords" content="color palette, color theory, color therapy, color psychology, color design, color combination, color wheel, complementary colors, contrasting colors, analogous colors, neutral colors, warm colors, cool colors" />
        <meta name="keywords" content="palette de couleurs, théorie des couleurs, thérapie par la couleur, psychologie des couleurs, design de la couleur, combinaison de couleurs, roue des couleurs, couleurs complémentaires, couleurs contrastées, couleurs analogues, couleurs neutres, couleurs chaudes, couleurs froides" />
        <meta name="keywords" content="paleta de colores, teoría del color, terapia del color, psicología del color, diseño del color, combinación de colores, rueda de colores, colores complementarios, colores contrastantes, colores análogos, colores neutros, colores cálidos, colores fríos" />
        <meta name="keywords" content="Farbpalette, Farbtheorie, Farbtherapie, Farbpsychologie, Farbgestaltung, Farbkombination, Farbkreis, Komplementärfarben, Kontrastfarben, Analoge Farben, Neutrale Farben, Warme Farben, Kalte Farben" />
        <meta name="keywords" content="tavolozza dei colori, teoria del colore, terapia del colore, psicologia del colore, design del colore, combinazione dei colori, ruota dei colori, colori complementari, colori contrastanti, colori analoghi, colori neutri, colori caldi, colori freddi" />
        <meta name="keywords" content="调色板, 色彩理论, 色彩疗法, 色彩心理学, 色彩设计, 色彩搭配, 色轮, 互补色, 对比色, 类似色, 中性色, 暖色, 冷色" />
        <meta name="keywords" content="カラーパレット, 色彩理論, カラーセラピー, 色彩心理学, カラーデザイン, カラーコーディネート, カラーホイール, 補色, 対比色, 類似色, 中性色, 暖色, 寒色" />
        <meta name="keywords" content="색상 팔레트, 색채 이론, 색채 치료, 색채 심리학, 색채 디자인, 색상 조합, 색상환, 보색, 대비 색, 유사 색, 중성 색, 따뜻한 색, 차가운 색" />
        <meta name="keywords" content="paleta de cores, teoria das cores, terapia da cor, psicologia das cores, design de cores, combinação de cores, roda das cores, cores complementares, cores contrastantes, cores análogas, cores neutras, cores quentes, cores frias" />
        <meta name="keywords" content="палитра цветов, теория цвета, цветотерапия, психология цвета, дизайн цвета, сочетание цветов, цветовой круг, комплементарные цвета, контрастные цвета, аналоговые цвета, нейтральные цвета, теплые цвета, холодные цвета" />
        <meta name="keywords" content="renk paleti, renk teorisi, renk terapisi, renk psikolojisi, renk tasarımı, renk kombinasyonu, renk çarkı, tamamlayıcı renkler, karşıt renkler, benzer renkler, nötr renkler, sıcak renkler, soğuk renkler" />
        
      </Helmet>
        <Row className=''>
            <Col lg={2} className='position-relative d-flex justify-content-center' >
            <div className='Advertising  mt-5 pt-5 px-5 '> 
              <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-1631607859297946"
                data-ad-slot="2957199157"
                data-auto-format="mcrspv"
                data-full-width="">
                <div overflow=""></div>
              </amp-ad>
            </div>
            </Col>
            <Col lg={8} >
              {
                loading ? <Loading /> : (
                  <div className='Container-palette '>
                  {
                  items.map( items => <CardPalette key={items._id} {...items} />)
                  }
                    
                </div> 
                )
              }
            </Col>
            <Col lg={2} className='  vh-lg-100  pt-5 d-flex flex-wrap justify-content-center   '>
              <div className='vh-lg-100  position-fixed  '>
                <amp-ad width="100vw" height="320"
                  type="adsense"
                  data-ad-client="ca-pub-1631607859297946"
                  data-ad-slot="2957199157"
                  data-auto-format="mcrspv"
                  data-full-width="">
                  <div overflow=""></div>
                </amp-ad>
              </div>
            </Col>
        </Row>
        
    </div>
  )
}

export default PalettesColor